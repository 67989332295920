import createReducer from '../createReducer';

/** ********************************************************
 * CONSTANTS
 ******************************************************** */

export const FETCH_PATIENT = 'patient/FETCH_PATIENT';
export const FETCHING_PATIENT = 'patient/FETCHING_PATIENT';
export const CONFIRM_PATIENT_MATCH = 'patient/CONFIRM_PATIENT_MATCH';
export const CONFIRMING_PATIENT_MATCH = 'patient/CONFIRMING_PATIENT_MATCH';
export const CONFIRM_PATIENT_MATCH_ERROR = 'patient/CONFIRM_PATIENT_MATCH_ERROR';
export const CREATE_EPIC_PATIENT_ERROR = 'patient/CREATE_EPIC_PATIENT_ERROR';
export const CREATE_EPIC_PATIENT = 'patient/CREATE_EPIC_PATIENT';
export const CREATING_EPIC_PATIENT = 'patient/CREATING_EPIC_PATIENT';
export const FETCH_PATIENT_CONTEXT = 'patient/FETCH_PATIENT_CONTEXT';
export const FETCHING_PATIENT_CONTEXT = 'patient/FETCHING_PATIENT_CONTEXT';
export const PATIENT_CONTEXT_ERROR = 'patient/PATIENT_CONTEXT_ERROR';
export const PATIENT_CONTEXT_ERROR_MESSAGE = 'patient/PATIENT_CONTEXT_ERROR_MESSAGE';
export const PATIENT_PES_ERROR_MESSAGE = 'patient/PATIENT_PES_ERROR_MESSAGE';
export const PATIENT_PES_REQUEST_FAILED = 'patient/PATIENT_PES_REQUEST_FAILED';
export const PATIENT = 'patient/PATIENT';
export const PATIENT_FHIR = 'patient/PATIENT_FHIR';
export const PATIENT_SEARCHED = 'patient/PATIENT_SEARCHED';
export const MRN_REQUIRED = 'patient/MRN_REQUIRED';
export const SET_MRN_REQUIRED = 'patient/SET_MRN_REQUIRED';
export const PATIENT_CONTEXT_ID = 'patient/PATIENT_CONTEXT_ID';

/** ********************************************************
 * REDUCER
 ******************************************************** */

export const initialStatePatient = {
  patient: null,
  patientFHIR: null,
  isConfirmingPatientMatch: false,
  isCreatingEpicPatient: false,
  isFetchingPatient: false,
  isFetchingPatientContext: false,
  isPatientContextError: false,
  patientContextErrorMessage: '',
  pesErrorMessage: '',
  pesRequestFailed: false,
  confirmPatientMatchError: false,
  createEpicPatientError: false,
  isPatientSearched: { isSearched: false, userEvent: false },
  isMRNRequired: false,
  patientContextId: null,
};

export default createReducer(initialStatePatient, {
  [PATIENT]: (state, { payload }) => ({
    ...state, patient: payload,
  }),
  [PATIENT_FHIR]: (state, { payload }) => ({
    ...state, patientFHIR: payload,
  }),
  [CONFIRMING_PATIENT_MATCH]: (state, { payload }) => ({
    ...state, isConfirmingPatientMatch: payload,
  }),
  [CONFIRM_PATIENT_MATCH_ERROR]: (state, { payload }) => ({
    ...state, confirmPatientMatchError: payload,
  }),
  [CREATE_EPIC_PATIENT_ERROR]: (state, { payload }) => ({
    ...state, createEpicPatientError: payload,
  }),
  [CREATING_EPIC_PATIENT]: (state, { payload }) => ({
    ...state, isCreatingEpicPatient: payload,
  }),
  [FETCHING_PATIENT]: (state, { payload }) => ({
    ...state, isFetchingPatient: payload,
  }),
  [FETCHING_PATIENT_CONTEXT]: (state, { payload }) => ({
    ...state, isFetchingPatientContext: payload,
  }),
  [PATIENT_CONTEXT_ERROR]: (state, { payload }) => ({
    ...state, isPatientContextError: payload,
  }),
  [PATIENT_CONTEXT_ERROR_MESSAGE]: (state, { payload }) => ({
    ...state, patientContextErrorMessage: payload,
  }),
  [PATIENT_PES_ERROR_MESSAGE]: (state, { payload }) => ({
    ...state, pesErrorMessage: payload,
  }),
  [PATIENT_PES_REQUEST_FAILED]: (state, { payload }) => ({
    ...state, pesRequestFailed: payload,
  }),
  [PATIENT_SEARCHED]: (state, { payload }) => ({
    ...state, isPatientSearched: payload,
  }),
  [MRN_REQUIRED]: (state, { payload }) => ({
    ...state, isMRNRequired: payload,
  }),
  [PATIENT_CONTEXT_ID]: (state, { payload }) => ({
    ...state, patientContextId: payload,
  }),
});

/** ********************************************************
 * ACTIONS
 ******************************************************** */

/**
 * Fetches the patient
 * @param {string} patientID - find patient with given id
 * @param organization
 * @param {string} patientIDType
 * @returns {{type: string, payload: *}}
 */
export const fetchPatient = (patientID, organization, patientIDType) => ({
  type: FETCH_PATIENT,
  payload: { patientID, organization, patientIDType },
});

/**
 * Used by the UI to determine if the form is ready to be shown
 * @param {boolean} bool
 * @returns {{type: string, payload: boolean}}
 */
export const isFetchingPatient = bool => ({
  type: FETCHING_PATIENT,
  payload: bool,
});

/**
 *
 * @param bool
 * @returns {{payload, type: string}}
 */
export const isConfirmingPatientMatch = bool => ({
  type: CONFIRMING_PATIENT_MATCH,
  payload: bool,
});

/**
 *
 * @param bool
 * @returns {{payload, type: string}}
 */
export const isCreatingEpicPatient = bool => ({
  type: CREATING_EPIC_PATIENT,
  payload: bool,
});

/**
 * Updates patient identifiers and submits service request with active status
 * @param patientID
 * @param patient
 * @returns {{payload: {patientID, patient}, type: string}}
 */
export const confirmPatientMatch = (patientID, patient) => ({
  type: CONFIRM_PATIENT_MATCH,
  payload: { patientID, patient },
});

/**
 *
 * @param organizationId
 * @param patient
 * @returns {{payload: {patientID, patient}, type: string}}
 */
export const createEpicPatient = (organizationId, patient) => ({
  type: CREATE_EPIC_PATIENT,
  payload: { organizationId, patient },
});

/**
 * Fetches the EHR patient context
 * @returns {{type: string}}
 */
export const fetchPatientContext = () => ({
  type: FETCH_PATIENT_CONTEXT,
});

/**
 * Used by the UI to determine if the form is ready to be shown
 * @param {boolean} bool
 * @returns {{type: string, payload: boolean}}
 */
export const isFetchingPatientContext = bool => ({
  type: FETCHING_PATIENT_CONTEXT,
  payload: bool,
});

/**
 * Used by the UI to determine an error occurred loading patient context
 * @param {boolean} bool
 * @returns {{type: string, payload: boolean}}
 */
export const isPatientContextError = bool => ({
  type: PATIENT_CONTEXT_ERROR,
  payload: bool,
});

/**
 * Use to display the patient context error message in the UI
 * @param message
 * @returns {{payload, type: string}}
 */
export const patientContextErrorMessage = message => ({
  type: PATIENT_CONTEXT_ERROR_MESSAGE,
  payload: message,
});

/**
 * Use to display the PES error messages in the UI
 * @param message
 * @returns {{payload, type: string}}
 */
export const pesErrorMessage = (message) => ({
  type: PATIENT_PES_ERROR_MESSAGE,
  payload: message,
});

/**
 * Use to display the PES error messages in the UI
 * @param bool
 * @returns {{payload, type: string}}
 */
export const pesRequestFailed = (bool) => ({
  type: PATIENT_PES_REQUEST_FAILED,
  payload: bool,
});

/**
 * Sets the patient
 * @param {[]} payload
 * @returns {{type: string, payload: *}}
 */
export const patient = payload => ({
  type: PATIENT,
  payload,
});

/**
 * Confirm patient match error
 * @param payload
 * @returns {{payload, type: string}}
 */
export const confirmPatientMatchError = payload => ({
  type: CONFIRM_PATIENT_MATCH_ERROR,
  payload,
});

/**
 * Create epic patient error
 * @param payload
 * @returns {{payload, type: string}}
 */
export const createEpicPatientError = payload => ({
  type: CREATE_EPIC_PATIENT_ERROR,
  payload,
});

/**
 * Sets the patient
 * @param {[]} payload
 * @returns {{type: string, payload: *}}
 */
export const patientFHIR = payload => ({
  type: PATIENT_FHIR,
  payload,
});

/**
 * Used by the UI to determine if the patient has been searched
 * @param isSearched: bool
 * @param userEvent: bool validate if the change is triggered by an user event or by internal action
 * @returns {{type: string, payload: *}}
 */
export const isPatientSearched = (isSearched, userEvent = false) => ({
  type: PATIENT_SEARCHED,
  payload: { isSearched, userEvent },
});

/**
 * We save when patient has been looked up or not
 * @param isSearched: bool
 * @returns {{type: string, payload: *}}
 */
export const setMRNRequired = bool => ({
  type: SET_MRN_REQUIRED,
  payload: bool,
});

/**
 * Used by the UI to determine if the patient mrn is required to validate
 * @param isSearched: bool
 * @returns {{type: string, payload: boolean}}
 */
export const isMRNRequired = isRequired => ({
  type: MRN_REQUIRED,
  payload: isRequired,
});

/**
 * Save the patient ID if the user is coming from the patient profile
 * @param patientID: Int
 * @returns {{type: string, payload: int}}
 */
export const patientContextId = patientID => ({
  type: PATIENT_CONTEXT_ID,
  payload: patientID,
});

/** ********************************************************
 * SELECTORS
 ******************************************************** */

/**
 * @param state
 * @returns {null|{}}
 */
export const selectPatient = state => state.patient.patient;

/**
 * @param state
 * @returns {null|int}
 */
export const selectPatientContextId = state => state.patient.patientContextId;

/**
 * Selects FHIR patient
 * @param state
 * @returns {null|{}}
 */
export const selectPatientFHIRData = state => state.patient.patientFHIR;
