import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectAppIframeSource, selectBrowserViewport } from '../../state';
import Breadcrumbs from '../breadcrumbs/Breadcrumbs';

const PageContent = ({ appIframeSource, children }) => (
  <div
    className={`page-content${appIframeSource === 'next' ? ' inside-iframe next' : ''}`}
  >
    { appIframeSource !== 'next' && <Breadcrumbs /> }
    <div className="page-body pb-4">
      {children}
    </div>
  </div>
);

const {
  array,
  element,
  oneOfType,
  string,
} = PropTypes;

PageContent.propTypes = {
  browserViewport: PropTypes.shape({
    greaterThan: PropTypes.shape({
      medium: PropTypes.bool,
    }),
  }).isRequired,
  appIframeSource: string.isRequired,
  children: oneOfType([
    element,
    array,
  ]).isRequired,
};

function mapStateToProps(state) {
  return {
    browserViewport: selectBrowserViewport(state),
    appIframeSource: selectAppIframeSource(state),
  };
}

export default connect(mapStateToProps)(PageContent);
