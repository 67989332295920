import { put, select } from 'redux-saga/effects';
import axios from 'axios';
import { coverage, isFetchingCoverage, selectUser } from '../../../../state';
import EnvironmentSettings from '../../../../services/EnvironmentSettings';

/**
 * Query Coverage for the patient if necessary, add to ServiceRequest.insurance
 * @param payload
 * @param patientFHIRId
 * @return {Generator<SimpleEffect<"PUT", PutEffectDescriptor<{type: string, payload: boolean}>>}
 */
export default function* postCoverage(payload, patientFHIRId) {
  let data = null;
  const user = yield select((state) => selectUser(state));
  const { reimbursement_type: reimbursementType, request_insurance_collection_enabled } = user?.organization?.settings;
  let insuranceProviderId, insuranceProviderLabel;

  /**
   * Note: insurance_provider_id possible values information
   * -1 -> reimbursement_type -> Organization pays when insurance does not pay -> Insurance provider not listed
   * -2 -> reimbursement_type -> Organization pays when insurance does not pay -> Self Pay / Uninsured
   * -3 -> reimbursement_type -> Organization pays
   * -4 -> reimbursement_type -> Subscription
   * <valid id> -> reimbursement_type -> Insurance pays, valid insurance provider selected from insurance providers dropdown
   */
  switch (reimbursementType) {
    case 'organization_pays':
      insuranceProviderId = -3;
      insuranceProviderLabel = request_insurance_collection_enabled ? payload.insuranceProviderText : '';
      break;
    case 'subscription':
      insuranceProviderId = -4;
      insuranceProviderLabel = request_insurance_collection_enabled ? payload.insuranceProviderText : '';
      break;
    default:
      insuranceProviderId = payload.insuranceProvider?.value || -1;
      insuranceProviderLabel = payload.insuranceProvider?.label || '';
  }

  const coverageResource = {
    resourceType: 'Coverage',
    status: payload.status,
    beneficiary: {
      reference: `Patient/${patientFHIRId}`,
    },
    payor: [
      {
        identifier: {
          use: 'usual',
          system: 'http://extensions.aristamd.com/coverage/payer-id',
          value: insuranceProviderId,
        },
        display: insuranceProviderLabel,
      },
    ],
  };

  // include subscriberId field if the insurance member id is provided
  if (payload.insuranceMemberId) {
    coverageResource.subscriberId = payload.insuranceMemberId;
  }

  /**
   * For insurance_provider_id as:
   * -1 -> reimbursement_type -> Organization pays when insurance does not pay -> Insurance provider not listed
   * -2 -> reimbursement_type -> Organization pays when insurance does not pay -> Self Pay / Uninsured
   * -3 -> reimbursement_type -> Organization pays
   * -4 -> reimbursement_type -> Subscription
   * Proceed to send payor_organization_id
   */
  if ([-1, -2, -3, -4].includes(insuranceProviderId)) {
    const { reimbursement_payor_organization_id } = user?.organization?.settings;
    const payorOrganization = {
      identifier: {
        use: 'usual',
        system: 'http://extensions.aristamd.com/coverage/payer-organization-id',
        value: reimbursement_payor_organization_id,
      },
      display: '',
    };
    coverageResource.payor.push(payorOrganization);
  }

  try {
    yield put(isFetchingCoverage(true));
    const response = yield axios.post(`${EnvironmentSettings.fhirApiUrl}/Coverage`, coverageResource);
    if (!response.error) {
      ({ data } = response);
    }
  // Set the coverage as it is once the process finalizes
  } finally {
    yield put(isFetchingCoverage(false));
    yield put(coverage(data));
  }

  return data;
}
