import createReducer from '../createReducer';

/** ********************************************************
 * CONSTANTS
 ******************************************************** */

export const PRINT_QUEUE = 'printQueue/PRINT_QUEUE';
export const FETCH_PRINT_QUEUE = 'printQueue/FETCH_PRINT_QUEUE';
export const FETCHING_PRINT_QUEUE = 'printQueue/FETCHING_PRINT_QUEUE';
export const FETCH_PRINT_QUEUE_ERROR = 'printQueue/FETCH_PRINT_QUEUE_ERROR';
export const CLEAR_PRINT_QUEUE = 'printQueue/CLEAR_PRINT_QUEUE';
export const CLEARING_PRINT_QUEUE = 'printQueue/CLEARING_PRINT_QUEUE';
export const CLEAR_PRINT_QUEUE_ERROR = 'printQueue/CLEAR_PRINT_QUEUE_ERROR';
export const CLEARING_PRINT_QUEUE_ALL = 'printQueue/CLEARING_PRINT_QUEUE_ALL';
export const CLEARING_PRINT_QUEUE_NAMES = 'printQueue/CLEARING_PRINT_QUEUE_NAMES';

/** ********************************************************
 * REDUCER
 ******************************************************** */

export const initialStatePrintQueue = {
  printQueue: null,
  isFetchingPrintQueue: false,
  isFetchPrintQueueError: false,
  isClearingPrintQueue: false,
  isClearingPrintQueueAll: false,
  isClearPrintQueueError: false,
  clearingPrintQueueNames: [],
  filesToClear: [],
};

export default createReducer(initialStatePrintQueue, {
  [PRINT_QUEUE]: (state, { payload }) => ({
    ...state, printQueue: payload,
  }),
  [FETCHING_PRINT_QUEUE]: (state, { payload }) => ({
    ...state, isFetchingPrintQueue: payload,
  }),
  [FETCH_PRINT_QUEUE_ERROR]: (state, { payload }) => ({
    ...state, isFetchPrintQueueError: payload,
  }),
  [CLEARING_PRINT_QUEUE]: (state, { payload }) => ({
    ...state, isClearingPrintQueue: payload,
  }),
  [CLEARING_PRINT_QUEUE_ALL]: (state, { payload }) => ({
    ...state, isClearingPrintQueueAll: payload,
  }),
  [CLEARING_PRINT_QUEUE_NAMES]: (state, { payload }) => ({
    ...state, clearingPrintQueueNames: payload,
  }),
  [CLEAR_PRINT_QUEUE_ERROR]: (state, { payload }) => ({
    ...state, clearPrintQueueError: payload,
  }),
  [CLEAR_PRINT_QUEUE]: (state, { payload }) => ({
    ...state, filesToClear: payload,
  }),
});

/** ********************************************************
 * ACTIONS
 ******************************************************** */

/**
 * Sets the print queue
 * @param {[]} payload
 * @returns {{type: string, payload: []}}
 */
export const printQueue = payload => ({
  type: PRINT_QUEUE,
  payload,
});

/**
 * Fetches the print queue
 * @returns {{type: string}}
 */
export const fetchPrintQueue = () => ({
  type: FETCH_PRINT_QUEUE,
});

/**
 * Used by the UI to determine if the form is ready to be shown
 * @param {boolean} bool
 * @returns {{type: string, payload: boolean}}
 */
export const isFetchingPrintQueue = bool => ({
  type: FETCHING_PRINT_QUEUE,
  payload: bool,
});

/**
 * Flag to indicate error fetching print queue
 * @param {boolean} bool
 * @returns {{type: string, payload: boolean}}
 */
export const isFetchPrintQueueError = bool => ({
  type: FETCH_PRINT_QUEUE_ERROR,
  payload: bool,
});

/**
 * Clears the print queue
 * @param {[]} arrayId document ids to clear (if empty, all documents in the queue will be cleared)
 * @returns {{type: string}}
 */
export const clearPrintQueue = arrayId => ({
  type: CLEAR_PRINT_QUEUE,
  payload: arrayId,
});


/**
 * Used by the UI to determine if print queue clearing in progress
 * @param {boolean} bool
 * @returns {{type: string, payload: boolean}}
 */
export const isClearingPrintQueue = bool => ({
  type: CLEARING_PRINT_QUEUE,
  payload: bool,
});

/**
 * Used by the UI to determine if print queue clearing all in progress
 * @param {boolean} bool
 * @returns {{type: string, payload: boolean}}
 */
export const isClearingPrintQueueAll = bool => ({
  type: CLEARING_PRINT_QUEUE_ALL,
  payload: bool,
});

/**
 * Used by the UI to determine which documents are being cleared
 * @param {array} array
 * @returns {{type: string, payload: array}}
 */
export const clearingPrintQueueNames = array => ({
  type: CLEARING_PRINT_QUEUE_NAMES,
  payload: array,
});

/**
 * Flag to indicate error clearing print queue
 * @param {boolean} bool
 * @returns {{type: string, payload: boolean}}
 */
export const isClearPrintQueueError = bool => ({
  type: CLEAR_PRINT_QUEUE_ERROR,
  payload: bool,
});

/** ********************************************************
 * SELECTORS
 ******************************************************** */

/**
 * @param state
 * @returns {null|[]}
 */
export const selectPrintQueue = state => state.printQueue.printQueue;

/**
 * @param state
 * @returns {bool}
 */
export const selectIsFetchingPrintQueue = state => state.printQueue.isFetchingPrintQueue;

/**
 * @param state
 * @returns {bool}
 */
export const selectIsFetchPrintQueueError = state => state.printQueue.isFetchPrintQueueError;

/**
 * @param state
 * @returns {bool}
 */
export const selectIsClearingPrintQueue = state => state.printQueue.isClearingPrintQueue;

/**
 * @param state
 * @returns {bool}
 */
export const selectIsClearingPrintQueueAll = state => state.printQueue.isClearingPrintQueueAll;

/**
 * @param state
 * @returns {array}
 */
export const selectClearingPrintQueueNames = state => state.printQueue.clearingPrintQueueNames;

/**
 * @param state
 * @returns {bool}
 */
export const selectIsClearPrintQueueError = state => state.printQueue.isClearPrintQueueError;
