import createReducer from '../createReducer';

/** ********************************************************
 * CONSTANTS
 ******************************************************** */

export const FETCH_SERVICE_LOGS = 'serviceLogs/FETCH_SERVICE_LOGS';
export const FETCHING_SERVICE_LOGS = 'serviceLogs/FETCHING_SERVICE_LOGS';
export const SERVICE_LOGS = 'serviceLogs/SERVICE_LOGS';

/** ********************************************************
 * REDUCER
 ******************************************************** */

export const initialStateServiceLogs = {
  serviceLogsData: null,
  isFetchingLogs: false,
};

export default createReducer(initialStateServiceLogs, {
  [SERVICE_LOGS]: (state, { payload }) => ({
    ...state, serviceLogsData: payload,
  }),
  [FETCHING_SERVICE_LOGS]: (state, { payload }) => ({
    ...state, isFetchingLogs: payload,
  }),
});

/** ********************************************************
 * ACTIONS
 ******************************************************** */

/**
 * Fetch the service logs
 * @param {object} request
 * @returns {{type: FHIR object, payload: *}}
 */
export const fetchServiceLogs = payload => ({
  type: FETCH_SERVICE_LOGS,
  payload,
});

/**
 * When fetching the service logs
 * @param {object} request
 * @returns {{type: FHIR object, payload: *}}
 */
export const isFetchingServiceLogs = bool => ({
  type: FETCHING_SERVICE_LOGS,
  payload: bool,
});

/**
 * Set the service logs
 * @param {[]} payload
 * @returns {{type: string, payload: *}}
 */
export const serviceLogsData = payload => ({
  type: SERVICE_LOGS,
  payload,
});
