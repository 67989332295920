import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { debounce } from 'lodash';
import { Spinner } from 'reactstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchPatient, pediatricsFilterLink, isPatientSearched as setPatientSearched, setMRNRequired, patient as patientAction } from '../../../state';
import PatientForm from './PatientForm';
import PropTypes from "prop-types";
import EhrPatientNotFoundControls from './ehr/EhrPatientNotFoundControls';
import { MRN_NOT_FOUND_EHR, ERR_CONNECT_EHR, ERR_PATIENT_ACCESS_EHR } from '../../../utils/constants';
/**
 * Patient Look Up Component and Patient Form Component
 *
 */
const PatientMRNForm = ({ request, params }) => {
  const {
    clearErrors,
    errors,
    getValues,
    register,
    setValue,
    trigger,
    watch,
  } = useFormContext();

  let medicalRecordNumberFocus = false;

  const [editPatient, setEditPatient] = useState(false);
  const [mrnManualEntry, setMrnManualEntry] = useState(false);

  const {
    patient, isFetchingPatient, isMRNRequired, pesErrorMessage,
  } = useSelector(state => state.patient, shallowEqual);
  const { isFetchingStoredCoverage } = useSelector(state => state.coverage, shallowEqual);
  const { hasEhrEnabled } = useSelector(state => state.organization, shallowEqual);
  const { fieldsRefs } = useSelector(state => state.fieldRef, shallowEqual);
  const watchedFields = watch(['siteSelector']);

  const dispatch = useDispatch();

  const emptyErrorMessage = 'This field is required';
  const patternErrorMessage = 'Please remove any special character';

  const { isPatientSearched } = useSelector(state => state.patient, shallowEqual);

  const patientSearchRef = useRef();
  const ehrErrorMessages = [ERR_CONNECT_EHR, ERR_PATIENT_ACCESS_EHR];

  const onPatientEdit = (e) => {
    if (getValues().documents) {
      setValue('documents', getValues().documents.filter(attachment => attachment.patientDocument !== true));
    }
    e.preventDefault();
    setEditPatient(false);
    dispatch(setPatientSearched(false));
    dispatch(patientAction(null));

    medicalRecordNumberFocus = true;
  };

  const onMrnManualEntry = () => {
    setValue('mrnManualEntry', pesErrorMessage);
    setMrnManualEntry(pesErrorMessage);
  };

  function onPatientSearch() {
    dispatch(setMRNRequired(false));
    const patientMRN = getValues().patientSearch;
    if (patientMRN && errors?.patientSearch?.type !== 'pattern') {
      dispatch(fetchPatient(patientMRN, getValues().siteSelector));
      dispatch(setPatientSearched(true, true));
      dispatch(pediatricsFilterLink(null));
      // FORM-496: If a new patient is being fetched the attachments must be removed in the form
      setValue('documents', []);

      setEditPatient(true);
      clearErrors('patientSearch');
    } else {
      trigger('patientSearch');
    }
  }

  // avoid multiple clicks
  const handler = useCallback(debounce(onPatientSearch, 700, false), []);

  const onClickPatientSearch = () => {
    handler();
  };

  const handleKeyDown = (event) => {
    dispatch(setMRNRequired(false));
    if (event.key === 'Enter') {
      onPatientSearch(event);
    }
  };

  /**
   * If patient portability is not enabled and the user changes the selected site, then the
   * patient object and search flag will be reset. We then proceed to reset the patient form,
   * forcing user to look up patient again by MRN.
   */
  useEffect(() => {
    const patientWasReset = !patient && !isPatientSearched.isSearched;
    if (patientWasReset) {
      setEditPatient(false);
    }
  }, [patient, isPatientSearched]);

  useEffect(() => {
    if (request) {
      setEditPatient(true);
    }
  }, []);

  // Open edit form when patient has been loaded (e.g. eConsult is saved as
  // draft, EHR patient context)
  useEffect(() => {
    if (patient) {
      setEditPatient(true);
    }
  }, [patient]);

  useEffect(() => {
    if (errors && typeof fieldsRefs === 'string') {
      if (fieldsRefs === 'patientSearch') {
        patientSearchRef.current.focus();
      }
    }
  }, [errors, fieldsRefs]);

  return (
    <div className="card" data-aut="patient-search-component" id="patient-lookup">
      <div className="card-title p-3 m-0">
        <h6 className="font-weight-bold border-bottom pb-2 patient-search">Patient</h6>
      </div>
      <div className="card-body">
        <div className="form-group col-12 mb-2">
          <div className="row">
            <div className="col-12 col-sm-3 col-md-3 col-xl-2 text-sm-right">
              <label htmlFor="medicalRecordNumber" data-aut="medical-record-label" className="font-small">Medical Record Number</label>
              {editPatient &&
              <a
                className="stretched-link ml-3 d-inline d-sm-none"
                href="#"
                onClick={onPatientEdit}
              >
                Edit
              </a>
              }
            </div>
            <div className="form-group col-12 col-sm-9 col-md-8 col-lg-7 col-xl-6 submit-options">
              <div className="row">
                <div className="d-inline-block col-sm-8 col-md-7 col-xl-6">
                  <input
                    className={
                      errors.patientSearch || isMRNRequired
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    autoFocus={medicalRecordNumberFocus}
                    type="text"
                    name="patientSearch"
                    disabled={editPatient || !watchedFields.siteSelector}
                    onKeyDown={handleKeyDown}
                    data-auto="patient-search-input"
                    ref={(e) => {
                      register(e, {
                        required: emptyErrorMessage,
                        pattern: {
                          value: /^[A-Za-z0-9-]*$/,
                          message: patternErrorMessage,
                        },
                      });
                      patientSearchRef.current = e;
                    }}
                  />
                  <p className="text-muted mb-0">Copy directly from your EHR</p>
                  {(errors?.patientSearch?.type === 'required' || errors?.patientSearch?.type === 'pattern') && !isMRNRequired && (
                    <div className="alert alert-danger" role="alert" data-auto="patient-search-required-error">
                      <i className="fa fa-warning fa-sm" /> {errors.patientSearch.message}
                    </div>
                  )}
                  {isMRNRequired && (
                    <div className="alert alert-danger" role="alert" data-auto="patient-search-error">
                      <i className="fa fa-warning fa-sm" /> {'Patient look up is required. Please click "Next" to search for the patient.'}
                    </div>
                  )}
                </div>
                {(!editPatient || isFetchingPatient) &&
                <div className="col-12 mt-3">
                  <button
                    type="button"
                    className="btn btn-success submit-options"
                    onClick={onClickPatientSearch}
                    data-auto="patient-search-button"
                    disabled={!watchedFields.siteSelector || isFetchingPatient || isFetchingStoredCoverage}
                  >
                    Next {isFetchingPatient && <Spinner size="sm" />}
                  </button>
                </div>
                }
                <div className="col-sm-4 col-md-3 col-xl-4">
                  {editPatient && !getValues().ehrPatientContextId && !isFetchingPatient && pesErrorMessage !== MRN_NOT_FOUND_EHR &&
                  <a
                    className="stretched-link d-none d-sm-block"
                    href="#"
                    data-auto="patient-edit-link"
                    onClick={onPatientEdit}
                  >
                    Edit
                  </a>
                  }
                </div>
                {(!isFetchingPatient && !(patient || request?.patientId) && editPatient) &&
                <div className="col-12 mt-3">
                  {!getValues().mrnManualEntry && pesErrorMessage !== MRN_NOT_FOUND_EHR && <div className="alert alert-primary mb-0" role="alert" data-auto="patient-none-found-message">
                    {pesErrorMessage === '' ? 'No patient match found. Please enter patient details below to create their record.' : pesErrorMessage}
                    {/* If there is an issue connecting to the EHR or accessing the patient using PES, proceed to enable the patient form fields */}
                    { (ehrErrorMessages.includes(pesErrorMessage)) && onMrnManualEntry() }
                  </div>}
                  {ehrErrorMessages.includes(pesErrorMessage) && <div className="alert alert-primary mb-0" role="alert" data-auto="patient-none-found-message">
                    {pesErrorMessage}
                    </div>}
                  {( pesErrorMessage === MRN_NOT_FOUND_EHR && !getValues().mrnManualEntry && (
                    <>
                    <div className="alert alert-danger mb-0" role="alert" data-auto="patient-none-found-message">
                      <i className="fa fa-warning fa-sm pr-1"></i>
                      {pesErrorMessage}
                    </div>
                    <EhrPatientNotFoundControls onPatientEdit={onPatientEdit} onMrnManualEntry={onMrnManualEntry}/>
                    </>
                  )
                  )}
                </div>
                }
              </div>
            </div>
          </div>
        </div>
        {!isFetchingPatient && editPatient && (pesErrorMessage === '' || ehrErrorMessages.includes(pesErrorMessage) || getValues().mrnManualEntry) && (
          <PatientForm patient={patient} editPatient={editPatient} request={request} eConsultID={params.id} />
        )}
      </div>
    </div>
  );
};

PatientMRNForm.propTypes = {
  request: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default PatientMRNForm;
