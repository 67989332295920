import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../loading/Loading';
import SelectSite from '../select-site/SelectSite';
import { selectUser, selectUserOrganizations, selectIsAccessVerified } from '../../state';

/**
 * Loading Screen
 */
class Setup extends Component {
  constructor(props) {
    super(props);
    this.childDiv = React.createRef();
  }

  content() {
    if (this.props.isAccessVerified && this.props.user.id && this.props.organizations) {
      return <SelectSite />;
    }
    else {
      return <Loading />;
    }
  }

  render() {
    return (
      <div className="setup-wrapper">
        <img src="/images/aristamd_logo.png" height={60} alt="AristaMD" />
        <div className="module">
          { this.content() }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: selectUser(state),
  organizations: selectUserOrganizations(state),
  isAccessVerified: selectIsAccessVerified(state),
});

export default connect(mapStateToProps)(Setup);
