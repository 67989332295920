import axios from 'axios';
import { put } from 'redux-saga/effects';
import { insuranceProviders, isFetchingInsuranceProviders } from '../../../state';

export default function* fetchInsuranceProviders({ payload }) {
  yield put(isFetchingInsuranceProviders(true));
  try {
    const { data } = yield axios.get(`/organizations/${payload}/insurance_providers?active=true`);
    // we need to check if data is set first for unit test purposes
    // in the unit test the mock response can't be destructured, do data doesn't exist
    // that causes the unit test to fail because data is undefined
    if (data && data.errors) {
      console.error(data.errors);
      yield put(insuranceProviders(null));
    } else {
      const insuranceProvidersData = data.map((insuranceProviderData) => {
        const { id, display_name, eligible_coverage_lookup_enabled } = insuranceProviderData;
        return {
          value: id,
          label: display_name,
          eligible_coverage_lookup_enabled,
        };
      });
      yield put(insuranceProviders(insuranceProvidersData));
    }
  } catch (e) {
    console.error(e);
    yield put(insuranceProviders(null));
  }
  yield put(isFetchingInsuranceProviders(false));
}
