import React from 'react';
import PropTypes from 'prop-types';
import LoadingOverlay from '@ronchalant/react-loading-overlay';
import { Spinner } from 'reactstrap';
import { connect, useSelector, shallowEqual } from 'react-redux';
import { selectIsAccessVerified, selectUser, selectUserOrganizationId } from '../../state';

const MainPageLoadingOverlay = (props) => {
  const {
    isRequestOnProcess,
    isFetchingRequest,
  } = useSelector(state => state.serviceRequest, shallowEqual);
  const {
    isFetchingPatientContext,
    isPatientContextError,
  } = useSelector(state => state.patient, shallowEqual);

  let spinnerText = '';

  if (isFetchingRequest) {
    spinnerText = 'Loading Request...';
  } else if (isFetchingPatientContext) {
    spinnerText = 'Loading Patient Context...';
  } else if (isRequestOnProcess.showSpinner) {
    spinnerText = isRequestOnProcess.status === 'draft' ? 'Saving Draft...' : 'Submitting Request...';
  } else if (isPatientContextError) {
    spinnerText = 'Failed to load patient context.';
  }

  return (
    <LoadingOverlay
      active={!!spinnerText}
      spinner={<div className="_loading_overlay_spinner"><Spinner /></div>}
      text={spinnerText}
      className="h-100 spinner-overlay"
    >
      {props.children}
    </LoadingOverlay>
  );
};

MainPageLoadingOverlay.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
};

const mapStateToProps = state => ({
  user: selectUser(state),
  organizationId: selectUserOrganizationId(state),
  isAccessVerified: selectIsAccessVerified(state),
});

export default connect(mapStateToProps)(MainPageLoadingOverlay);
