import createReducer from '../createReducer';

/** ********************************************************
 * CONSTANTS
 ******************************************************** */

export const FETCH_CHIEF_COMPLAINTS = 'chiefComplaint/FETCH_CHIEF_COMPLAINTS';
export const FETCHING_CHIEF_COMPLAINTS = 'chiefComplaint/FETCHING_CHIEF_COMPLAINTS';
export const CHIEF_COMPLAINTS = 'chiefComplaint/CHIEF_COMPLAINTS';

/** ********************************************************
 * REDUCER
 ******************************************************** */

export const initialStateChiefComplaint = {
  chiefComplaints: null,
  isFetchingChiefComplaints: false,
};

export default createReducer(initialStateChiefComplaint, {
  [CHIEF_COMPLAINTS]: (state, { payload }) => ({
    ...state, chiefComplaints: payload,
  }),
  [FETCHING_CHIEF_COMPLAINTS]: (state, { payload }) => ({
    ...state, isFetchingChiefComplaints: payload,
  }),
});


/** ********************************************************
 * ACTIONS
 ******************************************************** */

/**
 * Fetches the chief complaints
 * @param {specialtyID} chief complaints
 * @returns {{type: string, payload: *}}
 */
export const fetchChiefComplaints = (specialtyID) => ({
  type: FETCH_CHIEF_COMPLAINTS,
  payload: { specialtyID },
});

/**
 * Used by the UI to determine if the form is ready to be shown
 * @param {boolean} bool
 * @returns {{type: string, payload: boolean}}
 */
export const isFetchingChiefComplaints = bool => ({
  type: FETCHING_CHIEF_COMPLAINTS,
  payload: bool,
});

/**
 * Sets the chief complaints
 * @param {[]} payload
 * @returns {{type: string, payload: *}}
 */
export const chiefComplaintsData = payload => ({
  type: CHIEF_COMPLAINTS,
  payload,
});