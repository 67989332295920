import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { history } from '../../store';
import RequestedByReview from '../review-page/RequestedByReview';
import PatientReviewPage from '../review-page/PatientReviewPage';
import ReasonForRequestReview from '../review-page/ReasonForRequestReview';
import EnvironmentSettings from '../../services/EnvironmentSettings';
import SubmittedBy from './SubmittedBy';
import PatientMatch from '../patient-match/PatientMatch';
import { serviceRequestData, patient, patientContextId } from '../../state';
import { trackEvent } from '../../services/AnalyticsService';
import {goToAllEConsults} from "../../services/NextApp";

/**
 * Review Page Container
*/
function ConfirmationPage() {
  const request = history.location?.state;
  const dispatch = useDispatch();

  const { ehr_patient_context_id: ehrPatientContextId } = useSelector((state) => state.user.context);
  const { appIframeSource } = useSelector((state) => state.app, shallowEqual);
  const { performer, status, extension } = useSelector((state) => state.serviceRequest.serviceRequestData, shallowEqual);
  const { organizations } = useSelector(
    (state) => state.organization, shallowEqual,
  );
  const statusCode = extension.find((e) => e.url === 'http://extensions.aristamd.com/servicerequest/status_code');

  const createRequest = () => {
    const siteData = extension.find((e) => e.url === 'http://extensions.aristamd.com/servicerequest/requesting_location');
    const site = siteData?.valueReference?.reference.split('/');
    trackEvent('Start Another Request', {
      site_id: site[1],
      site_name: organizations.find(
        // ids come as integers, siteSelector comes as string
        // eslint-disable-next-line eqeqeq
        (organization) => organization.id == site[1],
      )?.name,
    });
    dispatch(serviceRequestData(null));
    dispatch(patient(null));
    dispatch(patientContextId(null));
    history.push(
      '/econsults/create-request',
      null,
    );
  };

  const exitToCore = () => {
    if (appIframeSource === 'app') {
      window.location.href = `https://app${EnvironmentSettings.domainServer}/#/app/econsults`;
    } else {
      goToAllEConsults();
    }
  };

  if (!request) return null;

  return (
    <>
      <div data-aut="review-container" className="row container options-menu mb-3 mt-3">
        <div className="col-xs-8 col-sm-7">
          <h4 className="font-weight-bold new-request-title">
            eConsult Request ID#: C0
            {request.id}
          </h4>
        </div>
      </div>
      <div className="review-container container">
        <div className="row">
          <div className="col">
            <Card>
              <CardBody>
                {status === 'active'
                  ? (
                    <div className="alert alert-success clearfix" role="alert" data-aut="request-confirmation">
                      <span data-auto="confirmation-message" className="confirmation-text mr-3 mb-2 d-block d-sm-inline-block">
                        <i className="fas fa-check fa-sm mr-2" />
                        Your request has been submitted
                        {' '}
                        {performer && performer?.length ? `to ${performer[0].display}` : null }
                      </span>
                      <div className="float-md-right">
                        { !ehrPatientContextId
                          ? (
                            <>
                              <button type="button" className="btn btn-success mr-3" onClick={createRequest}>Create Another Request</button>
                              <button type="button" className="btn btn-light" onClick={exitToCore}>View all eConsults</button>
                            </>
                          )
                          : ''}
                      </div>
                    </div>
                  ) : null}
                {status === 'on-hold' && statusCode.valueString === 'pending_specialist_ehr_patient_match'
                  ? (
                    <div className="alert alert-warning clearfix" role="alert">
                      <PatientMatch />
                    </div>
                  ) : null}
                <SubmittedBy />
                <RequestedByReview />
                <PatientReviewPage />
                <ReasonForRequestReview />
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmationPage;
