import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import app from './app';
import browser from './browser';
import idleModal from './idleModal';
import user from './user/user';
import sideDrawer from './sideDrawer';
import organization from './organization/organization';
import provider from './provider/provider';
import specialty from './specialty/specialty';
import patient from './patient/patient';
import printQueue from './printQueue/printQueue';
import coverage from './patient/coverage';
import chiefComplaint from './chiefComplaint/chiefComplaint';
import workupChecklist from './workupChecklist/workupChecklist';
import fieldRef from './form/fieldRef';
import form from './form/form';
import serviceRequest from './serviceRequest/serviceRequest';
import serviceLogs from './serviceLogs/serviceLogs';
import insuranceProviders from './insurance_providers/insurance_providers';
import states from './states/states';
import asset from './asset/asset';

export default history => combineReducers({
  router: connectRouter(history),
  app,
  browser,
  idleModal,
  sideDrawer,
  user,
  organization,
  provider,
  specialty,
  patient,
  printQueue,
  coverage,
  chiefComplaint,
  workupChecklist,
  serviceRequest,
  serviceLogs,
  fieldRef,
  form,
  insuranceProviders,
  states,
  asset,
});
