import axios from 'axios';
import { has } from 'lodash';
import { put, select } from 'redux-saga/effects';
import { parseCoverage } from 'fhir-parser';
import {
  storedCoverage,
  isFetchingStoredCoverage,
  selectInsuranceProviders,
} from '../../../state';
import EnvironmentSettings from '../../../services/EnvironmentSettings';
import Toast from '../../../services/Toast';

export default function* fetchStoredCoverageByPatient({ payload }) {
  const { patientId, eConsultId } = payload;
  yield put(isFetchingStoredCoverage(true));
  try {
    const url = eConsultId
      ? `${EnvironmentSettings.fhirApiUrl}/Patient/${patientId}/coverage/${eConsultId}`
      : `${EnvironmentSettings.fhirApiUrl}/Patient/${patientId}/coverage`;
    const response = yield axios.get(url);

    const coverageResponse = parseCoverage(response.data);
    if (coverageResponse.error) {
      yield put(storedCoverage(null));
      // Show the parsing error message just when the server response is not empty.
      if (!(has(response.data, 'data') && response.data.data.length === 0)) {
        Toast.error('An error occurred while loading patient\'s insurance information.');
      }
    } else {
      const coverage = coverageResponse.parsedData[0];
      // Look up for the insuranceProvider previously selected from the list of insuranceProviders, 
      // and proceed to use it to set the eligible_coverage_lookup_enabled
      const insuranceProviders = yield select(selectInsuranceProviders);
      const insuranceProvider = insuranceProviders?.find(insuranceProviders => insuranceProviders.value === coverage?.insurance_provider_id);
      coverage.insurance_provider = {
        value: coverage?.insurance_provider_id,
        label: coverage?.insurance_provider_name,
        eligible_coverage_lookup_enabled: insuranceProvider?.eligible_coverage_lookup_enabled ?? false,
      };
      yield put(storedCoverage(coverage));
    }
  } catch (e) {
    console.error(e);
    yield put(storedCoverage(null));
    Toast.error('An error occurred while loading patient\'s insurance information.');
  }
  yield put(isFetchingStoredCoverage(false));
}
