import React, { useCallback, useState, useEffect } from 'react';

const DicomToolBox = ({ dicomApp }) => {
  const [presets, setPresets] = useState(null);
  const [selectedPreset, setSelectedPreset] = useState('');

  const onClickToolBoxOption = (value) => {
    dicomApp.onChangeTool.apply({ value });
  };

  // it changes the name to display for the selected preset
  const changeSelectedPresetName = (name) => {
    setSelectedPreset(name);
  };

  // It handles on click event of the different preset options
  // It applies the selected value and update the text of the identifier button
  const onPresetClick = (event, preset) => {
    event.preventDefault();
    dicomApp.onChangeWindowLevelPreset.apply({ value: preset });
    changeSelectedPresetName(preset);
  };

  // it sets the default value for selected preset
  const setDefaultPresetValue = () => {
    changeSelectedPresetName('a');
  };

  // It reset the UI container with default vaules
  const onReset = () => {
    dicomApp.resetLayout();
    dicomApp.initWLDisplay();
    setDefaultPresetValue();
  };

  // it shares with UI the list of available presets
  const initPresets = useCallback(() => {
    setPresets(dicomApp.getViewController().getPresets());
    setDefaultPresetValue();
    dicomApp.onChangeTool.apply({ value: 'Scroll' });
  }, [dicomApp]);

  useEffect(() => {
    // We need to check first if the presets have been set before use it
    if (dicomApp?.getViewController() && dicomApp?.getViewController().getPresets()) {
      initPresets();
    } else if (dicomApp) {
      // if they have not been loaded we need to listen the event
      dicomApp.addEventListener('load-end', initPresets);
    }

    return () => dicomApp?.removeEventListener('load-end', initPresets);
  }, [dicomApp]);

  if (!dicomApp) return null;

  return (
    <div className="app-header navbar" data-aut="tool-box">
      <div className="navbar-header">
        <div id="dicom-viewer-controls" className="btn-group controls" data-toggle="buttons">
          <label htmlFor="scroll" className="btn btn-info active" onClick={() => { onClickToolBoxOption('Scroll'); }}>
            <input id="scroll" className="d-none" type="radio" /> Scroll
          </label>
          <label htmlFor="zoomandpan" className="btn btn-info" onClick={() => { onClickToolBoxOption('ZoomAndPan'); }}>
            <input id="zoomandpan" className="d-none" type="radio" /> Zoom/Pan
          </label>
          <label htmlFor="windowlevel" className="btn btn-info" onClick={() => { onClickToolBoxOption('WindowLevel'); }}>
            <input id="windowlevel" className="d-none" type="radio" /> W/L
          </label>
        </div>
        <div className="btn-group">
          <button data-aut="reset-button" className="btn btn-primary" onClick={() => { onReset(); }}>Reset</button>
        </div>
        <span className="presets">Presets:</span>
        <div className="btn-group dropdown">
          <button id="dropdownMenuButton" type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {selectedPreset} <span className="caret" />
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            { presets && Object.entries(presets).map(([key, value]) => {
              return (
                <a key={key} className="dropdown-item" href="#" onClick={(e) => { onPresetClick(e, key); }}>{ key }</a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DicomToolBox;
