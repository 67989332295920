import React from 'react';
import cogoToast from 'rb-cogo-toast';

/**
 * This service uses the cogo-toast package to display toast messages
 * It's compatible with IE11, Chrome, Safari, etc
 * https://cogoport.github.io/cogo-toast/
 */
class Toast {
  /**
   * Display an error toast message
   * @param text
   */
  static error = (text) => {
    const { hide } = cogoToast.error(text, {
      hideAfter: 0,
      onClick: () => {
        hide();
      },
    });
  };

  /**
   * Display a success toast message
   * @param text
   */
  static success = (text) => {
    const { hide } = cogoToast.success(text, {
      hideAfter: 0,
      onClick: () => {
        hide();
      },
    });
  };

  /**
   * Display an info toast message
   * @param text
   */
  static info = (text) => {
    const { hide } = cogoToast.info(text, {
      hideAfter: 0,
      onClick: () => {
        hide();
      },
    });
  };
}

export default Toast;
