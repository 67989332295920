import React from 'react';
import { history } from '../../store';
/**
 * Requested By Review
*/
function RequestedByReview({ edit, onEdit }) {
  const request = history.location?.state;

  return (
    <div className="row mb-4" id="requested-by-review">
      <div className="col-12 mb-3">
        <h6 className="font-weight-bold border-bottom pb-2 requested-by-review">Requested by</h6>
      </div>
      <div className="col-12 col-lg-10 w-100 pl-0">
        <div className="labels-wrapper d-inline-block">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-sm-5 col-md-4 text-sm-right">
                <p className="font-weight-bold mb-1 mb-sm-2">Provider</p>
              </div>
              <div className="col-12 col-sm-5 col-md-6 col-xl-6 mb-3 mb-sm-0">
                <span data-auto="txt-provider-name">{request?.providerSelector?.label}</span>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-sm-5 col-md-4 text-sm-right">
                <p className="font-weight-bold mb-1 mb-sm-2">Organization</p>
              </div>
              <div className="col-12 col-sm-5 col-md-6 col-xl-6 mb-3 mb-sm-0">
                <span data-auto="txt-organization-name">{request?.organization?.display_name}</span>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-sm-5 col-md-4 text-sm-right">
                <p className="font-weight-bold mb-1 mb-sm-2">Site</p>
              </div>
              <div className="col-12 col-sm-5 col-md-6 col-xl-6 mb-3 mb-sm-0">
                <span data-auto="txt-site-name">{request?.siteSelector?.label}</span>
              </div>
            </div>
          </div>
        </div>
        { edit
        && (
        <div className="col-12 col-sm-1 col-md-2 submit-options d-inline-block pl-3">
          <button
            type="button"
            className="btn btn-light ml-1"
            onClick={() => {
              onEdit({ provider: true });
            }}
          >
            <i className="fas fa-pencil-alt d-inline mr-1" />
            Edit
          </button>
        </div>
        )}
      </div>
    </div>
  );
}

export default RequestedByReview;
