import React from 'react';
import {
  UncontrolledDropdown,
  DropdownToggle,
  Collapse,
  Nav,
} from 'reactstrap';
import { connect, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { selectUserOrganizations, selectUserOrganizationId, userOrganizationName } from '../../state';
import store from '../../store';

/**
 * Empty component, Selects organization id by default as Organization menu does
 * @returns {JSX.Element}
 * @constructor
 */
export const DefaultOrgSelector = () => {
  const organizations = useSelector(state => state.user.organizations, shallowEqual)
  const organizationId = useSelector(state => state.user.organizationId, shallowEqual)
  const organizationObject = organizations.filter(obj => obj.id === parseInt(organizationId, 10));
  store.dispatch(userOrganizationName(organizationObject[0]));
  return (<></>)
}

const OrganizationMenu = ({ organizations, organizationId }) => {
  const organizationObject = organizations.filter(obj => obj.id === parseInt(organizationId, 10));
  store.dispatch(userOrganizationName(organizationObject[0]));
  return (
    <Collapse navbar>
      <Nav className="mr-auto" navbar>
        <UncontrolledDropdown inNavbar>
          <DropdownToggle color="secondary" nav className="d-none d-sm-inline-block textbox">
            <i className="fa fa-map-marker-alt fa-sm" />
            <span className="ml-2">{organizationObject[0].display_name}</span>
          </DropdownToggle>
        </UncontrolledDropdown>
      </Nav>
    </Collapse>
  );
};

const mapStateToProps = state => ({
  organizations: selectUserOrganizations(state),
  organizationId: selectUserOrganizationId(state),
});

OrganizationMenu.propTypes = {
  organizations: PropTypes.instanceOf(Array),
  organizationId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

OrganizationMenu.defaultProps = {
  organizations: [],
  organizationId: '',
};

export default connect(mapStateToProps)(OrganizationMenu);
