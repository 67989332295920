import axios from 'axios';
import { put } from 'redux-saga/effects';
import {
  printQueue,
  isFetchingPrintQueue,
  isFetchPrintQueueError,
} from '../../state';
import Toast from '../../services/Toast';

// get the attachments associated to the request
function documentsLoop(file, documents) {
  const documentReference = {
    id: file.basename.replace(/([ .])/g, '_').toLowerCase(),
    name: file.basename,
    size: file.size,
    type: file.type,
    // unix timestamp multiplied by 1000 so that the argument is in milliseconds, not seconds.
    lastModified: file.timestamp * 1000,
    thumbnail: file.thumbnail,
    urlPreview: file.urlPreview,
    patientDocument: false,
    serviceRequestDocument: false,
    source: 'aristamd_print_queue',
    isAdded: false,
    isClearing: false,
  };
  return {
    ...documentReference,
    ...documents,
  };
}
/**
 * Fetch Print Queue documents into the redux store
 */
export default function* fetchPrintQueue() {
  let supportingInfoData = null;
  yield put(isFetchPrintQueueError(false));
  yield put(isFetchingPrintQueue(true));
  try {
    const response = yield axios.get('/print-queue');
    supportingInfoData = response.data.map(file => documentsLoop(file, supportingInfoData));
    yield put(printQueue(supportingInfoData));
  } catch (e) {
    Toast.error('Unable to refresh print queue.');
    yield put(isFetchPrintQueueError(true));
  }
  // Restore original state when print queue is fetched
  yield put(isFetchingPrintQueue(false));
}
