import axios from 'axios';
import {
  put, all, select, call,
} from 'redux-saga/effects';
import { parseServiceRequest } from 'fhir-parser';
import EnvironmentSettings from '../../../services/EnvironmentSettings';

import {
  serviceRequestData,
  isRequestOnProcess,
  serviceRequestDraftData,
  patient,
  workupChecklistsData,
  isPatientSearched as setPatientSearched,
  selectUser,
  patientFHIR,
  selectContext,
  postServiceRequestError,
} from '../../../state';
import clearPrintQueue from '../../printQueue/clearPrintQueue';
import Session from '../../../services/Session';
import documentsLoop from './postServiceRequestUtils/documentsLoop';
import postCoverage from './postServiceRequestUtils/postCoverage';
import postPatient from './postServiceRequestUtils/postPatient';
import { trackEvent } from '../../../services/AnalyticsService';

export default function* postServiceRequest({ payload }) {
  yield put(isRequestOnProcess({ showSpinner: true, status: payload.status }));
  yield put(serviceRequestData(null));
  yield put(serviceRequestDraftData(null));
  yield put(postServiceRequestError(false));
  const user = yield select((state) => selectUser(state));
  const context = yield select((state) => selectContext(state));
  const documents = [];
  let supportingInfoData = null;
  let patientData;
  let coverage;
  let nestedResourcesSaved;
  try {
    patientData = yield postPatient(payload, user);
    coverage = yield postCoverage(payload, patientData.fhirID);
    nestedResourcesSaved = true;
  } catch (e) {
    nestedResourcesSaved = false;
  }
  // Save the service request only if nested resources were saved as expected
  if (nestedResourcesSaved) {
    try {
      let serviceRequest = {
        resourceType: 'ServiceRequest',
        status: payload.status,
        subject: {
          reference: `Patient/${patientData.fhirID}`,
        },
        requester: {
          reference: `Practitioner/${payload.providerSelector.value}`,
        },
        reasonCode: [
          {
            coding: [
              {
                system: 'https://system.aristamd.com/specialty-id',
                code: payload.specialtySelector?.value,
              },
            ],
          },
          {
            coding: [
              {
                system: 'https://system.aristamd.com/chief-complaint-id',
                code: payload.chiefComplaintSelector?.value,
              },
            ],
            text: payload.otherText || '',
          },
        ],
        extension: [
          {
            url: 'http://extensions.aristamd.com/servicerequest/main_question',
            valueString: payload.textEditor,
          },
          {
            url: 'http://extensions.aristamd.com/servicerequest/requesting_location',
            valueReference: {
              reference: `Location/${payload.siteSelector.value}`,
            },
          },
          {
            url: 'http://extensions.aristamd.com/servicerequest/referral_order_id',
            valueString: payload.ehrReferralOrderId ? payload.ehrReferralOrderId : '',
          },
          {
            url: 'http://extensions.aristamd.com/servicerequest/referral_procedure_id',
            valueString: payload.ehrReferralProcedureId ? payload.ehrReferralProcedureId : '',
          },
          {
            url: 'http://extensions.aristamd.com/servicerequest/referral_procedure_name',
            valueString: payload.ehrReferralProcedureName ? payload.ehrReferralProcedureName : '',
          },
          {
            url: 'http://extensions.aristamd.com/servicerequest/sla_acknowledge',
            valueBoolean: payload.slaAcknowledge ? payload.slaAcknowledge : false,
          },
        ],
        meta: {
          source: 'aristamd/request-form/v2',
        },
      };
      // Add coverage reference if valid coverage is available
      if (coverage) {
        serviceRequest.insurance = { reference: `Coverage/${coverage.id}` };
      }

      // Add encounter CSN from the form or smart if it is available
      const requestCsn = payload.epicEncounterCSN || context.smart?.csn;
      if (requestCsn) {
        serviceRequest.extension.push({
          url: 'http://extensions.aristamd.com/servicerequest/request_epic_encounter_csn',
          valueString: requestCsn,
        });
      }

      // Add patient consent from the form if it is available
      if (payload.patientConsent !== null) {
        serviceRequest.extension.push({
          url: 'http://extensions.aristamd.com/servicerequest/patient_consent',
          valueBoolean: payload.patientConsent,
        });
      }

      // if there is attachments associated post each of them
      if (payload.documents?.length > 0 && payload.status !== 'revoked') {
        supportingInfoData = yield all(
          payload.documents.map(
            (file) => documentsLoop(file, patientData.fhirID, documents, payload.status),
          ),
        );
        serviceRequest = { ...serviceRequest, supportingInfo: supportingInfoData };
      }
      const response = yield axios[payload.fhirID ? 'put' : 'post'](
        `${EnvironmentSettings.fhirApiUrl}/ServiceRequest${payload.fhirID ? `/${payload.fhirID}` : ''}`,
        payload.fhirID ? { ...serviceRequest, id: payload.fhirID } : serviceRequest,
      );
      if (payload.status === 'draft') {
        let actionParameters;
        const identifier = response.data.identifier.find((id) => id.system === 'aristamd_econsult_id');
        actionParameters = { action: payload.action };
        // track event just the first time request is saved as draft
        if (!payload.fhirID) {
          trackEvent('Save eConsult Draft', {
            econsult_id: identifier.value,
            provider_id: payload?.providerSelector?.value,
            provider_name: payload?.providerSelector?.label,
            site_id: payload?.siteSelector?.value,
            site_name: payload?.siteSelector?.label,
            specialty_name: payload?.specialtySelector?.label,
            chief_complaint_name: payload?.chiefComplaintSelector?.label,
            specialty_id: payload?.specialtySelector?.value,
            chief_complaint_id: payload?.chiefComplaintSelector?.value,
          });
        }
        if (payload.service_message) {
          actionParameters = { ...actionParameters, service_message: payload.service_message };
        }
        yield axios.post(`/econsults/${identifier.value}/events`, actionParameters);
      }
      if (response.error) {
        yield put(serviceRequestData(response.error));
      } else {
        yield put(serviceRequestData(response.data));
        yield put(workupChecklistsData(null));
        const econsultData = parseServiceRequest(response.data);
        if (econsultData.parsedData[0].status !== 'on-hold' && econsultData.parsedData[0].status_code !== 'pending_specialist_ehr_patient_match') {
          yield put(patient(null));
          yield put(patientFHIR(null));
        }
        yield put(setPatientSearched(false));
        // Clear print queue of any documents attached to the service request
        if (['active', 'draft'].includes(payload.status)
          && Session.verifyPermissions(user, 'print_queue_access')) {
          yield call(clearPrintQueue, { payload: [] });
        }
      }
    } catch (e) {
      console.error(e);
      yield put(postServiceRequestError(true));
      if (e.response?.data?.issue?.[0]?.code === 'duplicate') {
        yield put(serviceRequestData('Request duplicated'));
      } else if (e.response?.data?.issue?.[0]?.code === 'invalid') {
        yield put(serviceRequestData(e.response?.data?.issue[0]?.diagnostics));
      } else {
        yield put(serviceRequestData('Request failed'));
      }
    }
  } else {
    yield put(serviceRequestData('Request failed'));
    yield put(serviceRequestDraftData(null));
    yield put(postServiceRequestError(true));
  }
  yield put(isRequestOnProcess({ showSpinner: false, status: payload.status }));
}
