/* Constant used to validate MRN not found */
export const MRN_NOT_FOUND_EHR = 'MRN not found, please confirm in your EHR';

/* Constant used to validate Error communicating with the EHR */
export const ERR_CONNECT_EHR = 'Cannot connect to your EHR, please enter patient\'s information.';

/* Constant used to report an  Error communicating with the EHR */
export const ERR_ALERT_REASON_EHR = 'Cannot connect to EHR, through PES Service';

/* Constant used to report an Error communicating with the PES */
export const ERR_ALERT_REASON_PES = 'Cannot connect to PES service';

/* Constant used to validate Patient access error with the EHR */
export const ERR_PATIENT_ACCESS_EHR = 'Cannot access the patient, please enter patient\'s information.';
