import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import dwv from 'dwv';

const DicomThumbnail = ({ doc, clickMethod, unitTest }) => {
  const { id: docId, urlPreview: docUrl } = doc;
  useEffect(() => {
    // base function to get elements
    if (!unitTest) {
      dwv.gui.getElement = dwv.gui.base.getElement;
      const thumbnailContainer = new dwv.App();
      thumbnailContainer.init({
        containerDivId: docId,
      });
      thumbnailContainer.loadURLs([docUrl]);
    }
  }, []);

  return (
    <div id={docId} data-auto="dicom-thumbnail-container">
      <div className="layerContainer card-img dicom-card-img" onClick={() => { clickMethod(doc); }}>
        <canvas className="imageLayer card-img h-100" />
      </div>
    </div>
  );
};




export default DicomThumbnail;
