import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import AddDocuments from '../../form/request-reason-section/AddDocuments';

function PatientDocuments({ enablePrintQueue }) {
  const { patient, isFetchingPatient, isPatientSearched } = useSelector(state => state.patient, shallowEqual);
  const [modal, openModal] = useState(false);

  const viewPatientDocuments = (e) => {
    e.preventDefault();
    openModal(true);
  }

  const hidePatientD = () => {
    openModal(false);
  }

  if (!patient?.documents?.length > 0 || !isPatientSearched) return null;

  return (
    <>
    { modal &&
     <AddDocuments show={false} open={modal} callbackMethod={hidePatientD} documents={patient?.documents} defaultTab={'3'} enablePrintQueue={enablePrintQueue} />
    }
      <div className="col-12 m-xs-auto">
        <div className="offset-xs-3 offset-sm-4 offset-md-3 offset-lg-3 offset-xl-4 col-12 col-sm-8 col-lg-6 col-xl-8 p-0">
          <div className="alert alert-info ml-sm-2 mr-lg-3" role="alert" data-aut="request-confirmation">
           <i className="fa fa-info-circle fa-sm" />
           <span className=""> Patient has {patient.documents.length} documents available to attach. <a href="#" onClick={viewPatientDocuments}>View and Select</a></span>
          </div>
        </div>
      </div>
    </>
  );
}

export default PatientDocuments;
