import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Dashboard } from '@uppy/react';
import Uppy from '@uppy/core';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import dwv from 'dwv';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { assetThumbnailUrl, assetUrl, setCallbackValue } from '../utils/index';
import EnvironmentSettings from './EnvironmentSettings';

const XHRUpload = require('@uppy/xhr-upload');

function DicomUploader({ isFileUploadCompleted }) {
  const handleClose = () => {};

  const {
    setValue,
    getValues,
  } = useFormContext();

  const authCookieName = `aristamd_auth${EnvironmentSettings.stage ? '_dev' : ''}`;
  let cookie = Cookies.get(authCookieName);
  if (cookie) {
    cookie = JSON.parse(cookie);
  }

  const uppy = React.useMemo(() => new Uppy({
    id: 'dicomUploader',
    meta: { // Any extra field required by the endpoint
      security_profile: 'sensitive',
    },
    autoProceed: true,
    restrictions: {
      maxFileSize: 100 * 1024 * 1024, // 100MB
      allowedFileTypes: [ // Allowed extensions
        '.dcm',
      ],
    },
  })
    .use(XHRUpload, {
      endpoint: '/api/assets',
      fieldName: 'content', // What is the name of the field for file
      metaFields: ['security_profile'], // What meta info will be sent to BE
      // Default response transformation
      getResponseData: responseText => ({ id: responseText }),
      headers: {
        authorization: `Bearer ${cookie?.access_token}`,
      },
      limit: 3,
    })
    .on('complete', (files) => {
      const newAttachments = files.successful.map(file =>
        // Creates the file structure to be added to the eConsult
        ({
          id: file.response.body.id,
          name: file.name,
          size: file.size, // Required to recreate the uploaded file list
          type: file.type, // Required to recreate the uploaded file list
          lastModified: file.data.lastModified, // Required to recreate the uploaded file list
          thumbnail: `${assetThumbnailUrl(file.response.body.id)}?width=200`,
          urlPreview: assetUrl(file.response.body.id),
        }));
      const currentAttachments = getValues().documents || [];
      Array.prototype.push.apply(currentAttachments, newAttachments);
      setValue('documents', currentAttachments);
      setCallbackValue(isFileUploadCompleted, true);
    })
    .on('file-removed', (file, reason) => {
      if (file.response?.body && reason === 'removed-by-user') {
        setValue('documents', getValues().documents
          .filter(attachments => attachments.id !== file.response.body.id));
      }
    })
    .on('upload', () => {
      setCallbackValue(isFileUploadCompleted, false);
    })
    .on('cancel-all', () => {
      setCallbackValue(isFileUploadCompleted, true);
    }), []);

  useEffect(() => {
    const fileAdded = (file, data) => {
      uppy.setFileState(file.id, { ...{ preview: null } });
      // create structure for canvas
      const divThumbnailContainer = document.createElement('DIV');
      divThumbnailContainer.style.height = 0;
      divThumbnailContainer.setAttribute('ID', `uppythumbnail_${file.id}`);

      const divLayerContainer = document.createElement('DIV');
      divLayerContainer.classList.add('layerContainer');
      divThumbnailContainer.appendChild(divLayerContainer);

      const canvasUploader = document.createElement('CANVAS');
      canvasUploader.setAttribute('ID', `canvas_${file.id}`);
      canvasUploader.classList.add(['imageLayer']);
      divLayerContainer.appendChild(canvasUploader);
      document.querySelector('body').appendChild(divThumbnailContainer);

      dwv.gui.getElement = dwv.gui.base.getElement;
      const thumbnailContainer = new dwv.App();
      thumbnailContainer.init({
        containerDivId: `uppythumbnail_${file.id}`,
      });

      thumbnailContainer.addEventListener('load-end', () => {
        const canvas = document.getElementById(`canvas_${file.id}`);
        uppy.setFileState(file.id, { ...{ preview: canvas.toDataURL('image/png') } });
        document.getElementById(`uppythumbnail_${file.id}`).remove();
      });

      const urlPreview = assetUrl(data.body.id);
      thumbnailContainer.loadURLs([urlPreview]);
    };
    uppy.on('upload-success', fileAdded);
    return () => {
      uppy.off('upload-success', fileAdded);
    };
  }, [uppy]);

  useEffect(() => () => uppy.close(), []);

  return (
    <Dashboard
      uppy={uppy}
      height={400}
      inline
      showRemoveButtonAfterComplete
      onRequestClose={handleClose}
      locale={{
        strings: {
          // Text to show on the droppable area.
          // `%{browse}` is replaced with a link that opens the system file selection dialog.
          dropPaste: 'Drop files here, paste or %{browse}',
          // Used as the label for the link that opens the system file selection dialog.
          browse: 'browse',
        },
      }}
    />
  );
}

const {
  func,
} = PropTypes;

DicomUploader.propTypes = {
  isFileUploadCompleted: func,
};

DicomUploader.defaultProps = {
  isFileUploadCompleted: null,
};


export default DicomUploader;
