import { call, put, select } from 'redux-saga/effects';
import {
  isFetchingPatientContext, isPatientContextError,
  selectEhrPatientContextId,
} from '../../../state';
import fetchPatient from './fetchPatient';

/**
 * Fetch patient data for EHR patient context (user logged in through Smart EHR)
 */
export default function* fetchPatientContext() {
  yield put(isFetchingPatientContext(true));
  const patientID = yield select(state => selectEhrPatientContextId(state));
  const payload = {
    patientID,
    patientIDType: 'aristamd_patient_id',
  };
  yield call(fetchPatient, { payload });
  const patient = yield select(state => state.patient.patient);
  if (!patient) {
    yield put(isPatientContextError(true));
  }
  yield put(isFetchingPatientContext(false));
}
