import { put, select } from 'redux-saga/effects';

import {
  selectBrowserViewport,
  setSideDrawerHeight,
  setIsSideDrawerOpen,
  setSideDrawerTop,
} from '../state';

export default function* browser() {
  const browserViewport = yield select(state => selectBrowserViewport(state));

  const height = browserViewport.greaterThan.small ? 'calc(100% - 50px)' : '100%';
  const top = browserViewport.greaterThan.small ? 50 : 0;

  yield put(setSideDrawerHeight(height));
  yield put(setSideDrawerTop(top));
  yield put(setIsSideDrawerOpen(browserViewport.greaterThan.medium));
}
