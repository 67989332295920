import axios from 'axios';
import { put } from 'redux-saga/effects';
import { parseCodeSystem } from 'fhir-parser';
import EnvironmentSettings from '../../../services/EnvironmentSettings';
import { sortByWithoutOther } from '../../../utils';
import {
  chiefComplaintsData,
  isFetchingChiefComplaints,
} from '../../../state';

export default function* fetchChiefComplaints({ payload }) {
  yield put(isFetchingChiefComplaints(true));
  yield put(chiefComplaintsData(null));
  try {
    // send specialty
    const response = yield axios.get(`${EnvironmentSettings.fhirApiUrl}/CodeSystem?name=AristaMDChiefComplaints&specialty-id=${payload.specialtyID}`);
    let chiefComplaintData = parseCodeSystem(response.data);
    if (chiefComplaintData.error) {
      console.log(chiefComplaintData.error);
      yield put(chiefComplaintsData(null));
    } else {
      chiefComplaintData = chiefComplaintData.parsedData.map(({ id, name }) => ({ value: id, label: name }));
      chiefComplaintData = chiefComplaintData.sort(sortByWithoutOther);
      yield put(chiefComplaintsData(chiefComplaintData));
    }
  } catch (e) {
    console.log(e);
    yield put(chiefComplaintsData(null));
  }
  // Restore original state when user is fetched
  yield put(isFetchingChiefComplaints(false));
}
