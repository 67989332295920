import createReducer from '../createReducer';

/** ********************************************************
 * CONSTANTS
 ******************************************************** */

export const FETCH_COVERAGE = 'coverage/FETCH_COVERAGE';
export const FETCHING_COVERAGE = 'coverage/FETCHING_COVERAGE';
export const COVERAGE = 'coverage/COVERAGE';

export const FETCH_STORED_COVERAGE = 'coverage/FETCH_STORED_COVERAGE';
export const FETCHING_STORED_COVERAGE = 'coverage/FETCHING_STORED_COVERAGE';
export const STORED_COVERAGE = 'coverage/STORED_COVERAGE';

/** ********************************************************
 * REDUCER
 ******************************************************** */

export const initialStateCoverage = {
  coverage: { isCovered: false },
  isFetchingCoverage: false,
  storedCoverage: { },
  isFetchingStoredCoverage: false,
};

export default createReducer(initialStateCoverage, {
  [COVERAGE]: (state, { payload }) => ({
    ...state, coverage: payload,
  }),
  [FETCHING_COVERAGE]: (state, { payload }) => ({
    ...state, isFetchingCoverage: payload,
  }),
  [STORED_COVERAGE]: (state, { payload }) => ({
    ...state, storedCoverage: payload,
  }),
  [FETCHING_STORED_COVERAGE]: (state, { payload }) => ({
    ...state, isFetchingStoredCoverage: payload,
  }),
});

/** ********************************************************
 * ACTIONS
 ******************************************************** */

/**
 * Makes a request to backend to retrieve if patient has insurance coverage
 * @param {object} payload - should contain the following attributes
 * organization_id
 * first_name
 * last_name
 * date_of_birth
 * gender
 * insurance_provider_id
 * member_id
 * @returns {{type: string, payload: *}}
 */
export const fetchCoverage = payload => ({
  type: FETCH_COVERAGE,
  payload,
});

/**
 * Used by the UI to determine if coverage info has already been loaded
 * @param {boolean} bool
 * @returns {{type: string, payload: boolean}}
 */
export const isFetchingCoverage = bool => ({
  type: FETCHING_COVERAGE,
  payload: bool,
});

/**
 * Sets the coverage
 * @param {[]} payload
 * @returns {{type: string, payload: *}}
 */
export const coverage = payload => ({
  type: COVERAGE,
  payload,
});

/**
 * Makes a request to backend to retrieve patient's coverage and check
 * if it is associated to an eConsult
 * @param {int} patientId
 * @param {string} eConsultId
 * @returns {{type: string, payload: *}}
 */
export const fetchStoredCoverage = (patientId, eConsultId) => ({
  type: FETCH_STORED_COVERAGE,
  payload: { patientId, eConsultId },
});

/**
 * Used by the UI to determine if stored coverage info is currently being fetched
 * @param {boolean} bool
 * @returns {{type: string, payload: boolean}}
 */
export const isFetchingStoredCoverage = bool => ({
  type: FETCHING_STORED_COVERAGE,
  payload: bool,
});

/**
 * Sets the stored coverage
 * @param {[]} payload
 * @returns {{type: string, payload: *}}
 */
export const storedCoverage = payload => ({
  type: STORED_COVERAGE,
  payload,
});

/** ********************************************************
 * SELECTORS
 ******************************************************** */

/**
 * @param state
 * @returns {null|{}}
 */
export const selectCoverage = state => state.coverage;
