import axios from 'axios';
import { put } from 'redux-saga/effects';

import {
  context,
  isFetchingContext,
  isContextError,
} from '../../state';

/**
 * Load context data for the user's session
 */
export default function* fetchContext() {
  yield put(isFetchingContext(true));
  try {
    const response = yield axios.get('/oauth/context');
    yield put(context(response.data || {}));
  } catch (e) {
    yield put(context(null));
    yield put(isContextError(true));
  }
  // Restore original state when user is fetched
  yield put(isFetchingContext(false));
}
