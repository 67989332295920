import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import AsyncSelect from 'react-select/async';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { fetchStates } from '../../../state';
import { filterOptions } from '../../../utils';
import { fillPatientData } from './PatientUtils';

const PatientAddress = ({ lockedFields, patient, request, lockFieldsCallback }) => {
  const dispatch = useDispatch();
  const [isFilledFromRequest, setIsFilledFromRequest] = useState(false);
  const [currentState, setCurrentState] = useState(null);
  const {
    register,
    errors,
    clearErrors,
    setValue,
    getValues,
  } = useFormContext();

  const emptyErrorMessage = 'This field is required';
  const lengthErrorMessage = 'The maximum number of characters allowed is';
  const patternErrorMessage = 'Please remove any special character';

  const street1Ref = useRef();
  const street2Ref = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const zipRef = useRef();

  const {
    states,
    isFetchingStates,
  } = useSelector((state) => state.states, shallowEqual);
  const { isPatientSearched } = useSelector((state) => state.patient, shallowEqual);

  const stateOptions = states
    .map((s) => ({ value: s.code, label: s.display_name }));

  async function fillPatientAddressData() {
    await setValue('street_1', patient?.address?.street_1);
    await setValue('street_2', patient?.address?.street_2);
    await setValue('city', patient?.address?.city);
    await setValue('zip', patient?.address?.zip);
    lockFieldsCallback();
  }

  const fillData = () => {
    if (patient && isPatientSearched.isSearched && isPatientSearched.userEvent) {
      fillPatientAddressData();
    } else if (request && (request.patientSearch === getValues().patientSearch)
      && !isFilledFromRequest) {
      fillPatientData(request, setValue);
      setIsFilledFromRequest(true);
    }
  };
  useEffect(() => {
    fillData();
  }, [patient, isPatientSearched]);

  useEffect(() => {
    if (patient && isPatientSearched.isSearched && isPatientSearched.userEvent) {
      const state = stateOptions.find(s => s.value === patient?.address?.state);
      setCurrentState(state);
      setValue('state', patient?.address?.state);
      lockFieldsCallback();
    } else if (request && (request.patientSearch === getValues().patientSearch)
      && !isFilledFromRequest) {
      const state = stateOptions.find(s => s.value === request.state);
      setCurrentState(state);
      lockFieldsCallback();
    }
  }, [states.length, isPatientSearched]);

  useEffect(() => {
    register({ name: 'state' }, { required: emptyErrorMessage });
    dispatch(fetchStates());
  }, []);

  const filterStates = (inputValue) => filterOptions(inputValue, stateOptions);

  const onSelectState = (selectedItem) => {
    setCurrentState(selectedItem);
    setValue('state', selectedItem.value);
    clearErrors('state');
  };

  const loadOptions = (inputValue, callback) => {
    callback(filterStates(inputValue));
  };

  return (
    <>
      <div className="form-group col-12 mb-2">
        <div className="row">
          <div className="col-12 col-sm-3 col-xl-2 text-sm-right">
            <label htmlFor="street_1" data-auto="patient-street_1-label">Address Line 1</label>
          </div>
          <div className="form-group col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
            <input
              type="text"
              disabled={lockedFields.street_1}
              ref={(ref) => {
                street1Ref.current = ref;
                register(ref, {
                  required: emptyErrorMessage,
                  maxLength: {
                    value: 100,
                    message: `${lengthErrorMessage} ${100}`,
                  },
                  pattern: {
                    value: /^[A-Za-z0-9'-\\ ]*$/,
                    message: patternErrorMessage,
                  },
                });
              }}
              id="street_1"
              name="street_1"
              className="form-control"
              data-auto="patient-street_1-input"
            />
            <ErrorMessage errors={errors} name="street_1">
              {({ messages, message: customMessage }) => {
                const errorMessage = messages || [customMessage];
                return errorMessage
                  && Object.entries(errorMessage).map(([type, message]) => (
                    <div key={type} className="alert alert-danger" role="alert" data-auto="patient-street_1-error">
                      <i className="fa fa-warning fa-sm" />
                      {' '}
                      {message}
                    </div>
                  ));
              }}
            </ErrorMessage>
          </div>
        </div>
      </div>
      <div className="form-group col-12 mb-2">
        <div className="row">
          <div className="col-12 col-sm-3 col-xl-2 text-sm-right">
            <label htmlFor="street_2" data-auto="patient-street_2-label">Address Line 2</label>
          </div>
          <div className="form-group col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
            <input
              type="text"
              ref={(ref) => {
                street2Ref.current = ref;
                register(ref, {
                  required: false,
                  maxLength: {
                    value: 100,
                    message: `${lengthErrorMessage} ${100}`,
                  },
                  pattern: {
                    value: /^[A-Za-z0-9'-\\ ]*$/,
                    message: patternErrorMessage,
                  },
                });
              }}
              disabled={lockedFields.street_2}
              id="street_2"
              name="street_2"
              className="form-control"
              data-auto="patient-street_2-input"
            />
            <ErrorMessage errors={errors} name="street_2">
              {({ messages, message: customMessage }) => {
                const errorMessage = messages || [customMessage];
                return errorMessage
                  && Object.entries(errorMessage).map(([type, message]) => (
                    <div key={type} className="alert alert-danger" role="alert" data-auto="patient-street_2-error">
                      <i className="fa fa-warning fa-sm" />
                      {' '}
                      {message}
                    </div>
                  ));
              }}
            </ErrorMessage>
          </div>
        </div>
      </div>
      <div className="form-group col-12 mb-2">
        <div className="row">
          <div className="col-12 col-sm-3 col-xl-2 text-sm-right">
            <label htmlFor="city" data-auto="patient-city-label">City</label>
          </div>
          <div className="form-group col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
            <input
              type="text"
              ref={(ref) => {
                cityRef.current = ref;
                register(ref, {
                  required: emptyErrorMessage,
                  maxLength: {
                    value: 100,
                    message: `${lengthErrorMessage} ${100}`,
                  },
                  pattern: {
                    value: /^[A-Za-z0-9'-\\ ]*$/,
                    message: patternErrorMessage,
                  },
                });
              }}
              disabled={lockedFields.city}
              name="city"
              id="city"
              className="form-control"
              data-auto="patient-city-input"
            />
            <ErrorMessage errors={errors} name="city">
              {({ messages, message: customMessage }) => {
                const errorMessage = messages || [customMessage];
                return errorMessage
                  && Object.entries(errorMessage).map(([type, message]) => (
                    <div key={type} className="alert alert-danger" role="alert" data-auto="patient-city-error">
                      <i className="fa fa-warning fa-sm" />
                      {' '}
                      {message}
                    </div>
                  ));
              }}
            </ErrorMessage>
          </div>
        </div>
      </div>
      <div className="form-group col-12 mb-2">
        <div className="row">
          <div className="col-12 col-sm-3 col-xl-2 text-sm-right">
            <label htmlFor="state" data-auto="patient-state-label">State</label>
          </div>
          <div className="form-group col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
            <AsyncSelect
              ref={stateRef}
              name="state"
              data-auto="state-select"
              isSearchable
              value={currentState}
              defaultOptions={stateOptions}
              loadOptions={loadOptions}
              isLoading={isFetchingStates}
              onChange={onSelectState}
              className="form-control-select"
              isDisabled={lockedFields.state || isFetchingStates}
            />
            <ErrorMessage errors={errors} name="state">
              {({ messages, message: customMessage }) => {
                const errorMessage = messages || [customMessage];
                return errorMessage
                  && Object.entries(errorMessage).map(([type, message]) => (
                    <div key={type} className="alert alert-danger" role="alert" data-auto="patient-state-error">
                      <i className="fa fa-warning fa-sm" />
                      {' '}
                      {message}
                    </div>
                  ));
              }}
            </ErrorMessage>
          </div>
        </div>
      </div>
      <div className="form-group col-12 mb-2">
        <div className="row">
          <div className="col-12 col-sm-3 col-xl-2 text-sm-right">
            <label htmlFor="zip" data-auto="patient-zip-label">Zip</label>
          </div>
          <div className="form-group col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
            <input
              type="text"
              ref={(ref) => {
                zipRef.current = ref;
                register(ref, {
                  required: emptyErrorMessage,
                  maxLength: {
                    value: 5,
                    message: `${lengthErrorMessage} ${5}`,
                  },
                  pattern: {
                    value: /^\d{5}$/,
                    message: 'Invalid zip code',
                  },
                });
              }}
              name="zip"
              id="zip"
              disabled={lockedFields.zip}
              className="form-control"
              data-auto="patient-zip-input"
            />
            <ErrorMessage errors={errors} name="zip">
              {({ messages, message: customMessage }) => {
                const errorMessage = messages || [customMessage];
                return errorMessage
                  && Object.entries(errorMessage).map(([type, message]) => (
                    <div key={type} className="alert alert-danger" role="alert" data-auto="patient-zip-error">
                      <i className="fa fa-warning fa-sm" />
                      {' '}
                      {message}
                    </div>
                  ));
              }}
            </ErrorMessage>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientAddress;
