import axios from 'axios';
import { put } from 'redux-saga/effects';
import { parseHealthcareService } from 'fhir-parser';
import EnvironmentSettings from '../../../services/EnvironmentSettings';
import { sortByWithoutOther } from '../../../utils';

import {
  specialties,
  isFetchingSpecialties,
} from '../../../state';

export default function* fetchSpecialties({ payload }) {
  yield put(isFetchingSpecialties(true));
  yield put(specialties(null));
  try {
    let response = null;
    if (!payload.filter) {
      response = yield axios.get(`${EnvironmentSettings.fhirApiUrl}/HealthcareService?organization=Organization/${payload.organizationID}`);
    } else {
      response = yield axios.get(`${EnvironmentSettings.fhirApiUrl}/HealthcareService?organization=Organization/${payload.organizationID}&service-type=${payload.filter}`);
    }
    let specialtiesData = parseHealthcareService(response.data);
    if (specialtiesData.error) {
      console.log(specialtiesData.error);
      yield put(specialties(null));
    } else {
      specialtiesData = specialtiesData.parsedData;
      specialtiesData = specialtiesData.map(({
        // eslint-disable-next-line camelcase
        id, name, pediatrics_only, keywords,
      }) => ({
        value: id, label: name, pediatrics_only, keywords,
      }));
      specialtiesData = specialtiesData.sort(sortByWithoutOther);
      yield put(specialties(specialtiesData));
    }
  } catch (e) {
    console.log(e);
    yield put(specialties(null));
  }
  // Restore original state when user is fetched
  yield put(isFetchingSpecialties(false));
}
