import createReducer from '../createReducer';

/** ********************************************************
 * CONSTANTS
 ******************************************************** */

export const FETCH_PROVIDERS = 'provider/FETCH_PROVIDERS';
export const FETCHING_PROVIDERS = 'provider/FETCHING_PROVIDERS';
export const PROVIDERS = 'provider/PROVIDERS';

/** ********************************************************
 * REDUCER
 ******************************************************** */

export const initialStateProvider = {
  providers: null,
  isFetchingProviders: false,
};

export default createReducer(initialStateProvider, {
  [FETCHING_PROVIDERS]: (state, { payload }) => ({
    ...state, isFetchingProviders: payload,
  }),
  [PROVIDERS]: (state, { payload }) => ({
    ...state, providers: payload,
  }),
});


/** ********************************************************
 * ACTIONS
 ******************************************************** */

/**
 * Fetches the providers
 * @param {int} providerId
 * @returns {{type: string, payload: *}}
 */
export const fetchProviders = providerId => ({
  type: FETCH_PROVIDERS,
  payload: providerId,
});

/**
 * Used by the UI to determine if the form is ready to be shown
 * @param {boolean} bool
 * @returns {{type: string, payload: boolean}}
 */
export const isFetchingProviders = bool => ({
  type: FETCHING_PROVIDERS,
  payload: bool,
});

/**
 * Sets the providers
 * @param {[]} payload
 * @returns {{type: string, payload: *}}
 */
export const providers = payload => ({
  type: PROVIDERS,
  payload,
});
/** ********************************************************
 * SELECTORS
 ******************************************************** */

/**
 * @param state
 * @returns {null|[]}
 */
export const selectProviders = state => state.provider.providers;

/**
 * @param state
 * @returns {boolean}
 */
export const selectIsFetchingProviders = state => state.provider.isFetchingProviders;
