import axios from 'axios';
import { put } from 'redux-saga/effects';
import { parseOrganization } from 'fhir-parser';
import EnvironmentSettings from '../../../services/EnvironmentSettings';
import {
  organizations,
  isFetchingOrganizations,
} from '../../../state';

export default function* fetchOrganizations(action) {
  try {
    yield put(isFetchingOrganizations(true));
    const response = yield axios.get(`${EnvironmentSettings.fhirApiUrl}/Organization?_filter=id sb ${action.payload} and status in ['live', 'training']`);
    const locations = parseOrganization(response.data);
    if (locations.error) {
      yield put(organizations(null));
    } else {
      yield put(organizations(locations.parsedData));
    }
  } catch (e) {
    console.log(e);
    // TODO: handle/display error?
    yield put(organizations(null));
  }
  // Restore original state when user is fetched
  yield put(isFetchingOrganizations(false));
}
