import React, { useState } from 'react';

const ServiceLogModal = ({
  modalId,
  modalTitle,
  modalButton,
  confirmButton,
}) => {
  const [logComment, setlogComment] = useState(null);

  const handleTextArea = (e) => {
    if (e.target.value) {
      setlogComment(e.target.value);
    } else {
      setlogComment(null);
    }
  };

  return (
    <div
      id={modalId}
      className="modal fade"
      data-aut="service-log-modal-wrap"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title font-weight-bold" id="modalLabel">{modalTitle}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body text-left">
            <p>Add notes to service log (optional)</p>
            <textarea data-auto="service-log-textarea" className="w-100 log-note" maxLength="300" onChange={handleTextArea} />
          </div>
          <div className="modal-footer d-block">
            <button
              data-aut="service-log-back-button"
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Back to Form
            </button>
            <button
              data-auto="service-log-confirm-button"
              type="button"
              className="btn btn-primary float-left"
              onClick={() => { confirmButton(logComment); }}
            >
              {modalButton}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceLogModal;
