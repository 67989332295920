import React, { useEffect } from 'react';
import { Route } from 'react-router';
import { connect } from 'react-redux';
import routes from './routes';
import Layout from '../layout/Layout';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import IdleModal from '../idle-modal/IdleModal';
import PageContent from '../page-content/PageContent';
import {selectAppIframeSource, selectIsAccessVerified, selectUser, selectUserOrganizationId} from '../../state';
import {DefaultOrgSelector} from "../header-menu/OrganizationMenu";
import Setup from '../setup/Setup';
import MainPageLoadingOverlay from './MainPageLoadingOverlay';
import packageJson from '../../../../package.json';

global.appVersion = packageJson.version;

const newVersionAvailable = (newestVersion, currentVersion) => {
  const newestVersions = newestVersion.split(/\./g);
  const currentVersions = currentVersion.split(/\./g);

  while (newestVersions.length || currentVersions.length) {
    const a = Number(newestVersions.shift());
    const b = Number(currentVersions.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

const refreshCacheAndReload = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then((names) => {
      for (const name of names) caches.delete(name);
    });
  }
  // delete browser cache and hard reload
  window.location.reload(true);
};

const MainPage = (props) => {

  useEffect(() => {
    async function foo() {
      window.fetch('/meta.json')
        .then(response => response?.json())
        .then((meta) => {
          const latestVersion = meta?.version;
          const currentVersion = global?.appVersion;
          let shouldForceRefresh = false;

          if (currentVersion) {
            shouldForceRefresh = newVersionAvailable(latestVersion, currentVersion);
          }
          if (shouldForceRefresh) {
            refreshCacheAndReload();
          }
        });
    }
    foo();
  }, []);

  return props.isAccessVerified && props.user && props.organizationId ? (
    <Layout>
      <MainPageLoadingOverlay>
        {props.appIframeSource !== 'next' ? <Header /> : <DefaultOrgSelector />}
        <Footer />
        {routes.map(route => (
          <Route
            exact={route.exact}
            key={route.path}
            path={route.path}
            render={route.render ? route.render : null}
            component={!route.component ? null :
            ({ match: { params } }) => (
              <PageContent title={route.title}>
                { typeof route.component === 'function' ? route.component(params) : route.component}
              </PageContent>
            )}
          />
        ))}
        <IdleModal />
      </MainPageLoadingOverlay>
    </Layout>
  ) : (
    <Setup />
  );
};


const mapStateToProps = state => ({
  user: selectUser(state),
  organizationId: selectUserOrganizationId(state),
  isAccessVerified: selectIsAccessVerified(state),
  appIframeSource: selectAppIframeSource(state)
});

export default connect(mapStateToProps)(MainPage);
