import axios from 'axios';
import { call, put, select } from 'redux-saga/effects';
import {
  isClearingPrintQueue,
  isClearingPrintQueueAll,
  isClearPrintQueueError,
  clearingPrintQueueNames,
  printQueue,
  selectPrintQueue,
  selectClearingPrintQueueNames,
} from '../../state';
import Toast from '../../services/Toast';

/**
 * Clear Print Queue documents on server
 * @param {[]} arrayId array of ids to delete
 */
export default function* clearPrintQueue({ payload }) {
  yield put(isClearPrintQueueError(false));
  yield put(isClearingPrintQueue(true));
  if (payload?.length) {
    let names = yield select(state => selectClearingPrintQueueNames(state));
    names = [...payload, ...names];
    yield put(clearingPrintQueueNames(names));
  } else {
    yield put(isClearingPrintQueueAll(true));
  }
  try {
    // Clear documents from server
    yield axios.delete('/print-queue', { data: { id: payload } });
    if (payload?.length) {
      // Clear documents from the print queue state
      let printQueueState = yield select(state => selectPrintQueue(state));
      printQueueState = printQueueState.filter(doc => !payload.includes(doc.name));
      yield put(printQueue(printQueueState));
    } else {
      // Set print queue to empty since all documents were cleared
      yield put(printQueue([]));
    }
  } catch (e) {
    if (payload?.length) {
      const names = payload.join(', ');
      Toast.error(`Unable to clear ${names} from print queue.`);
    } else {
      Toast.error('Unable to clear print queue.');
    }
    yield put(isClearPrintQueueError(true));
  }
  // Restore original state when print queue is cleared
  yield put(isClearingPrintQueue(false));
  if (payload?.length) {
    let names = yield select(state => selectClearingPrintQueueNames(state));
    names = names.filter(name => !payload.includes(name));
    yield put(clearingPrintQueueNames(names));
  } else {
    yield put(isClearingPrintQueueAll(false));
  }
}
