import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'reactstrap';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { postServiceRequest, setMRNRequired } from '../../../state';
import ServiceLogModal from '../service-logs/service-log-modal/ServiceLogModal';
import Session from '../../../services/Session';
import CoreApp from '../../../services/CoreApp';
import { goToOpenEConsults } from '../../../services/NextApp';

// eslint-disable-next-line react/prop-types
const SaveDraftButton = ({ disabled, buttonId }) => {
  const [targetId, setTargetId] = useState(null);
  const dispatch = useDispatch();
  const { isPatientSearched } = useSelector((state) => state.patient, shallowEqual);
  const { user } = useSelector((state) => state.user, shallowEqual);
  const { appIframeSource } = useSelector((state) => state.app, shallowEqual);
  const { coverage } = useSelector((state) => state.coverage);
  const [showServiceLogsModal, setServiceLogsModal] = useState(false);
  const {
    isRequestOnProcess,
    serviceRequestData,
  } = useSelector((state) => state.serviceRequest, shallowEqual);
  const {
    getValues,
    clearErrors,
    trigger,
  } = useFormContext();

  useEffect(() => {
    if (user?.id != null) {
      setServiceLogsModal(Session.verifyPermissions(user, 'service_log_access'));
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line max-len
    // targetId === buttonId this hack is made to fix the duplicated toaster message in PRF-503 (preferral app)
    // because we are using the same save as draft button twice in the same page (current form)
    // the component is mounted twice same as the use effect
    if (serviceRequestData?.status === 'draft' && !isRequestOnProcess.showSpinner && (targetId === buttonId)) {
      if (appIframeSource === 'app') {
        window.location.href = CoreApp.defaultUrl();
      } else {
        goToOpenEConsults('econsult_draft',
          serviceRequestData.identifier.find((identifier) => identifier.system === 'aristamd_econsult_id').value);
      }
    }
  }, [isRequestOnProcess, serviceRequestData, targetId]);

  const onSaveAsDraftDispatchEvent = (LogData) => {
    $('#serviceLogModal').modal('hide');
    dispatch(postServiceRequest({
      ...getValues(),
      status: 'draft',
      action: 'save_by_requester',
      service_message: LogData,
      coverage,
    }));
  };

  const onSaveAsDraft = async (e) => {
    setTargetId(e.target.id);
    clearErrors();
    clearErrors('otherText');
    const resultSite = await trigger('siteSelector');
    const resultProvider = await trigger('providerSelector');
    // if there is no mrn
    if (!getValues().patientSearch || !isPatientSearched) {
      dispatch(setMRNRequired(true));
      return;
    }

    // trigger validations for dob and lastName
    const resultLastName = await trigger('lastName');
    const resultDob = await trigger('dob');

    if (resultLastName && resultDob && resultSite && resultProvider) {
      if (showServiceLogsModal) {
        $('#serviceLogModal').modal('show');
      } else {
        onSaveAsDraftDispatchEvent(null);
      }
    }
  };

  const onSaveRequest = (LogData) => {
    onSaveAsDraftDispatchEvent(LogData);
  };

  return (
    <>
      <ServiceLogModal modalId="serviceLogModal" modalTitle="Save as Draft" modalButton="Save as Draft" confirmButton={onSaveRequest} />
      <Button
        disabled={disabled}
        id={buttonId}
        type="button"
        color="light"
        className="mr-3"
        data-auto="request-form-save-as-draft"
        onClick={(e) => { onSaveAsDraft(e)}}
      >
        Save as Draft
      </Button>
    </>
  );
};

export default SaveDraftButton;
