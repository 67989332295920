import React, { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { kebabCase } from 'lodash';
import axios from 'axios';
import PESAxios from './PESAxios';
import b64ToBlob from '../helpers/b64ToBlob';
import { assetPreviewUrl, assetThumbnailUrl } from '../utils';
import { trackEvent } from './AnalyticsService';

// Default values for the context
export const defaultValues = {
  ehrDocuments: [],
  documentCache: {},
  isUploadInProgress: false,
  setEhrDocuments: () => {},
  uploadEhrDocuments: () => {},
};

const EhrDocumentContext = React.createContext(defaultValues);

/**
 * Creat EHR document as asset in the DB.
 * @param doc
 * @param data
 * @returns {Promise<AssetFile>}
 */
export const uploadEhrAsset = (doc, data) => {
  // Create blob from base64
  const blob = b64ToBlob(data);
  const name = `${kebabCase(doc.source === 'EHR' ? doc.title : doc.lab_name)}-${kebabCase(doc.date)}.pdf`;
  blob.name = name;

  // Create POST form body.
  const formData = new FormData();
  formData.append('security_profile', 'sensitive');
  formData.append('content', blob, name);
  formData.append('source', doc.source);
  // FORM-496: Send fhir id from ehr resource
  formData.append('ehrId', doc.id);
  formData.append('ehrIdName', 'FHIR');

  // POST new asset.
  return axios.post('/assets', formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  }).then((response) => ({
    id: response.data,
    ehrId: doc.id,
    name: blob.name,
    size: blob.size,
    type: blob.type,
    source: doc.source,
    lastModified: new Date().getUTCMilliseconds(),
    thumbnail: `${assetThumbnailUrl(response.data)}?width=200`,
    urlPreview: assetPreviewUrl(response.data),
  }));
};

export const updateDocumentsList = (docs, source, prevState) => {
  const oppositeSources = prevState.filter((doc) => doc.source !== source);
  const currentSource = prevState
    .filter((doc) => doc.source === source && docs.some((d) => d.id === doc.id));
  const newDocuments = docs.filter((doc) => !currentSource.some((d) => d.id === doc.id));
  return [...oppositeSources, ...currentSource, ...newDocuments];
};

// eslint-disable-next-line react/prop-types
const EhrDocumentsProvider = ({ children }) => {
  const [ehrDocuments, setEhrDocs] = useState([]);
  const [isUploadInProgress, setLoading] = useState(false);
  const documentCache = useRef({});
  const { setValue, getValues } = useFormContext();

  /**
   *
   * @param binary
   * @param doc
   * @returns {Promise<void>}
   */
  const addEhrDocuments = async (binary, doc) => {
    // Create new asset.
    const file = await uploadEhrAsset(doc, binary);
    trackEvent('Attach File', {
      from: 'request form',
      file_source: doc.source,
    });

    // Add asset to the UI
    const currentAttachments = getValues().documents || [];
    currentAttachments.push(file);
    setValue('documents', currentAttachments);
  };

  const uploadEhrDocuments = async (siteId) => {
    const currentDocuments = getValues().documents || [];

    // Remove un-selected documents from the current docs.
    if (currentDocuments.length > 0) {
      const currentNonEhrDocuments = currentDocuments.filter((doc) => !['EHR', 'EHRLab'].some((source) => source === doc.source));
      const currentEhrDocuments = currentDocuments
        .filter((doc) => ['EHR', 'EHRLab'].some((source) => source === doc.source))
        .filter((doc) => ehrDocuments.some((ehrDoc) => ehrDoc.id === doc.ehrId));
      const currentEhrDocumentsFromPatients = currentDocuments
        .filter((doc) => ['EHR', 'EHRLab'].some((source) => source === doc.source && doc.patientDocument));

      const currentEhrDocumentsFromServiceRequest = currentDocuments
        .filter((doc) => ['EHR', 'EHRLab'].some((source) => source === doc.source && doc.serviceRequestDocument))
        .filter((doc) => !ehrDocuments.some((ehrDoc) => ehrDoc.id === doc.ehrId));

      setValue('documents', [
        ...currentNonEhrDocuments,
        ...currentEhrDocuments,
        ...currentEhrDocumentsFromPatients,
        ...currentEhrDocumentsFromServiceRequest,
      ]);
    }

    // Don't upload documents that are already selected
    const selectDocs = ehrDocuments
      .filter((doc) => !currentDocuments.some((current) => current.ehrId === doc.id));

    setLoading(true);
    await Promise.all(selectDocs.map((doc) => {
      const docCache = documentCache.current[doc.binary];
      if (!docCache) {
        return PESAxios().get(
          `/fhir/r4/${doc.binary}`,
          { headers: { site: siteId } },
        ).then(async ({ data }) => {
          let documentData;
          if (doc.source === 'EHR') {
            documentData = doc.ehrType === 'epic' ? data.content[0].attachment.data : data.data;
          } else {
            documentData = data.presentedForm[0].data
          }
          addEhrDocuments(documentData, doc)
        });
      }
      return addEhrDocuments(docCache, doc);
    }));
    setLoading(false);
  };

  const setEhrDocuments = (docs, source) => setEhrDocs((prevState) => updateDocumentsList(docs, source, prevState));

  const value = {
    uploadEhrDocuments,
    ehrDocuments,
    setEhrDocuments,
    documentCache,
    isUploadInProgress,
  };

  return (
    <EhrDocumentContext.Provider value={value}>
      {children}
    </EhrDocumentContext.Provider>
  );
};

export default EhrDocumentsProvider;

export { EhrDocumentContext };
