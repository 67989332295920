import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useFormContext } from 'react-hook-form';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { Spinner } from 'reactstrap';
import { formatDateTimezone } from '../../../utils';
import { clearPrintQueue } from '../../../state';
import { trackEvent } from '../../../services/AnalyticsService';

let gridApi = null;

const DocumentsQueue = ({ documents, onViewDocument }) => {
  const {
    setValue,
    getValues,
  } = useFormContext();
  const [rowData, setRowData] = useState([]);
  const { user } = useSelector(state => state.user, shallowEqual);
  const {
    clearingPrintQueueNames,
    isClearingPrintQueueAll,
  } = useSelector(state => state.printQueue, shallowEqual);
  const dispatch = useDispatch();


  useEffect(() => {
    setRowData(documents);
  }, [documents]);

  /**
   * Run effect on render and if clearingPrintQueueNames, isClearingPrintQueueAll change
   * Update the clearing and disabled flags for each document in the print queue
   */
  useEffect(() => {
    if (gridApi && !gridApi.api.destroyCalled) {
      gridApi.api.forEachNode((rowNode) => {
        const isClearing = clearingPrintQueueNames.includes(rowNode.data.name);
        const isDisabled = isClearing || isClearingPrintQueueAll;
        rowNode.setDataValue('isClearing', isClearing);
        rowNode.setDataValue('isDisabled', isDisabled);
      });
    }
  }, [clearingPrintQueueNames, isClearingPrintQueueAll]);

  const onGridReady = (params) => {
    gridApi = params;
  };

  const setIsAdded = (data) => {
    const rowNode = gridApi.api.getRowNode(data.id);
    rowNode.setDataValue('isAdded', !data.isAdded);
    if (data.isAdded) {
      trackEvent('Attach File', {
        from: 'request form',
        file_source: data.source,
      });
    }
  };

  /**
   * When user clicks Clear Print Queue button, clear the print queue
   */
  const clearPrintQueueOnClick = (data) => {
    dispatch(clearPrintQueue([data.name]));
  };

  const dateFormat = ({ data }) => {
    const date = formatDateTimezone(data.lastModified || data.timestamp, user?.timezone);
    const isAdded = !!getValues().documents?.find(attachments => attachments.id === data.id);
    const isDisabled = (isAdded || data.isDisabled);
    return (
      <div>
        <p>{date}</p>
        { data.source === 'aristamd_print_queue' &&
          <button
            type="button"
            className="btn btn-light float-right"
            onClick={(e) => { clearPrintQueueOnClick(data); }}
            disabled={isDisabled}
          >
            Clear { data.isClearing ? <Spinner size="sm" /> : '' }
          </button>
        }
      </div>
    );
  };

  const addDocuments = (data, isChecked) => {
    setIsAdded(data);
    if (!isChecked && getValues().documents) { // if the file is unchecked from the modal, remove it from the list
      setValue('documents', getValues().documents.filter(attachments => attachments.id !== data.id));
      return;
    }
    const newAttachment = [data];
    const currentAttachments = getValues().documents || [];
    Array.prototype.push.apply(currentAttachments, newAttachment);
    setValue('documents', currentAttachments);
  };

  const detailsFormat = ({ data }) => {
    let isChecked;
    const currentAttachments = getValues().documents || [];
    if (currentAttachments.length > 0) {
      const assetId = currentAttachments.find(file => file.id === data.id);
      isChecked = !!assetId;
    }
    return (
      <div key={data.id} className="row mb-3">
        <div className="col-12">
          <input
            type="checkbox"
            id={data.id}
            className="checkboxColumn"
            name={data.name}
            onChange={(e) => {
              isChecked = e.target.checked;
              addDocuments(data, e.target.checked);
            }}
            value={data.name}
            defaultChecked={isChecked}
            disabled={data.isDisabled}
          />
          <div className="col-5 col-md-4 col-lg-3 d-inline-block img-queue">
            <img src={data.thumbnail} className="card-img-queue" alt="..." />
          </div>
          <div className="col-9 d-inline-block ml-3">
            <div>
              <p className="mb-0 font-weight-bold">{data.name}</p>
              { data.patientDocument &&
                <>
                  <p className="mb-0">Source: {data.source}</p>
                  <p className="mb-0">Uploaded by: {data.uploadedBy}</p>
                </>
              }
              <span className="btn btn-link p-0 m-0" onClick={() => { onViewDocument(data); }}>View</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const columnDefs = [
    {
      headerName: 'File Details',
      cellRendererFramework: detailsFormat,
      suppressMovable: true,
      suppressSizeToFit: true,
      flex: 2,
      resizable: true,
    },
    {
      headerName: 'Date Uploaded',
      cellRendererFramework: dateFormat,
      suppressMovable: true,
      suppressSizeToFit: true,
      flex: 2,
      resizable: true,
    },
    {
      headerName: '-',
      field: 'isAdded',
      flex: 0,
      resizable: false,
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: '-',
      field: 'isClearing',
      flex: 0,
      resizable: false,
      hide: true,
      suppressColumnsToolPanel: true,
    },
    {
      headerName: '-',
      field: 'isDisabled',
      flex: 0,
      resizable: false,
      hide: true,
      suppressColumnsToolPanel: true,
    },
  ];
  const gridOptions = {
    columnDefs,
    headerHeight: 30,
    rowHeight: 130,
    singleClickEdit: true,
    floatingFilter: false,
    suppressCellSelection: true,
  };
  return (
    <div className="ag-theme-alpine">
      <AgGridReact
        gridOptions={gridOptions}
        rowData={rowData}
        onGridReady={onGridReady}
        getRowNodeId={function (data) {
          return data.id;
        }}
      />
    </div>
  );
};

const {
  any,
  arrayOf,
} = PropTypes;

DocumentsQueue.propTypes = {
  documents: arrayOf(any),
  data: arrayOf(any),
};

DocumentsQueue.defaultProps = {
  documents: [],
  data: [],
};

export default DocumentsQueue;
