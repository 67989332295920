import createReducer from '../createReducer';

/** ********************************************************
 * CONSTANTS
 ******************************************************** */

export const POST_SERVICE_REQUEST = 'serviceRequest/POST_SERVICE_REQUEST';
export const FETCH_SERVICE_REQUEST = 'serviceRequest/FETCH_SERVICE_REQUEST';
export const POSTING_REQUEST_ON_PROCESS = 'serviceRequest/POSTING_REQUEST_ON_PROCESS';
export const FETCHING_SERVICE_REQUEST = 'serviceRequest/FETCHING_SERVICE_REQUEST';
export const SERVICE_REQUEST = 'serviceRequest/SERVICE_REQUEST';
export const SERVICE_REQUEST_DRAFT = 'serviceRequest/SERVICE_REQUEST_DRAFT';
export const POST_SERVICE_REQUEST_ERROR = 'serviceRequest/POST_SERVICE_REQUEST_ERROR';

/** ********************************************************
 * REDUCER
 ******************************************************** */

export const initialStateServiceRequest = {
  serviceRequestData: null,
  serviceRequestDraftData: null,
  isRequestOnProcess: { showSpinner: false, status: null },
  isFetchingRequest: false,
  postServiceRequestError: false,
};

export default createReducer(initialStateServiceRequest, {
  [SERVICE_REQUEST]: (state, { payload }) => ({
    ...state, serviceRequestData: payload,
  }),
  [POSTING_REQUEST_ON_PROCESS]: (state, { payload }) => ({
    ...state, isRequestOnProcess: payload,
  }),
  [FETCHING_SERVICE_REQUEST]: (state, { payload }) => ({
    ...state, isFetchingRequest: payload,
  }),
  [SERVICE_REQUEST_DRAFT]: (state, { payload }) => ({
    ...state, serviceRequestDraftData: payload,
  }),
  [POST_SERVICE_REQUEST_ERROR]: (state, { payload }) => ({
    ...state, postServiceRequestError: payload,
  }),
});

/** ********************************************************
 * ACTIONS
 ******************************************************** */

/**
 * Post a service request
 * @param {object} request
 * @returns {{type: FHIR object, payload: *}}
 */
export const postServiceRequest = request => ({
  type: POST_SERVICE_REQUEST,
  payload: request,
});


/**
 * Post a service request
 * @param {object} request
 * @returns {{type: FHIR object, payload: *}}
 */
export const fetchServiceRequest = id => ({
  type: FETCH_SERVICE_REQUEST,
  payload: id,
});

/**
 * Post a service request
 * @param {object} request
 * @returns {{type: FHIR object, payload: *}}
 */
export const isFetchingServiceRequest = bool => ({
  type: FETCHING_SERVICE_REQUEST,
  payload: bool,
});


/**
 * Used by the UI to determine if request is being submitted
 * @param {boolean} bool
 * @returns {{type: string, payload: boolean}}
 */
export const isRequestOnProcess = payload => ({
  type: POSTING_REQUEST_ON_PROCESS,
  payload,
});

/**
 * Set the service request
 * @param {[]} payload
 * @returns {{type: string, payload: *}}
 */
export const serviceRequestData = payload => ({
  type: SERVICE_REQUEST,
  payload,
});

/**
 * Set the service request
 * @param {[]} payload
 * @returns {{type: string, payload: *}}
 */
export const serviceRequestDraftData = payload => ({
  type: SERVICE_REQUEST_DRAFT,
  payload,
});

/**
 * @param state
 * @returns {null|int}
 */
export const selectServiceRequestData = state => state.serviceRequest.serviceRequestData;

/**
 * Use to display an error message in the UI
 * @param bool
 * @returns {{payload, type: bool}}
 */
export const postServiceRequestError = (bool) => ({
  type: POST_SERVICE_REQUEST_ERROR,
  payload: bool,
});
