import axios from 'axios';
import { put } from 'redux-saga/effects';

import {
  user,
  isFetchingUser,
} from '../state';


/**
 * Get User
 * Return Users Data
 */
export default function* fetchUser(action) {
  yield put(isFetchingUser(true));
  try {
    const response = yield axios.get(`/users/${action.payload}`);
    yield put(user(response.data));
    window.Beacon('identify', {
      name: response.data.full_name,
      email: response.data.email,
    });
  } catch (e) {
    yield put(user(null));
  }
  // Restore original state when user is fetched
  yield put(isFetchingUser(false));
}
