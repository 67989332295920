import createReducer from '../createReducer';

/** ********************************************************
 * CONSTANTS
 ******************************************************** */

export const ADD_FIELD_REF = 'form/ADD_FIELD_REF';
export const SET_FIELD_REF = 'form/SET_FIELD_REF';


/** ********************************************************
 * REDUCER
 ******************************************************** */
export const initialStateFieldsRefs = {
  fieldsRefs: [],
};

export default createReducer(initialStateFieldsRefs, {
  [ADD_FIELD_REF]: (state, { payload }) => ({
    ...state, fieldsRefs: payload,
  }),
  [SET_FIELD_REF]: (state, { payload }) => ({
    ...state, fieldsRefs: payload,
  }),
});

/** ********************************************************
 * ACTIONS
 ******************************************************** */

/**
 * Set the fields references
 * @param {[]} content
 * @returns {{payload: {fieldsRefs: *[]}, type: string}}
 */
export const setFieldsRefs = content => ({
  type: SET_FIELD_REF,
  payload: content,
});

/**
 * Set the fields references
 * @param {[]} content
 * @returns {{payload: {fieldsRefs: *[]}, type: string}}
 */
export const addFieldsRefs = content => ({
  type: ADD_FIELD_REF,
  payload: content,
});
