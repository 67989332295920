import React from 'react';
import moment from 'moment-timezone';
import { timeZoneAbbr, timeZoneOffset } from '../../utils/index';

const Footer = () => {
	return ( 
		<div className="app-footer wrapper b-t bg-light row">
			<div className="col-6"><p className="m-0">&copy; Copyright {moment().format('YYYY')} AristaMD, Inc. All rights reserved.</p></div>
			<div className="col-6"><p className="text-right m-0">Time Zone: { timeZoneAbbr() } (GMT: { timeZoneOffset() })</p></div>
		</div>
	);
	}

export default Footer;
