import React from 'react';
import PropTypes from 'prop-types';

const Layout = props => (
  <div className="app-layout">
    {props.children}
  </div>
);

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
};

export default Layout;
