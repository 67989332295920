import axios from 'axios';
import moment from 'moment';
import { put, select } from 'redux-saga/effects';
import EnvironmentSettings from '../../../services/EnvironmentSettings';
import {
  createEpicPatientError,
  isCreatingEpicPatient,
  patient as patientState,
  patientFHIR,
  selectPatientFHIRData,
  selectServiceRequestData,
  selectUser,
  serviceRequestData,
} from '../../../state';
import { getGenderAbbreviation } from '../../../helpers/gender';

function getEhrIdentifiers(patient) {
  return patient.identifiers.map((i) => (
    {
      use: 'usual',
      system: i.identifier_name,
      value: i.identifier,
      extension: [{
        valueString: i.auth_provider_id,
        url: 'http://extensions.aristamd.com/organization/auth_provider_id',
      }],
    }
  ));
}

export default function* postEpicPatient({ payload }) {
  yield put(isCreatingEpicPatient(true));
  yield put(createEpicPatientError(false));
  const patFHIR = yield select(selectPatientFHIRData);
  const serviceRequest = yield select(selectServiceRequestData);
  const user = yield select(selectUser);

  try {
    let body = {
      organization_id: payload.organizationId,
      first_name: payload.patient.first_name,
      last_name: payload.patient.last_name,
      gender: getGenderAbbreviation(payload.patient.gender).toLowerCase(),
      date_of_birth: moment(payload.patient.dob).format('YYYY-MM-DD'),
    };

    if (user?.organization?.settings?.patient?.address_enabled) {
      body = {
        ...body,
        street_1: payload.patient.address.street_1,
        street_2: payload.patient.address.street_2,
        city: payload.patient.address.city,
        state: payload.patient.address.state,
        zip: payload.patient.address.zip,
      };
    }

    const responseEpic = yield axios.post('/ehr/epic/patients', body);

    patFHIR.identifier = [...patFHIR.identifier, ...getEhrIdentifiers(responseEpic.data)];

    patFHIR.id = payload.patient.fhirID;
    yield axios.put(`${EnvironmentSettings.fhirApiUrl}/Patient/${patFHIR.id}`, patFHIR);

    const econsult = { ...serviceRequest };
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < econsult.extension.length; i++) {
      if (econsult.extension[i].url === 'http://extensions.aristamd.com/servicerequest/submitted_at') {
        econsult.extension.splice(i, 1);
      }
    }
    econsult.status = 'active';
    delete econsult.authoredOn;
    const response = yield axios.put(`${EnvironmentSettings.fhirApiUrl}/ServiceRequest/${serviceRequest.id}`, econsult);
    yield put(serviceRequestData(response.data));

    yield put(patientState(null));
    yield put(patientFHIR(null));
  } catch (e) {
    console.log(e);
    yield put(createEpicPatientError(true));
  }

  yield put(isCreatingEpicPatient(false));
}
