import { useEffect } from 'react';
import CoreApp from '../../services/CoreApp';

/**
 * Redirect to core app
 * @returns {null}
 * @constructor
 */
export default function EConsults() {
  useEffect(() => {
    window.location.href = CoreApp.defaultUrl();
  });
  return null;
}

