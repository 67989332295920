import React from 'react';
import { Route, Switch, withRouter } from 'react-router';
import Session from './services/Session';

import MainPage from './components/main-page/MainPage';

const Router = () => (
  <Switch>
    <Route exact path="/logout" render={() => Session.logout()} />
    <Route path="/" component={MainPage} />
  </Switch>
);

// with router provides browser history to the component
export default withRouter(Router);
