import axios from 'axios';
import { put } from 'redux-saga/effects';
import {
  isAssetPreviewAvailable,
  isFetchingAssetPreviewStatus,
} from '../../state';
import EnvironmentSettings from '../../services/EnvironmentSettings';

// Video assets need to be converted by server to a browser compatible preview version, which takes
// some time. We can check the status of the preview to see if it's available yet.
export default function* fetchAssetPreviewStatus(action) {
  const assetId = action.payload;
  try {
    // Set to undefined since the preview status is unknown while fetching
    yield put(isAssetPreviewAvailable(assetId, undefined));
    yield put(isFetchingAssetPreviewStatus(assetId, true));
    const response = yield axios.get(`${EnvironmentSettings.assetsServer}/${assetId}/getPreviewStatus`);
    // Set to true/false based on response
    yield put(isAssetPreviewAvailable(assetId, response.status === 200));
  } catch (e) {
    console.log(e);
    yield put(isAssetPreviewAvailable(assetId, false));
  }
  // Restore original state when asset is fetched
  yield put(isFetchingAssetPreviewStatus(assetId, false));
}
