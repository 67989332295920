import createReducer from '../createReducer';

/** ********************************************************
 * CONSTANTS
 ******************************************************** */

export const FETCH_WORKUP_CHECKLISTS = 'workupChecklist/FETCH_WORKUP_CHECKLISTS';
export const FETCHING_WORKUP_CHECKLISTS = 'workupChecklist/FETCHING_WORKUP_CHECKLISTS';
export const WORKUP_CHECKLISTS = 'workupChecklist/WORKUP_CHECKLISTS';

/** ********************************************************
 * REDUCER
 ******************************************************** */

export const initialStateWorkupChecklist = {
  workupChecklists: null,
  isFetchingWorkupChecklists: false,
};

export default createReducer(initialStateWorkupChecklist, {
  [WORKUP_CHECKLISTS]: (state, { payload }) => ({
    ...state, workupChecklists: payload,
  }),
  [FETCHING_WORKUP_CHECKLISTS]: (state, { payload }) => ({
    ...state, isFetchingWorkupChecklists: payload,
  }),
});


/** ********************************************************
 * ACTIONS
 ******************************************************** */

/**
 * Fetches the WC's
 * @param {specialtyID, chiefComplaintID} WC's
 * @returns {{type: string, payload: *}}
 */
export const fetchWorkupChecklists = (specialtyID, chiefComplaintID) => ({
  type: FETCH_WORKUP_CHECKLISTS,
  payload: { 
    specialtyID,
    chiefComplaintID
  },
});

/**
 * Used by the UI to determine if the form is ready to be shown
 * @param {boolean} bool
 * @returns {{type: string, payload: boolean}}
 */
export const isFetchingWorkupChecklists = bool => ({
  type: FETCHING_WORKUP_CHECKLISTS,
  payload: bool,
});

/**
 * Sets the WC's
 * @param {[]} payload
 * @returns {{type: string, payload: *}}
 */
export const workupChecklistsData = payload => ({
  type: WORKUP_CHECKLISTS,
  payload,
});