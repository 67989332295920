import createReducer from '../createReducer';

/** ********************************************************
 * CONSTANTS
 ******************************************************** */

export const FETCH_STATES = 'states/FETCH_STATES';
export const FETCHING_STATES = 'states/FETCHING_STATES';
export const STATES = 'states/STATES';

/** ********************************************************
 * REDUCER
 ******************************************************** */

export const initialStateStates = {
  states: [],
  isFetchingStates: false,
};

export default createReducer(initialStateStates, {
  [STATES]: (state, { payload }) => ({
    ...state, states: payload,
  }),
  [FETCHING_STATES]: (state, { payload }) => ({
    ...state, isFetchingStates: payload,
  }),
});

/** ********************************************************
 * ACTIONS
 ******************************************************** */

/**
 * Fetch US states
 * @returns {{type: string}}
 */
export const fetchStates = () => ({
  type: FETCH_STATES,
});

/**
 * Used by the UI to determine if the form is ready to be shown
 * @param bool
 * @returns {{payload, type: string}}
 */
export const isFetchingStates = (bool) => ({
  type: FETCHING_STATES,
  payload: bool,
});

/**
 * Fetch US states
 * @param payload
 * @returns {{payload, type: string}}
 */
export const states = (payload) => ({
  type: STATES,
  payload,
});
