import axios from 'axios';
import { logError } from '../helpers/log';
import Session from './Session';

/**
 * Axios basic Configuration
 */
export const configure = ({
  baseURL,
}) => {
  axios.defaults.baseURL = baseURL;
  axios.defaults.headers['Content-Type'] = 'application/json';
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
};

export const updateAccessToken = (token) => {
  axios.defaults.headers.Authorization = `Bearer ${token}`;
};

export const updateOrganization = (organizationId) => {
  axios.defaults.headers.organization = organizationId;
};

axios.interceptors.response.use((config) => config, (error) => {
  const e = { ...error };
  const status = e?.response?.status || null;
  const code = e?.response?.data?.code || null;

  switch (status) {
    case 401:
      logError('401 Unauthorized', [e], true)
        .finally(() => Session.unauthorized());
      return Promise.resolve();
    case 417:
        if (code === 'needs_2fa') {
          Session.redirectTwoFactorAuth();
          return Promise.reject(error);
        }
    default:
      return Promise.reject(error);
  }
});

export default axios;
