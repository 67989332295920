import axios from 'axios';
import { put } from 'redux-saga/effects';
import { parseBasicWc } from 'fhir-parser';
import EnvironmentSettings from '../../../services/EnvironmentSettings';
import { sortBy } from 'lodash';

import {
  workupChecklistsData,
  isFetchingWorkupChecklists,
} from '../../../state';

export default function* fetchWorkupChecklists({ payload }) {
  yield put(isFetchingWorkupChecklists(true));
  yield put(workupChecklistsData(null));

  try {
    const response = yield axios.get(`${EnvironmentSettings.fhirApiUrl}/Basic?id=workup-checklist&specialty-id=${payload.specialtyID}&chief-complaint-id=${payload.chiefComplaintID}`);
    let basicWcData = parseBasicWc(response.data);
    if (basicWcData.error) {
      console.log(specialtiesData.error);
      yield put(workupChecklistsData(null));
    } else {
      yield put(workupChecklistsData(basicWcData.parsedData));
    };
  }
  catch (e) {
    yield put(workupChecklistsData({
      error: '',
      parsedData: [],
    }));
  }
  // // Restore original state when user is fetched
  yield put(isFetchingWorkupChecklists(false));
}
