import 'whatwg-fetch';
import 'promise-polyfill/src/polyfill';
import 'babel-polyfill';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import store, { history } from '../../store';
import { configure } from '../../services/axios';
import Router from '../../Router';
import '../../../assets/images/aristamd_logo.png';
import '../../../assets/images/aristamd_logo_white.svg';
import Session from '../../services/Session';
import '../../../css/app.scss';
import {setAppIframeSource} from "../../state";

// Setup axios configuration
configure({
  baseURL: `${window.location.protocol}//${window.location.host}/api`,
});

const App = () => {
  // useEffect() is the equivalent of componentDidMount() for functional components
  useEffect(() => {
    Session.init();
    // Check if iframe was invoked by preferral-next
    if (document.referrer.includes(window.location.origin.replace('request', 'next'))) {
      store.dispatch(setAppIframeSource('next'));
    } else {
      window.Beacon('init', '5de7bcca-55ef-4679-a3b7-a571747f39ba');
    }
  });

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Router />
      </ConnectedRouter>
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById('app'));
