import axios from 'axios';
import moment from 'moment';
import Cookies from 'js-cookie';
import { fetchUser, selectUser, verifyAccess, setShowIdleModal, selectShowIdleModal } from '../state';
import store from '../store';
import EnvironmentSettings from './EnvironmentSettings';
import {updateAccessToken, updateOrganization} from './axios';
import {isMobile} from '../helpers/mobile';
import { logout } from "./AnalyticsService";

const clientId = EnvironmentSettings.clientId;
const clientSecret = EnvironmentSettings.clientSecret;

const authCookieName = `aristamd_auth${EnvironmentSettings.stage ? '_dev' : ''}`;
const authLockCookieName = `aristamd_lock${EnvironmentSettings.stage ? '_dev' : ''}`;
const authCookiePath = '/';
const authCookieDomain = EnvironmentSettings.domainServer;
const authUrl = `https://login${EnvironmentSettings.domainServer}`;

const cookieExpiresInSeconds = isMobile() ? 0 : 15;

const idleCookieName = `idle${EnvironmentSettings.stage ? '_dev' : ''}`;
const idleTimeoutInMinutes = 45; // timeout after 45 minutes inactivity
const idleWarningInMinutes = 5; // warn 5 minutes before timeout

let idleTimer = null;
let validateTimer = null;

let sessionStorageLock = false;

// Session class initializes user session and access token from shared auth cookie, periodically
// validates the cookie, and refreshes the token. If no cookie is present, the user is redirected
// to auth service endpoint to login.
export default class Session {
  static userId = null;

  /**
   * Get the auth cookie
   * @returns {*}
   */
  static getCookie() {
    try {
      let cookie = Cookies.get(authCookieName);
      // A value of '0' indicates user logged out
      if (cookie === '0') {
        return null;
      }
      if(cookie) {
        cookie = JSON.parse(cookie);
      }
      if (window.aristamd_auth) {
        if (!cookie) {
          // in case of slow connection, cookie may expire while app is loading
          // here we restore cookie from window variable
          cookie = window.aristamd_auth;
          this.setCookie(cookie);
        }
        // we must delete the window variable now to prevent unintended cookie recovery later
        // in the case of logout, for example
        delete window.aristamd_auth;
      }
      if(typeof cookie === 'undefined') {
        // Cookie may have expired if validate timer didn't run or was delayed
        // This can happen in IE while browsing for a file and Safari for minimized or inactive tab
        // Attempt to rebuild the cookie from storage
        cookie = this.rebuildCookieFromSessionStorage();
      }
      return cookie;
      // eslint-disable-next-line no-empty
    } catch (e) {}
    return null;
  }

  /**
   * Update cookie after refreshing tokens
   * @param data
   */
  static updateCookie(data) {
    const cookie = this.getCookie();
    cookie.access_token = data.access_token;
    cookie.refresh_token = data.refresh_token;
    cookie.expires_in = data.expires_in;
    cookie.received_at = moment();
    this.setCookie(cookie);
  }

  /**
   * Store auth cookie
   * @param cookie
   */
  static setCookie(cookie) {
    Cookies.set(
      authCookieName,
      JSON.stringify(cookie),
      {
        expires: this.cookieExpires(),
        path: authCookiePath,
        domain: authCookieDomain,
        secure: true,
        sameSite: 'None',
      },
    );
    return cookie;
  };

  /**
   * Rebuild cookie from session storage oauth data
   */
  static rebuildCookieFromSessionStorage = () => {
    const oauth = JSON.parse(sessionStorage.getItem('oauth'));
    if(oauth) {
      const cookie = {};
      cookie.user_id = oauth.user_id;
      cookie.access_token = oauth.access_token;
      cookie.refresh_token = oauth.refresh_token;
      cookie.expires_in = oauth.expires_in;
      cookie.received_at = oauth.received_at;
      return cookie;
    }
    return null;
  };

  /**
   * Keep session storage in sync with cookie
   * Tokens in the cookie can change when refresh occurs from another app instance (tab)
   * @param cookie
   */
  static updateFromCookie = (cookie) => {
    if(sessionStorageLock) {
      return;
    }
    const oauth = JSON.parse(sessionStorage.getItem('oauth')) || {};
    oauth.access_token = cookie.access_token;
    oauth.refresh_token = cookie.refresh_token;
    oauth.expires_in = cookie.expires_in;
    oauth.user_id = cookie.user_id;
    oauth.received_at = cookie.received_at || moment();
    sessionStorage.setItem('oauth', JSON.stringify(oauth));
  };

  /**
   * Returns a new expiration date for the cookie
   * @returns {Date|null}
   */
  static cookieExpires = () => (
    cookieExpiresInSeconds ? new Date(new Date().getTime() + (cookieExpiresInSeconds * 1000)) : null
  );

  /**
   * Redirect to login page on auth service
   */
  static redirectLogin() {
    clearTimeout(idleTimer);
    clearTimeout(validateTimer);
    this.clearSession(true);
    const url = window.location.href;
    window.location.href = `${authUrl}/signin?redirect=${url}`;
  }

  /**
   * Redirect to 2fa on auth service
   */
  static redirectTwoFactorAuth() {
    clearTimeout(idleTimer);
    clearTimeout(validateTimer);
    const url = window.location.href;
    window.location.href = `${authUrl}/signin/2fa?redirect=${encodeURIComponent(url)}`;
  }

  /**
   * Redirect to logout page on auth service which handles logging out of all services
   */
  static logout() {
    this.setLock();
    logout();
    clearTimeout(idleTimer);
    clearTimeout(validateTimer);
    this.clearSession(true);
    window.location.href = `${authUrl}/logout`;
    return null; // avoids router render error
  }

  /**
   * Clear session data
   * @param lock Lock session storage to prevent changes
   */
  static clearSession(lock) {
    if (lock) {
      sessionStorageLock = true;
    }
    this.userId = null;
    sessionStorage.clear();
  }

  /**
   * Clear stale session from previous log in
   * This can happen when a user logs out while using multiple tabs
   */
  static clearStaleSession = () => {
    const cookieToken = this.getToken();
    const oauth = JSON.parse(sessionStorage.getItem('oauth')) || {};
    const sessionToken = oauth.access_token;
    if (sessionToken !== cookieToken) {
      this.clearSession(false);
    }
  };

  /**
   * Do some checks to validate the session, call itself again in 2 seconds
   * @returns {boolean}
   */
  static validate() {
    let cookie = this.getCookie();

    // If the cookie has been deleted, redirect to the login page
    if (!cookie) {
      this.redirectLogin();
      return false;
    }

    // If cookie user is different from the session user, reload the page
    if (cookie.user_id !== Session.userId) {
      location.reload();
      return false;
    }

    // Get the time left in the secs since the access token was emitted.
    const elapsedTime = moment().diff(cookie.received_at, 'seconds');

    // if for some reason the token already expired, don't try to refresh it and logout
    if (cookie.expires_in - elapsedTime <= 0 && !this.isLocked()) {
      this.logout();
      return false;
    }

    // If cookie expires in 2 mins or less, refresh the token
    if (cookie.expires_in - elapsedTime <= 120 && !this.isLocked())   {
      this.refreshToken();
      return false;
    }

    updateAccessToken(cookie.access_token);
    this.updateFromCookie(cookie);
    this.keepAlive();

    validateTimer = setTimeout(this.validate.bind(this), 2000);
    return true;
  }

  /**
   * Set a temporary lock cookie to prevent multiple app instances (separate tabs) from trying
   * to refresh token at the same time
   */
  static setLock() {
    const inFifteenSeconds = new Date(new Date().getTime() + (15 * 1000));
    Cookies.set(
      authLockCookieName,
      true,
      {
        expires: inFifteenSeconds,
        path: authCookiePath,
        domain: authCookieDomain,
      },
    );
  }

  /**
   * Remove the lock cookie
   */
  static removeLock() {
    Cookies.remove(authLockCookieName, { path: authCookiePath, domain: authCookieDomain });
  }

  /**
   * Returns true if lock cookie has been set
   * @returns boolean
   */
  static isLocked() {
    return !!Cookies.get(authLockCookieName);
  }

  /**
   * Refresh the access token
   */
  static refreshToken() {
    this.setLock();
    let cookie = this.getCookie();

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${cookie.access_token}`,
    };

    let request = {};
    request['client_id'] = clientId;
    request['client_secret'] = clientSecret;
    request['grant_type'] = "refresh_token";
    request['refresh_token'] = cookie.refresh_token;

    axios({
      method: 'POST',
      headers: headers,
      data: request,
      url: '/oauth',
    })
      .then((response) => {
        let { data } = response;
        updateAccessToken(data.access_token);
        this.updateCookie(data);
        this.updateFromCookie(data);
        this.removeLock();
        this.validate();
        const user = selectUser(store.getState());
        if(user.id === null) {
          store.dispatch(fetchUser(Session.userId));
        }
      })
      .catch((err) => {
        this.removeLock();
        this.logout();
      });
  }

  /**
   * Redirect user to unauthorized page on auth service
   * @param error
   */
  static unauthorized(error) {
    clearTimeout(idleTimer);
    clearTimeout(validateTimer);
    window.location.href = `${authUrl}/unauthorized?service=request_form${error ? `&error=${error}` : ''}`;
  }

  /**
   * Initialize session
   * @returns void
   */
  static init() {
    this.clearStaleSession();
    let cookie = this.getCookie();

    if (!cookie) {
      this.redirectLogin();
      return;
    }

    Session.userId = cookie.user_id;
    updateAccessToken(cookie.access_token);

    if (this.validate()) {
      store.dispatch(verifyAccess());
      this.idleInit();
    }
  }

  /**
   * Update auth cookie expiration date to keep it alive, since cookie is destroyed automatically after expiration.
   * When an AristaMD app is opened in a new tab, it will look for auth cookie. If the cookie doesn't exist, the
   * user will be required to log in again. Otherwise the session is kept alive and we can initialize a new
   * session from the auth cookie.
   */
  static keepAlive() {
    if(!this.isLocked()) {
      const cookie = this.getCookie();
      this.setCookie(cookie);
    }
  }

  /**
   * Get the access token
   * @returns {*}
   */
  static getToken() {
    let cookie = this.getCookie();
    if (cookie) {
      return cookie.access_token;
    }
  }

  /**
   * Set up event listeners, reset last active date, and start monitoring for inactivity
   */
  static idleInit() {
    this.addIdleEventListeners();
    this.idleReset();
    this.idleMonitor();
  }

  /**
   * Add event listeners to reset idle whenever user clicks or presses a key
   */
  static addIdleEventListeners() {
    document.addEventListener('mousedown', this.idleReset.bind(this));
    document.addEventListener('keypress', this.idleReset.bind(this));
  }

  /**
   * Get the timeout for the user based on their role
   */
  static getIdleTimeoutInMinutes() {
    return idleTimeoutInMinutes;
  }

  /**
   * Get the warning time in minutes
   */
  static getIdleWarningInMinutes() {
    return idleWarningInMinutes;
  }

  /**
   * Set a cookie with current timestamp indicating when user was last active
   */
  static idleReset() {
    const showIdleModal = selectShowIdleModal(store.getState());
    if (showIdleModal) {
      // don't reset if the idle modal is showing
      return;
    }
    if(!this.isLocked()) {
      Cookies.set(
        idleCookieName,
        moment().unix(),
        {
          path: authCookiePath,
          domain: authCookieDomain,
          secure: true,
        }
      );
    }
  }

  /**
   * Monitor for user inactivity, show/hide warning modal as needed, and automatically logout
   */
  static idleMonitor() {
    const unixTimestamp = Cookies.get(idleCookieName);
    if (unixTimestamp) {
      const now = moment();
      const lastActive = moment.unix(unixTimestamp);
      const idleInSeconds = now.diff(lastActive, 'seconds');
      const timeoutInMinutes = this.getIdleTimeoutInMinutes();
      const warningInSeconds = (timeoutInMinutes - idleWarningInMinutes) * 60;
      const timeoutInSeconds = timeoutInMinutes * 60;
      const showIdleModal = selectShowIdleModal(store.getState());
      if (idleInSeconds > timeoutInSeconds) {
        // if user has been idle longer than timeout, log out
        this.logout();
        return;
      } else if (idleInSeconds > warningInSeconds) {
        // if user has been idle longer than warning threshold
        // display the modal, if it's not already showing
        if (!showIdleModal) {
          this.showIdleModal();
        }
      } else if (showIdleModal) {
        // we can get here if user was active or closed the modal in another tab
        // close the modal since it no longer applies
        this.hideIdleModal();
      }
      // for debugging purposes, we set idle status in session storage
      sessionStorage.setItem('idle', JSON.stringify({
        idle: idleInSeconds,
        warning: warningInSeconds,
        timeout: timeoutInSeconds,
      }));
    }
    idleTimer = setTimeout(this.idleMonitor.bind(this), 1000); // run again in 1 second
  }

  /**
   * Show the inactivity modal, user is required to click ok to continue session
   * @returns void
   */
  static showIdleModal() {
    store.dispatch(setShowIdleModal(true));
  }

  /**
   * Hide the inactivity modal
   * @returns void
   */
  static hideIdleModal() {
    store.dispatch(setShowIdleModal(false));
    this.idleReset();
  }

  /**
   * Check user permissions
   * @param User object
   * @param permission Permission to find
   * @return boolean
   */
  static verifyPermissions(user, permission) {
    let permissionAccess = false;
    if (user.id != null) {
      Object.keys(user.roles).forEach(key => {
        Object.keys(user.roles[key].permissions).forEach(accessKey => {
          if (user.roles[key].permissions[accessKey].name == permission) {
            permissionAccess = true;
          }
        })
      });
    }
    return permissionAccess;
  }
}
