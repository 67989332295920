import React, { useState, useEffect, useContext } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import moment from 'moment-timezone';
import { capitalize, get, isUndefined } from 'lodash';
import { useFormContext } from 'react-hook-form';

import PESAxios from '../../../../services/PESAxios';
import AppGrid from '../../../common/app-grid/AppGrid';
import ehrLabResultsGridOptions from './ag-grid/gridOptions';
import b64ToBlob from '../../../../helpers/b64ToBlob';
import { EhrDocumentContext } from '../../../../services/EhrDocumentProvider';

const EhrLabResults = ({ onViewDocument, isActiveTab }) => {
  const { patient } = useSelector((state) => state.patient, shallowEqual);
  const [ehrLabResults, setEhrLabResults] = useState([]);
  const [resultsFetched, setResultsFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpeningLab, setIsOpeningLab] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [visible, setVisible] = useState(true);
  const { ehrType } = useSelector((state) => state.organization, shallowEqual);
  const { getValues } = useFormContext();
  const context = useContext(EhrDocumentContext);
  const onDismiss = () => setVisible(!visible);

  const parseLabResults = ({ resource }) => ({
    date: moment(resource.effectiveDateTime).format('MM/DD/YYYY'),
    lab_name: resource.code.text || resource.code.coding[0].display,
    status: capitalize(resource.status),
    ordering_provider: get(resource, 'performer[0].display', 'Unknown'),
    id: resource.id,
    binary: `DiagnosticReport/${resource.id}?_pretty=true&patientIdentifier=${patient.mrn}`,
    source: 'EHRLab',
    ehrType,
  });

  const fetchEhrLabResults = async () => {
    setErrorMessage(null);
    onDismiss();
    try {
      const { data } = await PESAxios().post('/fhir/r4/DiagnosticReport/_search', {
        patient: patient.ehrFHIRId,
      }, {
        headers: {
          site: patient.organization_id,
        },
      });
      if (data.entry[0]?.resource?.resourceType !== 'OperationOutcome') {
        setEhrLabResults(data.entry.filter(({ resource }) => !['Immunization/Injection', 'Nursing'].some((c) => c === resource?.category[0]?.text))
          .map(parseLabResults));
      }
    } catch (e) {
      setErrorMessage('There was a problem when trying to connect to the EHR. Please contact Support or try again.');
    }
    setIsLoading(false);
    setResultsFetched(false);
  };

  useEffect(() => {
    if (patient?.ehrFHIRId && ehrLabResults.length === 0 && isActiveTab && !resultsFetched) {
      fetchEhrLabResults();
      setResultsFetched(true);
    } else if (isUndefined(patient?.ehrFHIRId)) {
      setIsLoading(false);
    }
  }, [ehrLabResults, isActiveTab]);

  const openEhrLabResult = async (event) => {
    const { binary, lab_name, date } = event.data;
    if (event.colDef.field === 'lab_name') {
      onDismiss();
      setErrorMessage(null);
      let data = context.documentCache.current[binary];
      if (!data) {
        setIsOpeningLab(true);
        event.api.showLoadingOverlay();
        try {
          const { data: resource } = await PESAxios().get(
            `/fhir/r4/${binary}`,
            { headers: { site: patient.organization_id } },
          );
          ([{ data }] = resource.presentedForm);
          context.documentCache.current[binary] = data;
        } catch (e) {
          setErrorMessage('There was a problem when trying to pull this document from the EHR. Please contact Support or try again.');
        }
        event.api.hideOverlay();
        setIsOpeningLab(false);
      }
      if (data) {
        const urlPreview = `/pdf-viewer/viewer.html?file=${URL.createObjectURL(b64ToBlob(data))}`;
        onViewDocument({ name: `Lab Result - ${lab_name} ${date}`, urlPreview });
      }
    }
  };

  const gridOptions = ehrLabResultsGridOptions(ehrLabResults);

  const onSelectionChanged = (selection) => context.setEhrDocuments(selection, 'EHRLab');

  /**
   * Set the rows as selected if the are already attached, when the grid is ready.
   * @param param
   */

  const setCurrentUploadedAssetsAsSelected = (param) => {
    const currentDocuments = getValues().documents || [];
    const currentEhrDocuments = currentDocuments.filter((d) => d.source === 'EHRLab');
    if (currentEhrDocuments.length === 0) {
      return;
    }
    // Set the row as selected if the doc is already attached
    param.api.forEachNode((rowNode) => {
      const exist = currentDocuments.some((current) => current.ehrId === rowNode.data.id);
      if (exist) {
        rowNode.setSelected(true);
      }
    });
  };

  return (
    <>
      <div>
        { (isLoading || isOpeningLab) ? (
          <div className="alert alert-primary my-2" role="alert">
            Loading ...
          </div>
        ) : false}
        { errorMessage ? (
          <Alert color="danger" isOpen={visible} toggle={onDismiss}>
            {errorMessage}
          </Alert>
        ) : false}
      </div>
      <div>
        {
          !isLoading ? (
            <div className="ehr-lab-results-container">
              <AppGrid
                onCellClicked={openEhrLabResult}
                gridOptions={gridOptions}
                rowData={ehrLabResults}
                onRowClick={onSelectionChanged}
                sendApiToParent={(params) => {
                  setCurrentUploadedAssetsAsSelected(params);
                }}
                overlayLoadingTemplate="ㅤ"
              />
            </div>
          ) : false
        }
      </div>
    </>
  );
};

EhrLabResults.propTypes = {
  onViewDocument: PropTypes.func.isRequired,
  isActiveTab: PropTypes.bool.isRequired,
};

export default EhrLabResults;
