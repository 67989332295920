import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import AppGrid from '../../app-grid/AppGrid';
import { formatDateTimezone } from '../../../../utils';

const dateFormat = (cell, timezone) => formatDateTimezone(cell.value, timezone);

const userRoleFormat = params => (
  <div>
    <p className="m-0">{params.data.user}</p>
    <p className="m-0">{params.data.role}</p>
  </div>
);

const detailsFormat = ({ value }) => {
  let valueString = value;
  if (valueString === 'n/a') {
    valueString = value.toString().toUpperCase();
  }
  return (
    <div>
      <p className="ellipsis" data-toggle="tooltip" data-placement="left" title={valueString}>
        {valueString}
      </p>
    </div>
  );
};

const ServiceLogsTableModal = ({ modalTitle, rowData, showModal, showModalHandler }) => {
  const { user } = useSelector(state => state.user, shallowEqual);
  const columnDefs = [
    {
      headerName: 'Date',
      field: 'timestamp',
      cellRendererFramework: cell => dateFormat(cell, user?.timezone),
    },
    {
      field: 'action',
    },
    {
      field: 'user',
      cellRendererFramework: userRoleFormat,
    },
    {
      headerName: 'Details/Notes',
      field: 'details',
      cellRendererFramework: detailsFormat,
    },
  ];

  const onModalClose = () => {
    showModalHandler(false);
  };

  const gridOptions = {
    columnDefs,
    headerHeight: 50,
    rowHeight: 60,
    sortable: false,
    filter: false,
    singleClickEdit: true,
    pagination: rowData.length > 5,
    floatingFilter: false,
    suppressColumnVirtualisation: true,
    skipHeaderOnAutoSize: true,
    resizable: false,
    paginationPageSize: 5,
  };

  return (
    <div
      id="serviceLogsTableModal"
      className="modal fade"
      data-aut="service-logs-table-modal-wrap"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title font-weight-bold" id="modalLabel">{modalTitle}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onModalClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body text-left">
            {showModal &&
              <AppGrid
                gridOptions={gridOptions}
                rowData={rowData}
              />
            }
          </div>
          <div className="modal-footer d-block">
            <button
              data-aut="service-log-back-button"
              type="button"
              className="btn btn-secondary float-right"
              data-dismiss="modal"
              onClick={onModalClose}
            >
              Back to Form
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceLogsTableModal;
