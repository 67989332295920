/**
 * Creates a reducer to use objects instead of switch statements for performance
 * See https://toddmotto.com/deprecating-the-switch-statement-for-object-literals/
 * @param initialState
 * @param actions
 * @return {Function}
 */
export default (initialState, actions) => (state = initialState, action) => {
  const reduce = actions[action.type];

  if (!reduce) {
    return state;
  }

  return {
    ...state,
    ...reduce(state, action),
  };
};
