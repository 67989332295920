import axios from 'axios';
import { put } from 'redux-saga/effects';
import { parseGroup } from 'fhir-parser';
import EnvironmentSettings from '../../../services/EnvironmentSettings';
import {
  providers,
  isFetchingProviders,
} from '../../../state';

export default function* fetchPractitioners(action) {
  try {
    yield put(isFetchingProviders(true));
    const response = yield axios.get(`${EnvironmentSettings.fhirApiUrl}/Group?type=practitioner&code=requesting_provider&managing-entity=Organization/${action.payload}`);
    const practitioners = parseGroup(response.data);
    if (practitioners.error) {
      yield put(providers(null));
    } else {
      yield put(providers(practitioners.parsedData));
    }
  } catch (e) {
    // TODO: handle/display error?
    yield put(providers(null));
  }
  // Restore original state when user is fetched
  yield put(isFetchingProviders(false));
}
