import React from 'react';
import { useFormContext } from 'react-hook-form';

const EhrPatientNotFoundControls = ({onPatientEdit, onMrnManualEntry}) => {
  const { getValues } = useFormContext();
  const values = getValues();

  return( !values.mrnManualEntry && 
    (
    <div className="col-12 pl-0 mt-3">
      <button
        type="button"
        className="btn btn-success mr-2"
        onClick={onPatientEdit}
        data-auto="patient-retry-button"
      >
      Retry
      </button>
      <button
        type="button"
        className="btn btn-light ml-2"
        onClick={(e) =>{ onMrnManualEntry(); }}
      >
        Enter Manually 
      </button>
    </div>
    )
  );
};

export default EhrPatientNotFoundControls;
