import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { openLiveChat } from '../../../helpers/liveChatHelper';

function RequestError() {
  const {
    serviceRequestData: serviceRequestDataSelector,
    postServiceRequestError,
  } = useSelector((state) => state.serviceRequest, shallowEqual);

  if (!postServiceRequestError) {
    return null;
  }

  let message = '';
  if (serviceRequestDataSelector === 'Request duplicated') {
    message = 'This eConsult has already been submitted for this patient.';
  } else if (serviceRequestDataSelector === 'Request failed') {
    message = 'There was an error submitting your request. Please try again.';
  } else {
    message = (
      <>
        Your request was not submitted due the following reason:
        <p className="font-weight-bold mb-0">{serviceRequestDataSelector}</p>
      </>
    );
  }

  return (
    <div className="col-sm-12 pl-3 p-0 mt-2">
      <div className="alert alert-danger" role="alert" data-aut="request-error">
        <i className="fa fa-warning fa-sm mr-2" />
        {message}
        {' '}
        For support, please email
        <a className="warning-live-chat ml-1 mr-1" href="mailto:support@aristamd.com">support@aristamd.com</a>
        or start an
        <a href="#" className="warning-live-chat ml-1" onClick={openLiveChat}>online chat</a>
        .
      </div>
    </div>
  );
}

export default RequestError;
