import React from 'react';
import axios from 'axios';
import { shallowEqual, useSelector } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'lodash';
import EnvironmentSettings from '../../../services/EnvironmentSettings';
import {
  goToAllEConsults,
  eConsultShowLink,
  eConsultEditLink
} from "../../../services/NextApp";

function PatientPastEconsults() {
  const { patient } = useSelector(state => state.patient, shallowEqual);
  const { appIframeSource } = useSelector((state) => state.app, shallowEqual)

  const getStatusToDisplay = (status) => {
    let statusText = '';

    switch (status) {
      case 'draft':
        statusText = 'Draft';
        break;
      case 'active':
        statusText = 'Open';
        break;
      case 'revoked':
        statusText = 'Cancelled';
        break;
      case 'on-hold':
        statusText = 'On Hold';
        break;
      case 'completed':
        statusText = 'Finalized';
        break;
      default:
        statusText = '';
        break;
    }

    return statusText;
  };

  const goToPDF = (e, econsult) => {
    e.preventDefault();
    window.open(`https://assets${EnvironmentSettings.domainServer}/print/econsult/${econsult.id}`, '_blank');
  };

  const goToRequest = (e, econsult) => {
    e.preventDefault();
    // if it is draft and created using this service
    axios.get(`${EnvironmentSettings.fhirApiUrl}/ServiceRequest/${econsult.fhir_id}`).then(({ data }) => {
      const econsultStatus = data.status;
      let url;
      if (econsult.source.match('aristamd/request-form') && ['draft', 'on-hold'].includes(econsultStatus)) {
        url = appIframeSource === 'app' ?
          `https://request${EnvironmentSettings.domainServer}/econsults/create-request/${econsult.fhir_id}` :
          eConsultEditLink(econsult.fhir_id);
      } else {
        url = appIframeSource === 'app' ?
          `https://app${EnvironmentSettings.domainServer}/#/app/econsults/${econsult.id}` :
          eConsultShowLink(econsult.id);
      }
      window.open(url, '_blank');
    });
  };

  const goToPatientHistory = (e, patientId) => {
    e.preventDefault();
    appIframeSource === 'app' ?
      window.open(`https://app${EnvironmentSettings.domainServer}/#/app/patients/${patientId}`, '_blank') :
      goToAllEConsults();

  };

  return (
    <div className="form-group col-12 mb-4" data-aut="past-econsult-component" id="past-econsult-component">
      <div className="row">
        <div className="col-12 col-sm-4 col-md-3 col-xl-2 text-sm-right">
          <label className="m-0 past-econsults-label" htmlFor="pastEconsult">Past eConsults ({patient?.pastEconsults?.length > 0 ? patient.pastEconsults.length : 0})</label>
          <p className="text-muted">Past eConsults can be viewed by the specialist</p>
        </div>
        <div className="col-sm-8 col-xl-10">
          { (patient && !isEmpty(patient.pastEconsults)) && patient.pastEconsults.map((pastEconsult) => {
            return (
              <div key={pastEconsult.id} className="past-econsults-list mb-3">
                <p className="m-0 font-weight-bold">
                  <a href="#" className="m-0" onClick={(e) => { goToRequest(e, pastEconsult); }}>C0{pastEconsult.id}</a>
                  <span> - {pastEconsult.specialty} ({getStatusToDisplay(pastEconsult.status)})</span>
                </p>
                <p className="text-muted m-0">Submitted: {pastEconsult.submitted_at !== null ?
                  moment(pastEconsult.submitted_at, 'YYYY-MM-DD').format('MM/DD/YY') : '-'}
                </p>
                { (pastEconsult.status !== 'draft' && pastEconsult.status !== 'revoked') &&
                  <a href="#" onClick={(e) => { goToPDF(e, pastEconsult); }}>View eConsult Record (PDF)</a>
                }
              </div>
            );
          })}
          { patient?.totalPastEconsults > 5 &&
          <p className="view-all-link" >Showing 5 most recent eConsults <a href="#" onClick={(e) => { goToPatientHistory(e, patient.id); }}> View All</a></p>
          }
        </div>
      </div>
    </div>
  );
}
export default PatientPastEconsults;
