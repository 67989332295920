import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { sortBy, isEmpty } from 'lodash';

import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  addFieldsRefs,
  ehrTypeOrganization,
  fetchProviders, hasEHREnabledOrganization,
  isPatientSearched,
  patient,
  selectIsFetchingProviders,
  selectProviders,
  selectUser,
  setMRNRequired,
} from '../../../state';
import RequestError from '../../common/request-error/RequestError';

const emptyErrorMessage = 'This field is required';

function RequestedBy(props) {
  const {
    organizations,
    providers,
    isFetchingProviders,
    user,
    request,
  } = props;

  // States
  const [onSelectedValue, setOnSelectedValue] = useState(request ? request.siteSelector : '');
  const [onSelectedProviderValue, setOnSelectedProviderValue] = useState(request ? request.providerSelector : '');
  const [onOpeningSaveAsDraft, setOnOpeningSaveAsDraft] = useState(false);
  const [onProviderOptions, setOnProviderOptions] = useState(null);
  const {
    serviceRequestDraftData,
    isFetchingRequest,
  } = useSelector(state => state.serviceRequest, shallowEqual);
  const { patientContextId, patient: patientData } = useSelector(state => state.patient);
  const { ehr_patient_context_id: ehrPatientContextId } = useSelector((state) => state.user.context);

  const siteSelectorRef = useRef();
  const providerSelectorRef = useRef();

  const {
    errors,
    setValue,
    getValues,
    clearErrors,
    trigger,
    register,
  } = useFormContext();

  // reused functions
  const filterOptions = (inputValue, object) => {
    if (!isEmpty(object)) {
      return object.filter(i =>
          i.label?.toLowerCase().includes(inputValue.toLowerCase()));
    }
  };

  const { fieldsRefs } = useSelector(state => state.fieldRef, shallowEqual);

  const dispatch = useDispatch();

  /*
  * Provider input and select control
  */
  async function onSelectProvider(selectedItem) {
    // Fix for FORM-536 when the requester is a provider and they press create new request
    // provider input is autopopulated before being registered in the form
    if (!getValues().providerSelector) {
      await register({ name: 'providerSelector' }, { required: emptyErrorMessage });
    }
    setValue('providerSelector', selectedItem);
    if (selectedItem) {
      clearErrors('providerSelector');
      await trigger('providerSelector');
      dispatch(addFieldsRefs([]));
    }
    setOnSelectedProviderValue(selectedItem);
  }

  /* Sites Functions
  * Sites input and select control
  * @param {object} selectedItem - The selected item from the drop down
  * @param {string} action - The action performed by user on the async select component (or
  * "autopopulate" to indicate setting option on open saved draft)
  */
  const onSelectSite = async (selectedItem, action = 'select-option') => {
    // clean the selected provider when the site change
    await onSelectProvider('');
    await setOnSelectedValue(selectedItem);
    await setValue('siteSelector', selectedItem);
    if (selectedItem) {
      // get providers for the selected site
      dispatch(fetchProviders(selectedItem.value));
      dispatch(hasEHREnabledOrganization(selectedItem.hasEhrEnabled));
      dispatch(ehrTypeOrganization(selectedItem.ehrType));
      dispatch(addFieldsRefs([]));
      clearErrors('siteSelector');
      trigger('siteSelector');
      if (action !== 'autopopulate') {
        /**
         * If patient portability is not enabled and user changes the site selector, then
         * reset patient object and search flag, forcing user to look up patient again by MRN
         */
        const patientPortabilityEnabled =
          user.organization?.settings?.patient?.portability === true;
        /*
         * If we already looked up patient using EHR patient context, we don't need to require
         * the user to look them up again by MRN.
         */
        if (!ehrPatientContextId && !patientPortabilityEnabled) {
          if (getValues('patientSearch')) {
            dispatch(patient(null));
            dispatch(isPatientSearched(false));
            dispatch(setMRNRequired(true));
          }
        }
      }
    }
  };

  // Load options if the user use the input to filter them
  const loadSitesOptions = (inputValue, callback) => {
    callback(filterOptions(inputValue, organizations));
  };

  /* Providers Functions */

  // Load options if the user use the input to filter them
  const loadProvidersOptions = (inputValue, callback) => {
    callback(filterOptions(inputValue, onProviderOptions));
  };

  useEffect(() => {
    // If we have just one site it will be selected by default
    if (isEmpty(onSelectedValue) && organizations?.length === 1) {
      onSelectSite(organizations[0]);
    }
    if (providers && !isFetchingProviders) {
      // formatting the data for the select
      let providersOptions = providers.map(item => ({ value: item.id, label: item.name }));
      providersOptions = sortBy(providersOptions, [o => o.label]);
      setOnProviderOptions(providersOptions);
      // Auto populate information for an eConsult save as draft
      if (serviceRequestDraftData && !isFetchingRequest &&
        !onOpeningSaveAsDraft && !request && onSelectedValue.value) {
        setOnOpeningSaveAsDraft(true);
        onSelectProvider(providersOptions
          .find(prov => prov.value == serviceRequestDraftData.providerSelector));
        return;
      }
      // If the user creating the request is a provider we select it by default
      if (user?.roles && isEmpty(onSelectedProviderValue)) {
        const providerRole = user.roles.find(role => role.name === 'requesting_provider');
        if (providerRole) {
          const selectedProvider = providersOptions
            .find(obj => parseInt(obj.value, 10) === user.id);
          onSelectProvider(selectedProvider);
        }
      }
    }
  }, [organizations, providers, isFetchingProviders]);

  useEffect(() => {
    if (errors && typeof fieldsRefs === 'string') {
      if (fieldsRefs === 'siteSelector') {
        siteSelectorRef.current.focus();
      }
      if (fieldsRefs === 'providerSelector') {
        providerSelectorRef.current.focus();
      }
    }
  }, [errors, fieldsRefs]);

  function selectSite(orgId) {
    // FORM-291 we should validate if the site selected is not available
    const siteSelected = organizations.find(org => org.value === +orgId);
    if (siteSelected) {
      onSelectSite(siteSelected, 'autopopulate');
    }
  }

  useEffect(() => {
    if (organizations.length >= 1) {
      // AutoPopulating if an eConsult save as draft is opened
      if (!request && serviceRequestDraftData && !isFetchingRequest) {
        selectSite(serviceRequestDraftData.siteSelector);
      } else if (patientContextId) {
        // if there is a patient already selected from web app we should autopopulate the site
        selectSite(patientData.organization_id);
      }
    }
  }, [request, serviceRequestDraftData, organizations, isFetchingRequest, patientContextId]);

  return (
    <div className="card" data-auto="requested-by-form" id="site-selector">
      <div className="card-title p-3 m-0">
        <h6 className="font-weight-bold border-bottom pb-2 requested-by">Requested by</h6>
      </div>
      <div className="card-body">
        <div className="row form-group">
          <div className="col-12 col-sm-3 col-md-3 col-xl-2 text-sm-right">
            <label htmlFor="siteName" data-aut="site-label" id="SitesSelector">Site</label>
          </div>
          <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3">
            <AsyncSelect
              ref={siteSelectorRef}
              autoFocus={request?.scroll?.provider || !request}
              name="siteSelector"
              className={
                errors.siteSelector
                    ? 'site-select is-invalid'
                    : 'site-select'
              }
              value={onSelectedValue}
              onChange={onSelectSite}
              defaultOptions={organizations}
              loadOptions={loadSitesOptions}
              isLoading={!organizations?.length}
              isDisabled={!organizations?.length}
            />
            <ErrorMessage errors={errors} name="siteSelector">
              {({ messages, message: customMesagge }) => {
                const errorMessage = messages || [customMesagge];
                return errorMessage &&
                Object.entries(errorMessage).map(([type, message]) => (
                  <div key={type} className="alert alert-danger" role="alert" data-auto="site-select-error">
                    <i className="fa fa-warning fa-sm" /> {message}
                  </div>
                ));
              }}
            </ErrorMessage>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-12 col-sm-3 col-md-3 col-xl-2 text-sm-right">
            <label htmlFor="providerName" data-aut="provider-label">Provider</label>
          </div>
          <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3">
            <AsyncSelect
              ref={providerSelectorRef}
              name="providerSelector"
              className={
                errors.providerSelector
                    ? 'provider-select is-invalid'
                    : 'provider-select'
              }
              value={onSelectedProviderValue}
              onChange={onSelectProvider}
              defaultOptions={onProviderOptions}
              isLoading={isFetchingProviders}
              isDisabled={!onSelectedValue || isFetchingProviders}
              loadOptions={loadProvidersOptions}
            />
            {errors.providerSelector?.type === 'required' &&
            <div className="alert alert-danger" role="alert" data-auto="provider-select-error">
              <i className="fa fa-warning fa-sm" /> {errors.providerSelector.message}
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

const {
  any,
  arrayOf,
  bool,
  object,
} = PropTypes;


RequestedBy.propTypes = {
  providers: arrayOf(any),
  isFetchingProviders: bool,
  user: object,
  organizations: arrayOf(any),
};

RequestedBy.defaultProps = {
  providers: [],
  isFetchingProviders: false,
  user: {},
  organizations: [],
};

const mapStateToProps = state => ({
  providers: selectProviders(state),
  isFetchingProviders: selectIsFetchingProviders(state),
  user: selectUser(state),
});

export default connect(mapStateToProps)(RequestedBy);
