import createReducer from '../createReducer';

/** ********************************************************
 * CONSTANTS
 ******************************************************** */

export const FETCH_INSURANCE_PROVIDERS = 'insurance_providers/FETCH_INSURANCE_PROVIDERS';
export const FETCHING_INSURANCE_PROVIDERS = 'insurance_providers/FETCHING_INSURANCE_PROVIDERS';
export const INSURANCE_PROVIDERS = 'insurance_providers/INSURANCE_PROVIDERS';

/** ********************************************************
 * REDUCER
 ******************************************************** */

export const initialStateInsuranceProviders = {
  insuranceProviders: null,
  isFetchingInsuranceProviders: false,
};

export default createReducer(initialStateInsuranceProviders, {
  [FETCHING_INSURANCE_PROVIDERS]: (state, { payload }) => ({
    ...state, isFetchingInsuranceProviders: payload,
  }),
  [INSURANCE_PROVIDERS]: (state, { payload }) => ({
    ...state, insuranceProviders: payload,
  }),
});


/** ********************************************************
 * ACTIONS
 ******************************************************** */

/**
 * Fetches the insurance providers
 * @param {int} insuranceProviderId
 * @returns {{type: string, payload: *}}
 */
export const fetchInsuranceProviders = insuranceProviderId => ({
  type: FETCH_INSURANCE_PROVIDERS,
  payload: insuranceProviderId,
});

/**
 * Used by the UI to determine if the form is ready to be shown
 * @param {boolean} bool
 * @returns {{type: string, payload: boolean}}
 */
export const isFetchingInsuranceProviders = bool => ({
  type: FETCHING_INSURANCE_PROVIDERS,
  payload: bool,
});

/**
 * Sets the insurance_providers
 * @param {[]} payload
 * @returns {{type: string, payload: *}}
 */
export const insuranceProviders = payload => ({
  type: INSURANCE_PROVIDERS,
  payload,
});


/**
 * Select the insurance_providers
 * @param state
 * @returns {null|[]}
 */
 export const selectInsuranceProviders = state => state.insuranceProviders.insuranceProviders;
