import { trackEvent } from '../services/AnalyticsService';

/**
 * Open the live chat and track the click event
 */
export const openLiveChat = (e) => {
  e.preventDefault();
  trackEvent('Click Live Chat');
  window.Beacon('navigate', '/ask/chat/');
  window.Beacon('open');
};
