import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Session from '../../../services/Session';
import ServiceLogsTableModal from './service-log-modal/ServiceLogsTableModal';


const LogNotes = ({ user }) => {
  
  const [showServiceLogsNotes, setServiceLogsNotes] = useState(false);
  const { serviceLogsData } = useSelector(state => state.serviceLogs, shallowEqual);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (user?.id != null) {
      setServiceLogsNotes(Session.verifyPermissions(user, 'service_log_access'));
    }
  }, []);

  const onShowServiceLogs = async () => {
    setShowModal(true);
    $('#serviceLogsTableModal').modal('show');
  };

return (
  <>
    { serviceLogsData && serviceLogsData[0] && showServiceLogsNotes &&
      <div data-auto="service-logs-container">
        <ServiceLogsTableModal modalTitle='Service Logs' rowData={serviceLogsData} showModal={showModal} showModalHandler={setShowModal} />
        <span className="" >
          {serviceLogsData[0].details !== 'n/a' && (<span>Service Log Notes: {serviceLogsData[0].details}.</span>)}
          <button type="button" className="btn btn-link pt-0 pl-0" data-auto='view-service-logs' onClick={onShowServiceLogs}>View Service Logs</button>
        </span>
      </div>
    }
  </>
  );
};  

export default LogNotes;