import React, { useState, useEffect } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Button, Spinner, FormGroup, Input, Label,
} from 'reactstrap';
import { createEpicPatient, confirmPatientMatch } from '../../state';
import axios from '../../services/axios';
import Toast from '../../services/Toast';
import { getGenderAbbreviation, getGenderDisplay, getGenderByAbbreviation } from '../../helpers/gender';

const PatientMatch = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patients, setPatients] = useState([]);
  // eslint-disable-next-line max-len
  const serviceRequest = useSelector((state) => state.serviceRequest.serviceRequestData, shallowEqual);
  const {
    patient: patientObject,
    isConfirmingPatientMatch,
    isCreatingEpicPatient,
    confirmPatientMatchError,
    patientFHIR,
    createEpicPatientError,
  } = useSelector((state) => state.patient, shallowEqual);
  const org = serviceRequest?.performer.find((p) => p.reference.includes('Organization'));

  useEffect(() => {
    function fetchPatients() {
      axios.post('/ehr/epic/patients/search', {
        organization_id: org.reference.split('/')[1],
        first_name: patientObject.first_name,
        last_name: patientObject.last_name,
        gender: getGenderAbbreviation(patientObject.gender).toLowerCase(),
        date_of_birth: moment(patientObject.dob).format('YYYY-MM-DD'),
      }).then(({ data }) => {
        setPatients(data);
        setLoading(false);
      });
    }
    fetchPatients();
  }, []);

  useEffect(() => {
    if (confirmPatientMatchError) {
      Toast.error('There was an error while matching the patient.');
    }
  }, [confirmPatientMatchError]);

  useEffect(() => {
    if (createEpicPatientError) {
      Toast.error('There was an error while creating the patient.');
    }
  }, [createEpicPatientError]);

  const getEhrIdentifiers = () => (
    selectedPatient.identifiers.map((i) => (
      {
        use: 'usual',
        type: {
          text: i.display_name || '',
        },
        system: i.identifier_name,
        value: i.identifier,
        extension: [{
          valueString: i.auth_provider_id,
          url: 'http://extensions.aristamd.com/organization/auth_provider_id',
        }],
      }
    ))
  );

  const confirmMatch = () => {
    patientFHIR.identifier = [...patientFHIR.identifier, ...getEhrIdentifiers()];

    patientFHIR.id = patientObject.fhirID;
    dispatch(confirmPatientMatch(patientObject.fhirID, patientFHIR));
  };

  const createPatientInEpic = () => {
    dispatch(createEpicPatient(org.reference.split('/')[1], patientObject));
  };

  // Social security number

  const getSSN = (patient) => {
    const ssn = patient.identifiers.find((i) => i.identifier_name === 'urn:oid:2.16.840.1.113883.4.1');
    if (ssn) {
      return (
        <div>
          <span className="font-weight-bold">SSN:</span>
          {' '}
          {ssn.identifier}
        </div>
      );
    }

    return null;
  };

  // Controls which patient input is selected
  const isChecked = (patient) => {
    if (!selectedPatient) {
      return false;
    }

    return selectedPatient.identifiers[0].identifier === patient.identifiers[0].identifier;
  };

  // Check if the patient has missing data
  const hasMissingData = (patient) => patient.identifiers.length === 0 || patient.gender === '' || patient.date_of_birth === '';

  // Retrieve the missing data error to display
  const getMissingDataError = (patient) => {
    if (hasMissingData(patient)) {
      return (
        <span className="text-danger">Full details are not available for this patient. It may be required to Break-The-Glass in Epic.</span>
      );
    }

    return null;
  };

  const ListPatients = () => (
    <FormGroup tag="fieldset">
      <h5>
        List of Patients Found at {org.display}
      </h5>
      <div className="container">
        <div className="row">
          {patients.map((p, index) => (
            <div key={`patient-ehr-${index}`} className="col-12 col-md-6 col-lg-4">
              <FormGroup check className="patient-match-option">
                {!hasMissingData(p) && (
                <Input
                  id={`patient-ehr-${index}`}
                  name="selectedPatient"
                  type="radio"
                  checked={isChecked(p)}
                  onChange={() => setSelectedPatient(p)}
                />
                )}
                {' '}
                <Label check htmlFor={`patient-ehr-${index}`}>
                  <div>
                    <span className="font-weight-bold">Name:</span>
                    {' '}
                    {p.first_name}
                    {' '}
                    {p.last_name}
                  </div>
                  <div>
                    <span className="font-weight-bold">DOB:</span>
                    {' '}
                    {p.date_of_birth}
                  </div>
                  <div>
                    <span className="font-weight-bold">Gender:</span>
                    {' '}
                    {getGenderByAbbreviation(p.gender)?.display ?? ''}
                  </div>
                  { getSSN(p) }
                  { getMissingDataError(p) }
                </Label>
              </FormGroup>
            </div>
          ))}
        </div>
      </div>
    </FormGroup>
  );

  return (
    <div className="patient-match" data-auto="patient-match">
      <h3>Patient Confirmation Required</h3>
      <p>
        Your request has been submitted, but we need to confirm your patient is
        the same patient we found at
        {' '}
        {org.display}
        .
      </p>
      <ul>
        <li data-auto="firstName">
          <span className="font-weight-bold">First Name:</span>
          {' '}
          {patientObject.first_name}
        </li>
        <li data-auto="lastName">
          <span className="font-weight-bold">Last Name:</span>
          {' '}
          {patientObject.last_name}
        </li>
        <li data-auto="dob">
          <span className="font-weight-bold">DOB:</span>
          {' '}
          {patientObject.dob}
        </li>
        <li data-auto="gender">
          <span className="font-weight-bold">Gender:</span>
          {' '}
          {getGenderDisplay(patientObject.gender)}
        </li>
      </ul>
      <div>
        { (loading && patients.length === 0) ? <Spinner /> : <ListPatients />}
      </div>
      <Button
        onClick={confirmMatch}
        color="success"
        disabled={selectedPatient === null || isConfirmingPatientMatch || isCreatingEpicPatient}
        className="mr-4"
      >
        Confirm Matching Patient
        {' '}
        {isConfirmingPatientMatch && <Spinner size="sm" />}
      </Button>
      <Button
        onClick={createPatientInEpic}
        color="success"
        disabled={isCreatingEpicPatient || isConfirmingPatientMatch}
      >
        Create a new patient
        {' '}
        {isCreatingEpicPatient && <Spinner size="sm" />}
      </Button>
    </div>
  );
};

export default PatientMatch;
