import React, { useEffect } from 'react';
import _ from 'lodash';
import { AgGridReact } from 'ag-grid-react';

/**
 * Ag Grid Component
 * Grid Options
 *   columnDefs: Definition of the Headers and fields
 *   rowData: Data to display
 *   multiSortKey: Key to activate multi sort
 *   animateRows: Animate Rows
 *   suppressMenuHide: Show the left menu filter always
 *   pagination: Enable Pagination
 *   paginationPageSize: if pagination its true we can define the amount of rows to show
 *   rowHeight: Height of the Rows
 *   rowSelection: Single or Multiple
 *   onSelectionChanged: Trigger On Click event in a Row
 *   domLayout: normal, autoHeight or printing, if there is no pagination autoHeight is the default
 *   enableCellChangeFlash: Highlight after update
 *   overlayLoadingTemplate: Loading Message
 *   onGridReady: Function Triggered after load the data
 *   frameworkComponents: Custom Template Using React Component
 *   getRowNodeId: Callback that allows to define a custom id for the queue
 *   sortable: Enables sorting
 *   filter: Enables filter fields
 *   resizable: Enables table to be resizable
 * https://www.ag-grid.com/javascript-grid-components/#registering-custom-components
 * https://www.ag-grid.com/javascript-grid-properties/
 */

function AppGrid(props) {

  const icons = {
    sortAscending: '<i class="fa fa-sort-up"/>',
    sortDescending: '<i class="fa fa-sort-down"/>',
    sortUnSort: '<i class="fa fa-sort"/>',
  };

  const gridOptions = {
    columnDefs: props.gridOptions.columnDefs.map(obj => ({
      ...obj,
      suppressMenu: true,
      unSortIcon: props.gridOptions.sortable !== undefined ? props.gridOptions.sortable : true,
      sortable: props.gridOptions.sortable !== undefined ? props.gridOptions.sortable : obj.sortable,
      filter: props.gridOptions.filter !== undefined ? props.gridOptions.filter : true,
      resizable: props.gridOptions.resizable !== undefined ? props.gridOptions.resizable : true,
    })),
    multiSortKey: 'ctrl',
    animateRows: props.gridOptions.animateRows !== undefined
      ? props.gridOptions.animateRows : true,
    pagination: props.gridOptions.pagination !== undefined
      ? props.gridOptions.pagination : true,
    paginationPageSize: props.gridOptions.paginationPageSize
      ? props.gridOptions.paginationPageSize : 20,
    rowHeight: props.gridOptions.rowHeight !== undefined ? props.gridOptions.rowHeight : 35,
    singleClickEdit: props.gridOptions.singleClickEdit !== undefined
      ? props.gridOptions.singleClickEdit : false,
    frameworkComponents: props.gridOptions.frameworkComponents
      ? props.gridOptions.frameworkComponents : {},
    onCellEditingStopped: props.gridOptions.onCellEditingStopped
      ? props.gridOptions.onCellEditingStopped
      : null,
    onCellEditingStarted: props.gridOptions.onCellEditingStarted
      ? props.gridOptions.onCellEditingStarted
      : null,
    stopEditingWhenCellsLoseFocus:
            !_.isUndefined(props.gridOptions.stopEditingWhenCellsLoseFocus)
              ? props.gridOptions.stopEditingWhenCellsLoseFocus
              : true,
    suppressColumnVirtualisation: props.gridOptions.suppressColumnVirtualisation
      ? props.gridOptions.suppressColumnVirtualisation
      : false,
    rowClassRules: props.gridOptions.rowClassRules || null,
    getRowNodeId: props.gridOptions.getRowNodeId || null,
    onSortChanged: props.gridOptions.sortChanged,
    onFilterChanged: props.gridOptions.filterChanged,
    suppressRowClickSelection: props.gridOptions.suppressRowClickSelection || null,
  };

  const overlayLoadingTemplate = props.overlayLoadingTemplate || '<span class="ag-overlay-loading-center">Loading...</span>';

  const autoSizeAll = (skipHeader) => {
    const allColumnIds = [];
    gridOptions.columnApi?.getAllColumns().forEach((column) => {
      allColumnIds.push(column.colId);
    });

    gridOptions.columnApi?.autoSizeColumns(allColumnIds, skipHeader);
  };

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
    props.sendApiToParent ? props.sendApiToParent(params) : '';
  };

  const onGridSizeChanged = () => {
    if (props.browserViewport.width < 950) {
      autoSizeAll(false);
    } else {
      gridOptions.api.sizeColumnsToFit();
    }
  };

  const onSelectionChanged = (event) => {
    props.onRowClick ? props.onRowClick(event.api.getSelectedRows()) : '';
  };

  const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  useEffect(() => {
    autoSizeAll(true);
  });

  return (
    <div className="w-100 h-100">
      <div className="ag-theme-alpine">
        <AgGridReact
          onCellClicked={props.onCellClicked}
          gridOptions={gridOptions}
          rowData={props.rowData}
          animateRows
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
          onFirstDataRendered={onFirstDataRendered}
          components={props.gridOptions.components}
          rowSelection={props.gridOptions.rowSelection || 'single'}
          domLayout="autoHeight"
          headerHeight={props.gridOptions.headerHeight}
          overlayLoadingTemplate={overlayLoadingTemplate}
          icons={icons}
          data-aut="ag-grid-react"
        />
      </div>
    </div>
  );
}

export default AppGrid;
