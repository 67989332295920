import moment from 'moment';
import axios from 'axios';
import { put } from 'redux-saga/effects';
import { parsePatient } from 'fhir-parser';
import EnvironmentSettings from '../../../../services/EnvironmentSettings';
import { formatDate } from '../../../../utils';
import { patient, patientFHIR } from '../../../../state';
import { isGenderOther, parsePatientGender } from '../../../../helpers/gender';
import { logError } from '../../../../helpers/log';

/**
 * Posts the payload info related to patient to patient fhir endpoint
 * @param payload
 * @return {Generator<*|Generator<SimpleEffect<"PUT", PutEffectDescriptor<{type: string, payload: *}>>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string, payload: boolean}>>, *, *>|Promise<AxiosResponse<any>>, *, null&Generator<Promise<AxiosResponse<any>>|Generator<SimpleEffect<"PUT", PutEffectDescriptor<{type: string, payload: *}>>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string, payload: boolean}>>, *, *>, *, null>>}
 */
export default function* postPatient(payload, user) {
  let data = null;
  let patientResource = {
    resourceType: 'Patient',
    identifier: [
      {
        use: 'official',
        system: payload.patientSystem || 'aristamd_internal_mrn',
        value: payload.patientSearch,
      },
    ],
    name: {
      use: 'usual',
      family: payload.lastName,
      given: [
        payload.firstName,
      ],
    },
    birthDate: moment(payload.dob).format('YYYY-MM-DD'),
    extension: [
      {
        url: 'http://extensions.aristamd.com/patient/pending_provider_ehr_patient_match',
        valueString: payload.mrnManualEntry ?? '',
      },
    ],
  };

  if (isGenderOther(payload.gender)) {
    patientResource.gender = 'other';
    patientResource.extension.push({
      url: 'http://extensions.aristamd.com/patient/gender_identity',
      valueString: payload.gender,
    });
  } else {
    patientResource.gender = payload.gender;
  }

  if (payload.coverage?.patientCoverage?.address) {
    patientResource.address = [
      {
        state: payload.coverage?.patientCoverage?.address.state,
        city: payload.coverage?.patientCoverage?.address.city,
        postalCode: payload.coverage?.patientCoverage?.address.zipCode,
        line: [
          payload.coverage?.patientCoverage?.address.line1,
          payload.coverage?.patientCoverage?.address.line2 ?? '',
        ],
      },
    ];
  } else if (user?.organization?.settings?.patient?.address_enabled) {
    const address = {
      use: 'home',
      type: 'physical',
      country: 'USA',
      line: [payload.street_1],
      city: payload.city,
      state: payload.state,
      postalCode: payload.zip,
    };

    if (payload.street_2) {
      address.line.push(payload.street_2);
    }
    patientResource.address = [address];
  }
  // FORM-313 if there are two tabs working on the same patient
  // we need to validate first if the patient already exists on the database
  // if exists update the patient if not create a new one
  try {
    const patientInDatabase = yield axios.get(`${EnvironmentSettings.fhirApiUrl}/Patient?identifier=${payload.patientSearch}`);
    if (!payload.siteSelector.hasEhrEnabled) {
      const fhirPatientID = patientInDatabase?.data.identifier.find((id) => id.system === 'aristamd_fhir_id').value;
      if (fhirPatientID) {
        // Keep existing address
        if (!patientResource.address && patientInDatabase.data.address) {
          patientResource.address = patientInDatabase.data.address;
        }
        patientResource = { ...patientResource, id: fhirPatientID };
        ({ data } = yield axios.put(`${EnvironmentSettings.fhirApiUrl}/Patient/${fhirPatientID}`, patientResource));
      } else {
        // Force patient to be created
        throw new Error('Patient identifier aristamd_fhir_id was not found');
      }
    } else {
      ({ data } = { ...patientInDatabase });
    }
  } catch (error) {
    // If the patient was not found, proceed to create it
    if (error?.response?.status === 404 || error?.message === 'Patient identifier aristamd_fhir_id was not found') {
      // new patient
      // Send the organization when creating a new patient
      patientResource = {
        ...patientResource,
        managingOrganization: {
          reference: `Organization/${payload.siteSelector.value}`,
        },
      };
      if (payload.preferralId) {
        // Preferral patient to be synced
        patientResource.identifier.push({
          use: 'usual',
          system: 'preferral_id',
          value: payload.preferralId,
        });
      }
      ({ data } = yield axios.post(`${EnvironmentSettings.fhirApiUrl}/Patient`, patientResource));
    } else { // Otherwise, throw an error to display the proper message to the user
      let logErrorObject;
      if (error.isAxiosError && error.response) {
        logErrorObject = { ...error.response };
      } else {
        logErrorObject = {
          name: error.name,
          message: error.message,
          stack: error.stack,
        };
      }
      logError('Unable to verify if the patient exists', logErrorObject, true);
      throw new Error();
    }
  }
  let patientData;
  ({ parsedData: patientData } = parsePatient(data));
  [patientData] = patientData;
  patientData.dob = formatDate(patientData.dob);
  patientData.gender = parsePatientGender(patientData);
  yield put(patient(patientData));
  yield put(patientFHIR(data));
  return patientData;
}
