import createReducer from '../createReducer';

/** ********************************************************
 * CONSTANTS
 ******************************************************** */

export const FETCH_SPECIALTIES = 'specialty/FETCH_SPECIALTIES';
export const FETCHING_SPECIALTIES = 'specialty/FETCHING_SPECIALTIES';
export const SPECIALTIES = 'specialty/SPECIALTIES';
export const PEDIATRICS_FILTER = 'specialty/PEDIATRICS_FILTER';

/** ********************************************************
 * REDUCER
 ******************************************************** */

export const initialStateSpecialty = {
  specialties: null,
  isFetchingSpecialties: false,
  pediatricsFilter: null,
};

export default createReducer(initialStateSpecialty, {
  [SPECIALTIES]: (state, { payload }) => ({
    ...state, specialties: payload,
  }),
  [FETCHING_SPECIALTIES]: (state, { payload }) => ({
    ...state, isFetchingSpecialties: payload,
  }),
  [PEDIATRICS_FILTER]: (state, { payload }) => ({
    ...state, pediatricsFilter: payload,
  }),
});


/** ********************************************************
 * ACTIONS
 ******************************************************** */

/**
 * Fetches the specialties
 * @param {organizationID, filter} specialties
 * @returns {{type: string, payload: *}}
 */
export const fetchSpecialties = (organizationID, filter=null) => ({
  type: FETCH_SPECIALTIES,
  payload: { organizationID, filter },
});

/**
 * Used by the UI to determine if the form is ready to be shown
 * @param {boolean} bool
 * @returns {{type: string, payload: boolean}}
 */
export const isFetchingSpecialties = bool => ({
  type: FETCHING_SPECIALTIES,
  payload: bool,
});

/**
 * Sets the specialties
 * @param {[]} payload
 * @returns {{type: string, payload: *}}
 */
export const specialties = payload => ({
  type: SPECIALTIES,
  payload,
});

/**
 * Sets the specialties filter
 * @param {[]} payload
 * @returns {{type: string, payload: *}}
 */
export const pediatricsFilterLink = payload => ({
  type: PEDIATRICS_FILTER,
  payload,
});
