import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Dashboard } from '@uppy/react';
import Uppy from '@uppy/core';
import Cookies from 'js-cookie';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import {
  assetThumbnailUrl,
  assetPreviewUrl,
  setCallbackValue,
} from '../utils/index';
import EnvironmentSettings from './EnvironmentSettings';
import { trackEvent } from './AnalyticsService';

const XHRUpload = require('@uppy/xhr-upload');

function UppyService({ onProcess }) {
  const handleClose = () => {};
  const {
    setValue,
    getValues,
  } = useFormContext();

  const authCookieName = `aristamd_auth${EnvironmentSettings.stage ? '_dev' : ''}`;
  let cookie = Cookies.get(authCookieName);
  if (cookie) {
    cookie = JSON.parse(cookie);
  }

  const uppy = React.useMemo(() => new Uppy({
    id: 'uppy',
    meta: { // Any extra field required by the endpoint
      security_profile: 'sensitive',
    },
    autoProceed: true,
    restrictions: {
      maxFileSize: 100 * 1024 * 1024, // 100MB
      allowedFileTypes: [ // Allowed extensions
        '.jpeg',
        '.jpg',
        '.png',
        '.gif',
        '.tif',
        '.tiff',
        '.bmp',
        '.mp4',
        '.mov',
        '.pdf',
        '.plain',
        '.rtf',
        '.text',
        '.txt',
        '.csv',
      ],
    },
  })
    .use(XHRUpload, {
      endpoint: '/api/assets',
      fieldName: 'content', // What is the name of the field for file
      metaFields: ['security_profile'], // What meta info will be sent to BE
      timeout: 60 * 1000, // there are some files that takes more than the default time (30 seconds)
      // https://uppy.io/docs/xhr-upload/#timeout-30-1000,
      // Default response transformation
      getResponseData: (responseText) => ({ id: responseText }),
      headers: {
        authorization: `Bearer ${cookie?.access_token}`,
      },
      limit: 3,
    })
    .on('complete', (files) => {
      trackEvent('Attach File', {
        from: 'request form',
        file_source: 'upload',
      });
      const newAttachments = files.successful.map((file) =>
        // Creates the file structure to be added to the eConsult
        ({
          id: file.response.body.id,
          ehrId: null,
          name: file.name,
          size: file.size, // Required to recreate the uploaded file list
          type: file.type, // Required to recreate the uploaded file list
          source: 'AristaMD Clinical', // Required to recreate the uploaded file list
          lastModified: file.data.lastModified, // Required to recreate the uploaded file list
          thumbnail: `${assetThumbnailUrl(file.response.body.id)}?width=200`,
          urlPreview: assetPreviewUrl(file.response.body.id),
          uppyId: file.id,
        }));
      const currentAttachments = getValues().documents || [];
      Array.prototype.push.apply(currentAttachments, newAttachments);
      setValue('documents', currentAttachments);
      setCallbackValue(onProcess, false);

      // use server generated thumbnail for any frontend failed to generate (video, txt, pdf, etc)
      newAttachments.forEach((attachment) => {
        const previewContainer = document.getElementById(`uppy_${attachment.uppyId}`)
          .querySelector('.uppy-Dashboard-Item-previewInnerWrap');
        if (!previewContainer.querySelector('.uppy-Dashboard-Item-previewImg')) {
          previewContainer.innerHTML = `<img class="uppy-Dashboard-Item-previewImg" alt="${attachment.name}" src="${attachment.thumbnail}"/>`;
        }
      });
    })
    .on('file-removed', (file, reason) => {
      if (file.response?.body && reason === 'removed-by-user') {
        setValue('documents', getValues().documents
          .filter((attachments) => attachments.id !== file.response.body.id));
      }
    })
    .on('progress', (progress) => {
      if (progress === 0 && uppy.getFiles().length === 0) {
        setCallbackValue(onProcess, false);
      }
    })
    .on('upload', ({ fileIDs }) => {
      setCallbackValue(onProcess, true);
    })
    .on('cancel-all', () => {
      setCallbackValue(onProcess, false);
    }), [])
    .on('upload-error', (_file, _error, response) => {
      if (response.status === 422) {
        const { errors } = JSON.parse(response.body.id);
        if (Object.keys(errors).includes('mime_type')) {
          uppy.info({
            message: 'Invalid File MIME Type',
            details: errors.mime_type,
          }, 'error', 5000);
        }
      }
    });

  useEffect(() => () => uppy.close(), []);

  return (
    <Dashboard
      uppy={uppy}
      height={400}
      inline
      showRemoveButtonAfterComplete
      onRequestClose={handleClose}
      locale={{
        strings: {
          // Text to show on the droppable area.
          // `%{browse}` is replaced with a link that opens the system file selection dialog.
          dropPaste: 'Drop files here, paste or %{browse}',
          // Used as the label for the link that opens the system file selection dialog.
          browse: 'browse',
        },
      }}
    />
  );
}

export default UppyService;
