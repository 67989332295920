import store from '../store';
import { selectEhrPatientContextId, selectPatientContextId } from '../state';
import EnvironmentSettings from './EnvironmentSettings';

/**
 * CoreApp service provides methods related to the Core App (Clinical App)
 */
export default class CoreApp {
  /**
   * Get patient context clinical app url
   * @returns {string}
   */
  static patientContextUrl() {
    return `https://app${EnvironmentSettings.domainServer}/#/app/patient-context`;
  }

  /**
   * Get patient profile clinical app url
   * @returns {string}
   */
  static patientProfileUrl(patientId) {
    return `https://app${EnvironmentSettings.domainServer}/#/app/patients/${patientId}`;
  }

  /**
   * Get eConsults clinical app url
   * @returns {string}
   */
  static econsultsUrl() {
    return `https://app${EnvironmentSettings.domainServer}/#/app/econsults`;
  }

  /**
   * Get the default core app url for the current user state
   * @returns {string}
   */
  static defaultUrl() {
    const state = store.getState();
    const ehrPatientContextId = selectEhrPatientContextId(state);
    const patientContextId = selectPatientContextId(state);
    if (ehrPatientContextId) {
      return this.patientContextUrl();
    } else if (patientContextId) {
      return this.patientProfileUrl(patientContextId);
    }
    return this.econsultsUrl();
  }
}
