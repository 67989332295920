import { all, takeEvery } from 'redux-saga/effects';

import browser from './browser';

import fetchUser from './fetchUser';
import fetchContext from './context/fetchContext';
import verifyAccess from './verify-access';
import fetchOrganizations from './FHIR/organization/fetchOrganizations';
import fetchPractitioners from './FHIR/group/fetchPractitioners';
import fetchSpecialties from './FHIR/healthcareService/fetchSpecialties';

import fetchChiefComplaints from './FHIR/chiefComplaint/fetchChiefComplaints';
import fetchPatient from './FHIR/patient/fetchPatient';
import postEpicPatient from './FHIR/patient/postEpicPatient';
import confirmPatientMatch from './FHIR/patient/confirmPatientMatch';
import fetchPatientContext from './FHIR/patient/fetchPatientContext';
import setMRNRequired from './FHIR/patient/setMRNRequired';
import fetchWorkupChecklists from './FHIR/workupChecklist/fetchWorkupChecklists';

import postServiceRequest from './FHIR/serviceRequest/postServiceRequest';
import fetchServiceRequest from './FHIR/serviceRequest/fetchServiceRequest';

import fetchCoverage from './form/patients/fetchCoverage';
import fetchStoredCoverage from './form/patients/fetchStoredCoverage';

import addFieldRef from './form/fieldRef';
import fetchInsuranceProviders from './form/insurance/fetchInsuranceProviders';

import fetchPrintQueue from './printQueue/fetchPrintQueue';
import clearPrintQueue from './printQueue/clearPrintQueue';

import fetchStates from './state/fetchStates';
import fetchAssetPreviewStatus from './asset/fetchAssetPreviewStatus';

import {
  VERIFY_ACCESS,
  FETCH_CONTEXT,
  FETCH_USER,
  FETCH_ORGANIZATIONS,
  FETCH_PROVIDERS,
  FETCH_SPECIALTIES,
  FETCH_CHIEF_COMPLAINTS,
  FETCH_PATIENT,
  CREATE_EPIC_PATIENT,
  CONFIRM_PATIENT_MATCH,
  FETCH_PATIENT_CONTEXT,
  FETCH_WORKUP_CHECKLISTS,
  POST_SERVICE_REQUEST,
  ADD_FIELD_REF,
  FETCH_SERVICE_REQUEST,
  SET_MRN_REQUIRED,
  FETCH_INSURANCE_PROVIDERS,
  FETCH_PRINT_QUEUE,
  CLEAR_PRINT_QUEUE,
  FETCH_COVERAGE,
  FETCH_STORED_COVERAGE,
  FETCH_STATES,
  FETCH_ASSET_PREVIEW_STATUS,
} from '../state';

export default function* rootSaga() {
  yield all([
    yield takeEvery(VERIFY_ACCESS, verifyAccess),
    yield takeEvery(['CALCULATE_RESPONSIVE_STATE', 'redux-responsive/CALCULATE_RESPONSIVE_STATE'], browser),
    yield takeEvery(FETCH_USER, fetchUser),
    yield takeEvery(FETCH_CONTEXT, fetchContext),
    yield takeEvery(FETCH_ORGANIZATIONS, fetchOrganizations),
    yield takeEvery(FETCH_PROVIDERS, fetchPractitioners),
    yield takeEvery(FETCH_SPECIALTIES, fetchSpecialties),
    yield takeEvery(FETCH_CHIEF_COMPLAINTS, fetchChiefComplaints),
    yield takeEvery(FETCH_PATIENT, fetchPatient),
    yield takeEvery(CONFIRM_PATIENT_MATCH, confirmPatientMatch),
    yield takeEvery(CREATE_EPIC_PATIENT, postEpicPatient),
    yield takeEvery(FETCH_PATIENT_CONTEXT, fetchPatientContext),
    yield takeEvery(SET_MRN_REQUIRED, setMRNRequired),
    yield takeEvery(FETCH_CHIEF_COMPLAINTS, fetchChiefComplaints),
    yield takeEvery(FETCH_WORKUP_CHECKLISTS, fetchWorkupChecklists),
    yield takeEvery(POST_SERVICE_REQUEST, postServiceRequest),
    yield takeEvery(FETCH_SERVICE_REQUEST, fetchServiceRequest),
    yield takeEvery(ADD_FIELD_REF, addFieldRef),
    yield takeEvery(FETCH_INSURANCE_PROVIDERS, fetchInsuranceProviders),
    yield takeEvery(FETCH_PRINT_QUEUE, fetchPrintQueue),
    yield takeEvery(CLEAR_PRINT_QUEUE, clearPrintQueue),
    yield takeEvery(FETCH_COVERAGE, fetchCoverage),
    yield takeEvery(FETCH_STORED_COVERAGE, fetchStoredCoverage),
    yield takeEvery(FETCH_STATES, fetchStates),
    yield takeEvery(FETCH_ASSET_PREVIEW_STATUS, fetchAssetPreviewStatus),
  ]);
}
