import React from 'react';

export default rowData => ({
  columnDefs: [
    {
      sortable: true,
      field: 'date',
      flex:2,
      minWidth:200,
      maxWidth: 250,
      checkboxSelection: true,
      sort: 'desc',
      comparator(a, b) {
        if (a === b) {
          return 0;
        }
        return new Date(a) > new Date(b) ? 1 : -1;
      },
    },
    {
      field: 'lab_name',
      wrapText: true,
      flex: 2,
      minWidth: 200,
      maxWidth: 250,
      headerName: 'Lab Name',
      autoHeight: true,
    },
    {
      field: 'status',
      flex: 2,
      minWidth: 200,
      maxWidth: 250,
    },
    {
      field: 'ordering_provider',
      headerName: 'Ordering Provider',
      wrapText: true,
      autoHeight: true,
      flex: 2,
      minWidth: 200,
      maxWidth: 250,
    },
  ],
  headerHeight: 50,
  rowHeight: 50,
  filter: false,
  singleClickEdit: true,
  pagination: rowData.length > 6,
  floatingFilter: false,
  suppressColumnVirtualisation: true,
  skipHeaderOnAutoSize: false,
  resizable: true,
  suppressRowClickSelection: true,
  rowSelection: 'multiple',
  paginationPageSize: 6,
});
