import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { postServiceRequest } from '../../../state';
import { history } from '../../../store';
import ServiceLogModal from '../service-logs/service-log-modal/ServiceLogModal';
import Session from '../../../services/Session';
import CoreApp from '../../../services/CoreApp';
import {goToOpenEConsults} from "../../../services/NextApp";

const SaveDraftButtonReview = () => {
  const dispatch = useDispatch();
  const { isRequestOnProcess, serviceRequestData } = useSelector(state => state.serviceRequest, shallowEqual);
  const { user } = useSelector(state => state.user, shallowEqual);
  const { appIframeSource } = useSelector((state) => state.app, shallowEqual);
  const { coverage } = useSelector((state) => state.coverage);
  const [showServiceLogsModal, setServiceLogsModal ] = useState(false);
  const request = history.location?.state;

  useEffect(() => {
    if (user?.id != null) {
      setServiceLogsModal(Session.verifyPermissions(user, 'service_log_access'));
    }
  }, []);

  useEffect(() => {
    if ((serviceRequestData?.status === 'draft' && serviceRequestData !== 'Request failed') && !isRequestOnProcess.showSpinner) {
      if (appIframeSource === 'app') {
        window.location.href = CoreApp.defaultUrl()
      } else {
        goToOpenEConsults('econsult_draft', serviceRequestData.identifier.find(identifier => identifier.system === 'aristamd_econsult_id').value)
      }
    }
  }, [isRequestOnProcess, serviceRequestData]);

  const onSaveAsDraft = async () => {
    if (showServiceLogsModal) {
      $('#serviceLogModal').modal('show');
    } else {
      onSaveRequest(null);
    }
  };

  const onSaveRequest = (logComment) => {
    $('#serviceLogModal').modal('hide');
    // on the review page we dont have form context just the request of history
    // we need to trust the information of the review is already validated
    dispatch(postServiceRequest({
      ...request,
      status: 'draft',
      action: 'save_by_requester',
      service_message: logComment,
      coverage,
    }));
  };

  return (
    <>
      <ServiceLogModal modalId="serviceLogModal" modalTitle="Save as draft" modalButton="Save as draft" confirmButton={onSaveRequest} />
  		<button type="button" className="btn btn-light mr-3" data-auto="request-form-save-as-draft" onClick={onSaveAsDraft}>Save as Draft</button>
    </>
  );
};

export default SaveDraftButtonReview;
