import moment from 'moment';

export const supportedDateFormats = ['M-DD-YYYY', 'MM-DD-YYYY', 'MM/DD/YYYY', 'M/DD/YYYY', 'MM/D/YYYY', 'M/D/YYYY',
  'MM.DD.YYYY', 'M.DD.YYYY', 'MMMM D, YYYY', 'MMMM DD, YYYY', 'MMM D, YYYY',
  'MMMM D YYYY', 'MMMM DD YYYY', 'MMM DD, YYYY', 'MMM D YYYY', 'MMM DD YYYY', 'YYYY-MM-D', 'YYYY/M/DD', 'YYYY.M.DD'];

/**
 * Validates the Date of Birth
 * @param  date date from form input
 * @returns boolean
 */
export const validateDate = (date) => {
  const dateBefore = moment().subtract(130, 'years');
  return moment(date, supportedDateFormats, true).isValid() &&
    !moment(date, 'MM-DD-YYYY').isAfter() &&
    !moment(date, 'MM-DD-YYYY').isBefore(dateBefore, 'year');
};

export const fillPatientData = (request, setValue) => {
  setValue('patientId', request.patientId);
  setValue('firstName', request.firstName);
  setValue('lastName', request.lastName);
  setValue('dob', request.dob);
  setValue('gender', request.gender);
  setValue('street_1', request.street_1);
  setValue('street_2', request.street_2);
  setValue('city', request.city);
  setValue('state', request.state);
  setValue('zip', request.zip);
  setValue('insuranceProviderText', request.insuranceProviderText);
  setValue('insuranceMemberId', request.insuranceMemberId);
};
