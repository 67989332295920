import { createResponsiveStateReducer } from 'redux-responsive';

/** ********************************************
 * constants
 ******************************************** */

/** ********************************************
 * reducer
 ******************************************** */

export default createResponsiveStateReducer(
  {
    extraSmall: 575,
    small: 767,
    medium: 959,
    large: 1199,
    extraLarge: 10000,
  },
  {
    extraFields: () => ({
      height: window.innerHeight,
      width: window.innerWidth,
    }),
  },
);

/** ********************************************
 * actions
 ******************************************** */

/** ********************************************
 * selectors
 ******************************************** */

/**
 * @param state
 * @return {string}
 */
export const selectBrowserViewport = state => state.browser;
