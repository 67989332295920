import axios from 'axios';
import { put, select } from 'redux-saga/effects';
import { parseServiceRequest } from 'fhir-parser';
import EnvironmentSettings from '../../../services/EnvironmentSettings';
import {
  serviceRequestData,
  patient,
  patientFHIR,
  isConfirmingPatientMatch,
  selectServiceRequestData,
  confirmPatientMatchError,
} from '../../../state';

export default function* confirmPatientMatch({ payload }) {
  yield put(isConfirmingPatientMatch(true));
  yield put(confirmPatientMatchError(false));

  try {
    let serviceRequestStatus;

    // Update patient with the new identifiers
    yield axios.put(`${EnvironmentSettings.fhirApiUrl}/Patient/${payload.patientID}`, payload.patient);

    // Update the econsult if it is on-hold
    const serviceRequest = yield select(selectServiceRequestData);
    serviceRequestStatus = serviceRequest.status;
    if (serviceRequestStatus === 'on-hold') {
      const econsult = { ...serviceRequest };
      econsult.status = 'active';
      delete econsult.authoredOn;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < econsult.extension.length; i++) {
        if (econsult.extension[i].url === 'http://extensions.aristamd.com/servicerequest/submitted_at') {
          econsult.extension.splice(i, 1);
        }
      }

      const response = yield axios.put(`${EnvironmentSettings.fhirApiUrl}/ServiceRequest/${serviceRequest.id}`, econsult);
      yield put(serviceRequestData(response.data));
      const econsultData = parseServiceRequest(response.data);
      serviceRequestStatus = econsultData.parsedData[0].status;
    }
    // If the service request status is already active or becomes active, we can clear the patient
    if (serviceRequestStatus === 'active') {
      yield put(patient(null));
      yield put(patientFHIR(null));
    }
  } catch (e) {
    console.error(e);
    yield put(confirmPatientMatchError(true));
  }

  yield put(isConfirmingPatientMatch(false));
}
