import createReducer from '../createReducer';

/** ********************************************************
 * CONSTANTS
 ******************************************************** */
export const FETCH_USER = 'user/FETCH_USER';
export const FETCHING_USER = 'user/FETCHING_USER';
export const IS_LOGGING_OUT = 'user/IS_LOGGING_OUT';
export const USER = 'user/USER';
export const USER_ORGANIZATIONS = 'user/ORGANIZATIONS';
export const USER_ORGANIZATION_ID = 'user/ORGANIZATION_ID';
export const USER_ORGANIZATION_NAME = 'user/ORGANIZATION_NAME';
export const VERIFY_ACCESS = 'user/VERIFY_ACCESS';
export const VERIFYING_ACCESS = 'user/VERIFYING_ACCESS';
export const ACCESS_VERIFIED = 'user/ACCESS_VERIFIED';
export const ACCESS_ERROR = 'user/ACCESS_ERROR';
export const FETCH_CONTEXT = 'user/FETCH_CONTEXT';
export const FETCHING_CONTEXT = 'user/FETCHING_CONTEXT';
export const CONTEXT = 'user/CONTEXT';
export const CONTEXT_ERROR = 'user/CONTEXT_ERROR';

/** ********************************************************
 * REDUCER
 ******************************************************** */

export const initialStateUser = {
  isFetchingUser: false,
  isLoggingOut: false,
  isVerifyingAccess: false,
  isAccessVerified: false,
  user: {
    id: null,
    name: null,
    email: null,
  },
  organizations: null,
  organizationId: null,
  organizationName: null,
  isFetchingContext: false,
  context: null,
  isContextError: false,
};
export default createReducer(initialStateUser, {
  [FETCHING_USER]: (state, { payload }) => ({
    ...state, isFetchingUser: payload,
  }),
  [IS_LOGGING_OUT]: (state, { payload }) => ({
    ...state, isLoggingOut: payload,
  }),
  [USER]: (state, { payload }) => ({
    ...state, user: payload,
  }),
  [VERIFYING_ACCESS]: (state, { payload }) => ({
    ...state, isVerifyingAccess: payload,
  }),
  [ACCESS_VERIFIED]: (state, { payload }) => ({
    ...state, isAccessVerified: payload,
  }),
  [USER_ORGANIZATIONS]: (state, { payload }) => ({
    ...state, organizations: payload,
  }),
  [USER_ORGANIZATION_ID]: (state, { payload }) => ({
    ...state, organizationId: payload,
  }),
  [USER_ORGANIZATION_NAME]: (state, { payload }) => ({
    ...state, organizationName: payload,
  }),
  [CONTEXT]: (state, { payload }) => ({
    ...state, context: payload,
  }),
  [CONTEXT_ERROR]: (state, { payload }) => ({
    ...state, isContextError: payload,
  }),
});

/** ********************************************************
 * ACTIONS
 ******************************************************** */

/**
 * Fetches a user given an id
 * @param {int} id
 * @returns {{type: string, payload: int}}
 */
export const fetchUser = userId => ({
  type: FETCH_USER,
  payload: userId,
});

/**
 * Used by the UI to determine if the page is ready to be shown
 * @param {boolean} bool
 * @returns {{type: string, payload: bool}}
 */
export const isFetchingUser = bool => ({
  type: FETCHING_USER,
  payload: bool,
});

/**
 * Used by the UI to determine if the user is logging out
 * @param {boolean} bool
 * @returns {{type: string, payload: bool}}
 */
export const isLoggingOut = bool => ({
  type: IS_LOGGING_OUT,
  payload: bool,
});

/**
 * Sets the user
 * @param {object} object
 * @returns {{type: string, payload: *}}
 */
export const user = userData => ({
  type: USER,
  payload: userData,
});

/**
 * @param {integer} id
 * @returns {{type: string, payload: integer}}
 */
export const userOrganizationId = id => ({
  type: USER_ORGANIZATION_ID,
  payload: id,
});



/**
 * @param {integer} id
 * @returns {{type: string, payload: integer}}
 */
export const userOrganizationName = name => ({
  type: USER_ORGANIZATION_NAME,
  payload: name,
});

/**
 * @param {[]} orgs
 * @returns {{type: string, payload: []}}
 */
export const userOrganizations = orgs => ({
  type: USER_ORGANIZATIONS,
  payload: orgs,
});

/**
 * @param {int} id
 * @returns {{type: string}}
 */
export const verifyAccess = () => ({
  type: VERIFY_ACCESS
});

/**
 * @param {boolean} bool
 * @returns {{type: string, payload: bool}}
 */
export const isVerifyingAccess = bool => ({
  type: VERIFYING_ACCESS,
  payload: bool,
});

/**
 * @param {boolean} bool
 * @returns {{type: string, payload: bool}}
 */
export const isAccessVerified = bool => ({
  type: ACCESS_VERIFIED,
  payload: bool,
});

/**
 * Fetches oauth context
 * @returns {{type: string}}
 */
export const fetchContext = () => ({
  type: FETCH_CONTEXT,
});

/**
 * Used by the UI to determine if the page is ready to be shown
 * @param {boolean} bool
 * @returns {{type: string, payload: bool}}
 */
export const isFetchingContext = bool => ({
  type: FETCHING_CONTEXT,
  payload: bool,
});

/**
 * Sets the context
 * @param {object} object
 * @returns {{type: string, payload: *}}
 */
export const context = payload => ({
  type: CONTEXT,
  payload,
});

/**
 * Used by the UI to determine if context failed to load
 * @param {boolean} bool
 * @returns {{type: string, payload: bool}}
 */
export const isContextError = bool => ({
  type: CONTEXT_ERROR,
  payload: bool,
});

/** ********************************************************
 * SELECTORS
 ******************************************************** */
/**
 * @param state
 * @returns {boolean}
 */
export const selectIsFetchingUser = state => state.user.isFetchingUser;

/**
 * @param state
 * @returns {boolean}
 */
export const selectIsLoggingOut = state => state.user.isLoggingOut;


/**
 * @param state
 * @returns {boolean}
 */
export const selectIsVerifyingAccess = state => state.user.isVerifyingAccess;

/**
 * @param state
 * @returns {boolean}
 */
export const selectIsAccessVerified = state => state.user.isAccessVerified;

/**
 * @param state
 * @returns {boolean}
 */
export const selectUser = state => state.user.user;

/**
 * @param state
 * @returns []
 */
export const selectUserOrganizations = state => state.user.organizations;

/**
 * @param state
 * @returns {integer}
 */
export const selectUserOrganizationId = state => state.user.organizationId;

/**
 * @param state
 * @returns {integer}
 */
export const selectUserOrganizationName = state => state.user.organizationName;

/**
 * @param state
 * @returns {boolean}
 */
export const selectIsFetchingContext = state => state.user.isFetchingContext;

/**
 * @param state
 * @returns {object}|null
 */
export const selectContext = state => state.user.context;

/**
 * @param state
 * @returns int|null
 */
export const selectEhrPatientContextId =
    state => state.user.context?.ehr_patient_context_id || null;
