import axios from 'axios';
import { put, all } from 'redux-saga/effects';
import { parsePatient, parseServiceRequest } from 'fhir-parser';
import EnvironmentSettings from '../../../services/EnvironmentSettings';
import {
  formatDate,
  assetThumbnailUrl,
  assetPreviewUrl,
  assetUrl,
  validateDemographics,
} from '../../../utils';
import {
  patient,
  isFetchingPatient,
  isPatientSearched as setPatientSearched,
  pesErrorMessage,
  pesRequestFailed,
} from '../../../state';
import PESAxios from '../../../services/PESAxios';
import sendAlert from '../../../services/EmergencyAlerts';
import {
  MRN_NOT_FOUND_EHR,
  ERR_CONNECT_EHR,
  ERR_ALERT_REASON_EHR,
  ERR_ALERT_REASON_PES,
  ERR_PATIENT_ACCESS_EHR,
} from '../../../utils/constants';
import {trackEvent} from "../../../services/AnalyticsService";
import { parsePatientGender } from '../../../helpers/gender';

const urlExtension = 'http://extensions.aristamd.com/documentReference/uploaded_by';
const pesErrorData = {
  error_type: 'ehr-downtime',
  reason: '',
};
const ehrErrorCodes = ['invalid-request', 'ehr-auth-failure'];

// get the attachments associated to the patient
function documentsLoop(file, documents) {
  const assetId = file.resource.identifier.find(id => id.system === 'aristamd_id').value;
  const uploadedBy = file.resource.extension.find(extension => extension.url === urlExtension).valueString;
  const documentReference = {
    id: assetId,
    fhir_id: file.resource.id,
    name: file.resource.content[0].attachment.title,
    size: file.resource.content[0].attachment.size, // Required to recreate the uploaded file list
    type: file.resource.content[0].attachment.contentType, // Required to recreate the uploaded file list
    lastModified: file.resource.content[0].attachment.creation, // Required to recreate the uploaded file list
    thumbnail: `${assetThumbnailUrl(assetId)}?width=200`,
    urlPreview: (file.resource.content[0].attachment.contentType === 'application/dicom' || file.resource.content[0].attachment.title.split('.').pop() === 'dcm')
      ? assetUrl(assetId) : assetPreviewUrl(assetId),
    uploadedBy,
    source: file.resource.meta?.source,
    patientDocument: true,
    serviceRequestDocument: false,
  };
  return {
    ...documentReference,
    ...documents,

  };
}

// get the past econsults associated to the patient
function serviceRequestLoop(pastEconsult, pastEconsults) {
  const serviceRequest = parseServiceRequest(pastEconsult.resource);
  const pastEconsultData = {
    id: serviceRequest.parsedData[0].id,
    fhir_id: serviceRequest.parsedData[0].fhir_id,
    status: serviceRequest.parsedData[0].status,
    submitted_at: serviceRequest.parsedData[0].submitted_at,
    specialty: serviceRequest.parsedData[0].specialtyText,
    source: serviceRequest.parsedData[0].source,
  };
  return {
    ...pastEconsultData,
    ...pastEconsults,
  };
}

/**
 * Get the patient data from AristaMD
 * @param {*} payload
 * @returns
 */
export function* getPatientFromAristaMD(payload) {
  let url = `${EnvironmentSettings.fhirApiUrl}/Patient?identifier=${payload.patientID}`;

  if (payload.organization) {
    url += `&organizationId=${payload.organization.value}`;
  }
  if (payload.patientIDType) {
    url += `&identifierType=${payload.patientIDType}`;
  }

  return yield axios.get(url);
}

/**
 * Get the patient data from the PES service
 * @param payload
 * @returns {Generator<AxiosPromise<any>, *, Generator<AxiosPromise<any>, *, *>>}
 */
export function* getPatientFromPES(payload) {
  let response;
  let isEhrConnectionError = false;
  let isEHRPatientAccessError = false;
  const genericErrorMessage = 'A problem has occurred identifying the patient in the EHR. Please reload, or contact Support if the problem continues.';
  try {
    response = yield PESAxios().post('/fhir/r4/Patient/_search', {
      identifier: `${payload.patientIDType ? payload.patientIDType : 'MRN'}|${payload.patientID}`,
    }, {
      headers: {
        site: payload.organization.value,
      },
    });
    yield put(pesRequestFailed(false));
  } catch (e) {
    const { issue } = e.response.data;
    if (issue && issue[0].details.text) {
      switch (issue[0].details.text) {
        case 'Connection Timeout':
          isEhrConnectionError = true;
          break;
        case 'This patient does not exist in this context.':
          yield put(pesErrorMessage(MRN_NOT_FOUND_EHR));
          break;
        case 'Access to this patient is not allowed because access is restricted by the practice.':
          isEHRPatientAccessError = true;
          break;
        default:
          if (ehrErrorCodes.includes(issue[0].code)) {
            isEhrConnectionError = true;
          } else {
            yield put(pesErrorMessage(genericErrorMessage));
          }
      }
    } else if (e.response.status === 500) {
      isEhrConnectionError = true;
    } else {
      yield put(pesErrorMessage(genericErrorMessage));
    }

    // Throw error only if the problem is not
    // an EHR connection error and not
    // an EHR patient access error
    if (!isEhrConnectionError && !isEHRPatientAccessError) {
      throw e;
    }
  }

  let pat = null;
  if (isEhrConnectionError || isEHRPatientAccessError) {
    // If is EHR connection error proceed to trigger a slack alert
    if (isEhrConnectionError) {
      pesErrorData.reason = ERR_ALERT_REASON_EHR;
      sendAlert(pesErrorData);
    }
    yield put(pesRequestFailed(true));
    try {
      // Try to retrieve the patient from AristaMD database, in case it was created previously
      const patientResponse = yield getPatientFromAristaMD(payload);
      pat = patientResponse.data;
    } catch (e) {
      yield put(pesErrorMessage(isEhrConnectionError ? ERR_CONNECT_EHR : ERR_PATIENT_ACCESS_EHR));
      throw new Error(isEhrConnectionError ? ERR_CONNECT_EHR : ERR_PATIENT_ACCESS_EHR);
    }
  }

  // Throw error if both, EHR and AristaMD patient request fail
  if ((isEhrConnectionError || isEHRPatientAccessError) && !pat) {
    yield put(pesErrorMessage(isEhrConnectionError ? ERR_CONNECT_EHR : ERR_PATIENT_ACCESS_EHR));
    throw new Error(isEhrConnectionError ? ERR_CONNECT_EHR : ERR_PATIENT_ACCESS_EHR);
  }

  if (!pat) {
    const { resource } = response.data.entry[0];
    pat = resource;
  }

  if (pat.resourceType === 'OperationOutcome') {
    if (pat.issue[0].details.text === 'Resource request returns no results.') {
      yield put(pesErrorMessage(MRN_NOT_FOUND_EHR));
    } else {
      yield put(pesErrorMessage(genericErrorMessage));
    }
    console.error(pat.issue[0].details.text);
    throw new Error(pat.issue[0].details.text);
  }

  // Change organization reference to selected organization
  pat.managingOrganization = pat.managingOrganization || {};
  pat.managingOrganization.reference = `Organization/${payload.organization.value}`;
  const demographics = validateDemographics(pat);
  if (!demographics) {
    yield put(pesErrorMessage('Required patient information is missing. Please return to your EHR and correct the information needed on their patient record.'));
    throw new Error('Required patient information is missing. Please return to your EHR and correct the information needed on their patient record.');
  }
  return yield axios.post(`${EnvironmentSettings.fhirApiUrl}/Patient`, pat);
}

export default function* fetchPatient({ payload }) {
  yield put(isFetchingPatient(true));
  yield put(patient(null));
  let documents = {};
  let pastEconsults = {};
  let totalPastEconsults;
  try {
    let response = null;
    if (payload.organization?.hasEhrEnabled && payload.patientIDType !== 'aristamd_patient_id') {
      response = yield getPatientFromPES(payload);
    } else {
      response = yield getPatientFromAristaMD(payload);
    }
    let patientData = parsePatient(response.data);
    const identifier = patientData.parsedData[0].fhirID;
    const isFromPreferral = identifier === undefined;
    const emptyPromise = new Promise((resolve) => resolve({ data: { entry: [] } }));
    const patientDocuments = yield isFromPreferral ? emptyPromise : axios.get(`${EnvironmentSettings.fhirApiUrl}/DocumentReference?subject=Patient/${identifier}`);
    patientData.parsedData[0].dob = formatDate(patientData.parsedData[0].dob);
    patientData.parsedData[0].gender = parsePatientGender(patientData.parsedData[0]);

    if (patientDocuments?.data?.entry?.length > 0) {
      documents = yield all(patientDocuments.data.entry.map(file => documentsLoop(file, documents)));
    }

    const patientServiceRequests = yield isFromPreferral ? emptyPromise : axios.get(`${EnvironmentSettings.fhirApiUrl}/ServiceRequest?subject=Patient/${identifier}&_count=5`);

    if (patientServiceRequests?.data?.entry?.length > 0) {
      totalPastEconsults = patientServiceRequests.data.total;
      pastEconsults = yield all(patientServiceRequests.data.entry.map(pastEconsult => serviceRequestLoop(pastEconsult, pastEconsults)));
    }

    if (patientData.error) {
      console.log(patientData.error);
      yield put(patient(null));
    } else {
      patientData = patientData.parsedData;
      if (patientData.length === 1) {
        yield put(patient({
          ...patientData[0], documents, pastEconsults, totalPastEconsults,
        }));
        yield put(setPatientSearched(true, true));
      }
      // TODO check if the response has more than one result
    }
    yield put(pesErrorMessage(''));
    trackEvent('MRN Search', {
      result_found: patientData && !patientData.error && !!patientData[0].id,
      page: 'draft_econsult',
      ehr_search_enabled: payload?.organization?.hasEhrEnabled,
      is_pes_enabled: payload?.organization?.hasEhrEnabled && payload.patientIDType !== 'aristamd_patient_id',
    });
  } catch (e) {
    console.log(e);
    yield put(patient(null));
    trackEvent('MRN Search', {
      result_found: false,
      page: 'draft_econsult',
      ehr_search_enabled: payload?.organization?.hasEhrEnabled,
      is_pes_enabled: payload?.organization?.hasEhrEnabled && payload.patientIDType !== 'aristamd_patient_id',
    });
  }
  // Restore original state when user is fetched
  yield put(isFetchingPatient(false));
}
