import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

function WorkupChecklist() {
  const {
    workupChecklists,
    isFetchingWorkupChecklists,
  } = useSelector(state => state.workupChecklist, shallowEqual);

  return (
    <div className="col-lg-12 col-xl-6">
      {(!isFetchingWorkupChecklists && workupChecklists?.length > 0) && (
        <div className="p-4 d-inline-block chief-complaint-box w-100 border">
          <h6 className="mb-4 font-weight-bold">
            <i className="fas fa-clipboard-list fa-2x mr-2 align-middle guidelines-title" />
            Recommended supporting details
          </h6>
          <div dangerouslySetInnerHTML={{ __html: workupChecklists[0].text }} />
        </div>
      )}
    </div>
  );
}

export default WorkupChecklist;
