/**
 * Converts a base64 encoded file into a blob instance
 * @param b64Data string incoming base64 data
 * @param mimeType file mime type, pdf by default
 * @return string blob url
 */
export default function b64ToBlob(b64Data, mimeType = 'application/pdf') {
  const binaryString = window.atob(b64Data);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return new Blob([bytes], { type: mimeType });
}
