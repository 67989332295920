import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExitButton from '../exit-button/ExitButton';
import SaveDraftButton from '../save-draft-button/SaveDraftButton';
import { EhrDocumentContext } from '../../../services/EhrDocumentProvider';
import { postServiceRequestError } from '../../../state';

function FormFooterOptions({ noSpecialistAvailableReason, slaAcknowledge, isLoading }) {
  const dispatch = useDispatch();
  const { isFetchingCoverage } = useSelector((state) => state.coverage);
  const [enabled, isEnabled] = useState(true);

  useEffect(() => {
    isEnabled(!isFetchingCoverage);
  }, [isFetchingCoverage]);

  const { isUploadInProgress } = useContext(EhrDocumentContext);
  return (
    <div className="form-group col-12 mb-4">
      <div className="row">
        <div className="offset-xs-3 offset-sm-3 offset-md-3 offset-lg-3 offset-xl-4 col-12 col-sm-8 col-lg-6 col-xl-9">
          <div className="row">
            <div className="col-12 mt-3 submit-options">
              <button
                onClick={() => dispatch(postServiceRequestError(false))}
                disabled={isUploadInProgress || !enabled || isLoading || (noSpecialistAvailableReason && !slaAcknowledge)}
                type="submit"
                className={`btn ${(isUploadInProgress || !enabled || (noSpecialistAvailableReason && !slaAcknowledge)) ? 'disabled-button' : 'btn-success'} mr-3 d-sm-block d-md-inline`}
                data-auto="request-form-next-button"
              >
                Next: Review
                <i className="fa fa-angle-right ml-2 font-weight-bold" aria-hidden="true" />
                { noSpecialistAvailableReason && !slaAcknowledge
                  && (
                  <i
                    className="fa fa-warning fa-md ml-2"
                    data-toggle="tooltip"
                    data-original-title={noSpecialistAvailableReason}
                    data-placement="right"
                  />
                  )}
              </button>
              <SaveDraftButton disabled={isUploadInProgress || !enabled} buttonId="save-as-draft-footer" />
              <ExitButton disabled={isUploadInProgress || !enabled} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormFooterOptions;
