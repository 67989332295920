import React from 'react';

export default rowData => ({
  columnDefs: [
    {
      sortable: true,
      field: 'date',
      checkboxSelection: true,
      flex: 2,
      minWidth: 200,
      maxWidth: 250,
      sort: 'desc',
      comparator(a, b) {
        if (a === b) {
          return 0;
        }
        return new Date(a) > new Date(b) ? 1 : -1;
      },
    },
    {
      field: 'title',
      wrapText: true,
      autoHeight: true,
      minWidth: 200,
      maxWidth: 250,
      flex:2,
    },
    {
      sortable: true,
      field: 'encounter',
      flex:2,
      minWidth: 200,
      maxWidth: 250,
    },
    {
      field: 'author',
      wrapText: true,
      autoHeight: true,
      flex:2,
      minWidth: 200,
      maxWidth: 250,
    },
    { // This is an non visible row in order to keep the checkbox state
      headerName: '-',
      field: 'isAdded',
      resizable: false,
      hide: true,
      suppressColumnsToolPanel: true,
    },
  ],
  headerHeight: 50,
  rowHeight: 50,
  filter: false,
  singleClickEdit: true,
  pagination: rowData.length > 6,
  floatingFilter: false,
  suppressColumnVirtualisation: true,
  skipHeaderOnAutoSize: false,
  resizable: true,
  suppressRowClickSelection: true,
  rowSelection: 'multiple',
  paginationPageSize: 6,
});
