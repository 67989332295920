import axios from 'axios';
import moment from 'moment';
import { put } from 'redux-saga/effects';
import { supportedDateFormats, validateDate } from '../../../components/form/patient-section/PatientUtils';
import {
  coverage,
  isFetchingCoverage,
} from '../../../state';

export default function* fetchCoverage({ payload }) {
  if (payload.insuranceProvider?.eligible_coverage_lookup_enabled
    && payload.insuranceMemberId && validateDate(payload.dob)) {
    try {
      const formattedDob = moment(payload.dob, supportedDateFormats).format('MM/DD/YYYY');
      yield put(isFetchingCoverage(true));
      const response = yield axios.post(
        '/patients/coverage',
        {
          organization_id: payload.organization.id,
          first_name: payload.firstName,
          last_name: payload.lastName,
          date_of_birth: formattedDob,
          gender: payload.gender,
          payer_name: payload.insuranceProvider?.label,
          payer_id: payload.insuranceProvider?.value,
          member_id: payload.insuranceMemberId,
        },
      );

      if (response.error) {
        yield put(coverage(null));
      } else {
        yield put(coverage(response.data));
      }
    } catch (e) {
      yield put(coverage(null));
    }
    yield put(isFetchingCoverage(false));
  }
}
