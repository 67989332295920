import React, { useState, useEffect, useContext } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { useFormContext } from 'react-hook-form';
import moment from 'moment-timezone';

import { get, isUndefined } from 'lodash';
import PESAxios from '../../../../services/PESAxios';
import AppGrid from '../../../common/app-grid/AppGrid';
import ehrProgressNotesGridOptions from './ag-grid/gridOptions';
import b64ToBlob from '../../../../helpers/b64ToBlob';
import { EhrDocumentContext } from '../../../../services/EhrDocumentProvider';

const EhrProgressNotes = ({ onViewDocument, isActiveTab }) => {
  const { patient } = useSelector((state) => state.patient, shallowEqual);
  const { ehrType } = useSelector((state) => state.organization, shallowEqual);
  const [ehrProgressNotes, setEhrProgressNotes] = useState([]);
  const [notesFetched, setNotesFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpeningNote, setIsOpeningNote] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [visible, setVisible] = useState(true);
  const { getValues } = useFormContext();
  const context = useContext(EhrDocumentContext);
  const onDismiss = () => setVisible(!visible);

  const parseProgressNotes = ({ resource }) => {
    // eslint-disable-next-line max-len
    // PILOT-12510: There are some data issue scenarios where the encounter or author are not present
    const encounter = get(resource, 'context.encounter[0].identifier.value', '');
    const author = get(resource, 'author[0].display', 'Unknown');
    // eslint-disable-next-line max-len
    // Per PILOT-12784: For Epic, we need to add patient information header to the visit note/encounter
    const binary = ehrType === 'epic' ? `DocumentReference/${resource.id}?_pretty=true&patientIdentifier=${patient.mrn}`
      : resource.content[0].attachment.url;
    return {
      date: moment(resource.date).format('MM/DD/YYYY'),
      title: resource.category[0].text,
      encounter,
      author,
      id: resource.id,
      binary,
      source: 'EHR',
      ehrType
    };
  };


  const fetchEhrProgressNotes = async () => {
    setErrorMessage(null);
    onDismiss();
    try {
      const response = await PESAxios().post('/fhir/r4/DocumentReference/_search', {
        category: 'clinical-note',
        patient: patient.ehrFHIRId,
      }, {
        headers: {
          site: patient.organization_id,
        },
      });
      if (response.data.entry[0]?.resource?.resourceType !== 'OperationOutcome') {
        setEhrProgressNotes(response.data.entry.map(parseProgressNotes));
      }
    } catch (e) {
      setErrorMessage('There was a problem when trying to connect to the EHR. Please contact Support or try again.');
    }
    setIsLoading(false);
    setNotesFetched(false);
  };

  useEffect(() => {
    if (patient?.ehrFHIRId && ehrProgressNotes.length === 0 && isActiveTab && !notesFetched) {
      fetchEhrProgressNotes();
      setNotesFetched(true);
    } else if (isUndefined(patient?.ehrFHIRId)) {
      setIsLoading(false);
    }
  }, [ehrProgressNotes, isActiveTab]);


  const openEhrProgressNote = async (event) => {
    const { binary, title, date } = event.data;
    if (event.colDef.field === 'title') {
      onDismiss();
      setErrorMessage(null);
      let data = context.documentCache.current[binary];
      if (!data) {
        setIsOpeningNote(true);
        event.api.showLoadingOverlay();
        try {
          const binaryData = await PESAxios().get(
            `/fhir/r4/${binary}`,
            { headers: { site: patient.organization_id } },
          );
          data = ehrType === 'epic' ? binaryData.data.content[0].attachment.data : binaryData.data.data;
          context.documentCache.current[binary] = data;
        } catch (e) {
          setErrorMessage('There was a problem when trying to pull this document from the EHR. Please contact Support or try again.');
        }
        event.api.hideOverlay();
        setIsOpeningNote(false);
      }
      if (data) {
        const urlPreview = `/pdf-viewer/viewer.html?file=${URL.createObjectURL(b64ToBlob(data))}`;
        onViewDocument({ name: `${title} - ${date}`, urlPreview });
      }
    }
  };

  const gridOptions = ehrProgressNotesGridOptions(ehrProgressNotes);

  const onSelectionChanged = selection => context.setEhrDocuments(selection, 'EHR');

  /**
   * Set the rows as selected if the are already attached, when the grid is ready.
   * @param param
   */
  const setCurrentUploadedAssetsAsSelected = (param) => {
    const currentDocuments = getValues().documents || [];
    const currentEhrDocuments = currentDocuments.filter(d => d.source === 'EHR');
    if (currentEhrDocuments.length === 0) {
      return;
    }
    // Set the row as selected if the doc is already attached
    param.api.forEachNode((rowNode) => {
      const exist = currentDocuments.some(current => current.ehrId === rowNode.data.id);
      if (exist) {
        rowNode.setSelected(true);
      }
    });
  };

  return (
    <>
      <div>
        { (isLoading || isOpeningNote) ? (
          <div className="alert alert-primary my-2" role="alert">
            Loading ...
          </div>
        ) : false}
        { errorMessage ? (
          <Alert color="danger" isOpen={visible} toggle={onDismiss}>
            {errorMessage}
          </Alert>
        ) : false}
      </div>
      <div>
        {
          !isLoading ? (
            <div className="ehr-progress-notes-container">
              <AppGrid
                onCellClicked={openEhrProgressNote}
                gridOptions={gridOptions}
                rowData={ehrProgressNotes}
                onRowClick={onSelectionChanged}
                sendApiToParent={(params) => {
                  setCurrentUploadedAssetsAsSelected(params);
                }}
                overlayLoadingTemplate="ㅤ"
              />
            </div>
          ) : false
        }
      </div>
    </>
  );
};

EhrProgressNotes.propTypes = {
  onViewDocument: PropTypes.func.isRequired,
  isActiveTab: PropTypes.bool.isRequired,
};


export default EhrProgressNotes;
