import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

export default () => {
  const isContextError = useSelector(state => state.user.isContextError);
  const {
    isPatientContextError,
    patientContextErrorMessage,
  } = useSelector(state => state.patient, shallowEqual);

  let status = 'Loading...';
  let loading = true;
  if (isContextError) {
    status = 'Failed to load context.';
    loading = false;
  } else if (isPatientContextError) {
    status = patientContextErrorMessage || 'Failed to load patient context.';
    loading = false;
  }

  return (
    <div className="loading-wrapper">
      <div className="loading">
        <p className="font-weight-bold">{status}</p>
        { loading ?
          <Spinner />
          : null }
      </div>
    </div>
  );
};
