import React from 'react';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { Button } from 'reactstrap';
import EnvironmentSettings from '../../../services/EnvironmentSettings';
import CoreApp from '../../../services/CoreApp';
import ServiceLogModal from '../service-logs/service-log-modal/ServiceLogModal';
import { trackEvent } from '../../../services/AnalyticsService';
import {goToOpenEConsults} from "../../../services/NextApp";

function ExitButton({ disabled }) {
  const { serviceRequestDraftData } = useSelector((state) => state.serviceRequest, shallowEqual);
  const { organizations } = useSelector(
    (state) => state.organization, shallowEqual,
  );
  const { specialties } = useSelector(
    (state) => state.specialty, shallowEqual,
  );
  const { chiefComplaints } = useSelector(
    (state) => state.chiefComplaint, shallowEqual,
  );
  const { appIframeSource } = useSelector((state) => state.app, shallowEqual)
  const exitToCore = () => {
    if (serviceRequestDraftData) {
      // eslint-disable-next-line no-undef
      $('#cancelLogModal').modal('show');
    } else {
      if (appIframeSource === 'app') {
        window.location.href = CoreApp.defaultUrl();
      } else {
        goToOpenEConsults('econsult_cancel');
      }
    }
  };

  const onCancelRequest = async (LogData) => {
    await axios.put(`${EnvironmentSettings.fhirApiUrl}/ServiceRequest/${serviceRequestDraftData.fhirID}`, { status: 'revoked' });
    await axios.post(
      `/econsults/${serviceRequestDraftData.id}/events`,
      {
        action: 'cancel_submission',
        service_message: LogData,
        service_start_time: moment(),
        service_end_time: moment().add(642, 'seconds'),
      },
    );
    trackEvent('Cancel Request', {
      specialty_id: serviceRequestDraftData.specialtySelector,
      specialty_name: specialties.find(
        (specialty) => specialty.value === serviceRequestDraftData.specialtySelector,
      )?.label,
      site_name: organizations.find(
        // ids come as integers, siteSelector comes as string
        // eslint-disable-next-line eqeqeq
        (organization) => organization.id == serviceRequestDraftData.siteSelector,
      )?.name,
      site_id: serviceRequestDraftData.siteSelector,
      chief_complaint_id: serviceRequestDraftData.chiefComplaintSelector,
      chief_complaint_name: chiefComplaints?.find(
        (chiefComplaint) => chiefComplaint.value === serviceRequestDraftData.chiefComplaintSelector,
      )?.label,
    // Once event is tracked close the modal and redirect user
    }, () => {
      // eslint-disable-next-line no-undef
      $('#cancelLogModal').modal('hide');
      if (appIframeSource === 'app') {
        window.location.href = CoreApp.defaultUrl();
      } else {
        goToOpenEConsults('econsult_cancel');
      }
    });
    return null;
  };

  return (
    <>
      <ServiceLogModal modalId="cancelLogModal" modalTitle="Cancel eConsult request" modalButton="Cancel request" confirmButton={onCancelRequest} />
      <Button
        disabled={disabled}
        type="button"
        color="light"
        onClick={exitToCore}
        data-auto="close-request-form-button"
      >
        Cancel Request
      </Button>
    </>
  );
}

ExitButton.propTypes = {
  disabled: PropTypes.bool,
};

ExitButton.defaultProps = {
  disabled: false,
};

export default ExitButton;
