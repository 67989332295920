import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import CoreApp from '../../services/CoreApp';
import {goToOpenEConsults} from "../../services/NextApp";


/**
 * Link for eConsults or Patient {ID} if we have patient context
 * @returns {JSX.Element}
 * @constructor
 */
function EConsultsBreadcrumbLink() {
  const { ehr_patient_context_id: ehrPatientContextId } = useSelector(state => state.user.context);
  const { patientContextId } = useSelector(state => state.patient);
  const { patient } = useSelector(state => state.patient, shallowEqual);
  const { appIframeSource } = useSelector((state) => state.app, shallowEqual)

  let url;
  let breadcrumb;
  // If we have patient context, display patient breadcrumb
  if (ehrPatientContextId) {
    url = CoreApp.patientContextUrl();
    breadcrumb = patient?.mrn ? `Patient ${patient.mrn}` : 'Patient';
  } else if (patientContextId) {
    // If we are comming from patient profile, display patient breadcrumb
    url = CoreApp.patientProfileUrl(patientContextId);
    breadcrumb = `Patient ${patientContextId}`;
  } else {
    url = CoreApp.econsultsUrl();
    breadcrumb = 'eConsults';
  }

  return <>{appIframeSource === 'app' ? <a href={url}>{breadcrumb}</a> : <a href="#!" onClick={goToOpenEConsults}>eConsults</a>}</>;
}

export default EConsultsBreadcrumbLink;
