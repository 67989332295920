import EnvironmentSettings from './EnvironmentSettings';

export const goToOpenEConsults = (status = null, econsultId = null) => window.parent.postMessage({
  transition: 'next-go-to-econsults-open',
  status,
  econsultId,
}, window.location.origin.replace('request', 'next'));

export const goToAllEConsults = (status = null, econsultId = null) => window.parent.postMessage({
  transition: 'next-go-to-econsults-all',
  status,
  econsultId,
}, window.location.origin.replace('request', 'next'));

export const eConsultShowLink = (econsultId) => `https://next${EnvironmentSettings.domainServer}/o/econsults/${econsultId}`;

export const eConsultEditLink = (fhirId) => `https://next${EnvironmentSettings.domainServer}/o/econsults/${fhirId}/edit`;
