import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import {
  connect,
  useSelector,
  shallowEqual,
  useDispatch,
} from 'react-redux';
import RequestedBy from './requested-by-section/RequestedBy';
import RequestReason from './request-reason-section/RequestReason';
import ExitButton from '../common/exit-button/ExitButton';
import SaveDraftButton from '../common/save-draft-button/SaveDraftButton';
import PatientSearch from './patient-section/PatientSearch';
import store, { history } from '../../store';
import LogNotes from '../common/service-logs/LogNotes';

import {
  fetchOrganizations,
  fetchSpecialties,
  isPatientSearched,
  selectOrganizations,
  selectIsFetchingOrganizations,
  selectUserOrganizationName,
  addFieldsRefs,
  fetchServiceRequest,
  setMRNRequired,
  fetchInsuranceProviders,
} from '../../state';

import { formatDateTimezone } from '../../utils';
import { isReimbursementEnabled } from '../../helpers/CoverageHelper';
import RequestError from '../common/request-error/RequestError';

const MemoizedRequestReason = React.memo(RequestReason);
const emptyErrorMessage = 'This field is required';

/**
 * Form Container
 * We initialize form object and default values here
*/
function Form(props) {
  const dispatch = useDispatch();
  const {
    params,
    organizations,
    isFetchingOrganizations,
    organizationSelected,
  } = props;
  const request = history.location?.state;
  const MemoRequestedBy = React.useMemo(
    () => (
      <RequestedBy
        request={request}
        organizations={organizations?.map((item) => ({
          value: item.id,
          label: item.name,
          partOf: item.partOf,
          hasEhrEnabled: item.has_ehr_config,
          ehrType: item.ehr_type,
        }))}
      />
    ),
    [organizations],
  );

  const {
    serviceRequestDraftData,
    isFetchingRequest,
    postServiceRequestError,
  } = useSelector((state) => state.serviceRequest, shallowEqual);
  const { user } = useSelector((state) => state.user, shallowEqual);
  const {
    patient,
    isPatientSearched: isPatientSearchedSelector,
    isMRNRequired: isMRNRequiredSelector,
  } = useSelector((state) => state.patient, shallowEqual);
  const { ehr_patient_context_id: ehrPatientContextId } = useSelector((state) => state.user.context);
  const ehrReferralOrderId = useSelector((state) => state.user.context.smart?.referral_order_id);
  const ehrReferralProcedureId = useSelector((state) => state.user.context.smart?.referral_procedure_id);
  const ehrReferralProcedureName = useSelector((state) => state.user.context.smart?.referral_procedure_name);
  const { insuranceProviders, isFetchingInsuranceProviders } = useSelector((state) => state.coverage);
  const { appIframeSource } = useSelector((state) => state.app, shallowEqual);
  const methods = useForm({
    defaultValues: {
      ehrPatientContextId: request ? request.ehrPatientContextId : '',
      ehrReferralOrderId: request ? request.ehrReferralOrderId : '',
      ehrReferralProcedureId: request ? request.ehrReferralProcedureId : '',
      ehrReferralProcedureName: request ? request.ehrReferralProcedureName : '',
      siteSelector: request ? request.siteSelector : '',
      providerSelector: request ? request.providerSelector : '',
      patientId: request ? request.patientId : '',
      patientFHIRId: request ? request.patientFHIRId : '',
      firstName: request ? request.firstName : '',
      lastName: request ? request.lastName : '',
      dob: request ? request.dob : '',
      gender: request ? request.gender : '',
      street_1: request ? request.street_1 : '',
      street_2: request ? request.street_2 : '',
      city: request ? request.city : '',
      state: request ? request.state : '',
      zip: request ? request.zip : '',
      insuranceProvider: request ? request.insuranceProvider : '',
      insuranceProviderText: request ? request.insuranceProviderText : '',
      insuranceMemberId: request ? request.insuranceMemberId : '',
      fhirID: request ? request.fhirID : '',
      preferralId: '',
      patientSearch: request ? request.patientSearch : '',
      patientSystem: request ? request.patientSystem : '',
      specialtySelector: request ? request.specialtySelector : '',
      epicEncounterCSN: request ? request.epicEncounterCSN : '',
      chiefComplaintSelector: request ? request.chiefComplaintSelector : '',
      textEditor: request ? request.textEditor : '',
      documents: request ? request.documents : [],
      organization: '',
      otherText: request ? request.otherText : '',
      mrnManualEntry: request?.mrnManualEntry,
      slaAcknowledge: request ? request?.slaAcknowledge : false,
      patientConsent: request ? request?.patientConsent : null,
    },
    mode: 'onBlur',
  });

  const [isDisabled, setIsDisabled] = useState(true);

  const goToReviewPage = () => {
    history.push(
      '/econsults/create-request/new/review',
      methods.getValues(),
    );
  };

  /**
   * Run effect on first render
   * Register form inputs and apply validation rules
   */
  useEffect(() => {
    methods.register({ name: 'ehrPatientContextId' });
    methods.register({ name: 'documents' });
    methods.register({ name: 'organization' });
    methods.register({ name: 'patientId' });
    methods.register({ name: 'patientSystem' });
    methods.register({ name: 'patientFHIRId' });
    methods.register({ name: 'fhirID' });
    methods.register({ name: 'providerSelector' }, { required: emptyErrorMessage });
    methods.register({ name: 'ehrReferralOrderId' });
    methods.register({ name: 'ehrReferralProcedureId' });
    methods.register({ name: 'ehrReferralProcedureName' });
    methods.register({ name: 'mrnManualEntry' });
    methods.register({ name: 'preferralId' });
    methods.register({ name: 'slaAcknowledge' });
  }, []);

  /**
   * Run effect on first render and if organizations change
   * Register siteSelector input and validation
   */
  useEffect(() => {
    // Fix for FORM-263
    const errorMessage = `Please contact AristaMD Support.
     eConsults cannot be submitted due to your Site configuration.`;
    if (organizations?.length === 1) {
      methods.register({ name: 'siteSelector' }, {
        validate: (value) => Boolean(value.partOf) || errorMessage,
      });
    } else {
      methods.register({ name: 'siteSelector' }, {
        required: emptyErrorMessage,
        validate: (value) => Boolean(value.partOf) || errorMessage,
      });
    }
  }, [organizations]);

  /**
   * Run effect on first render and if the user's selected organization changes
   */
  useEffect(() => {
    if (organizationSelected) {
      methods.setValue('organization', organizationSelected);

      // Load organization's sites for the site selector
      if (!organizations && !isFetchingOrganizations) {
        dispatch(fetchOrganizations(organizationSelected.id));
      }

      const insuranceProvidersEnabled = isReimbursementEnabled(user?.organization);
      if (insuranceProvidersEnabled && !insuranceProviders && !isFetchingInsuranceProviders) {
        dispatch(fetchInsuranceProviders(organizationSelected.id));
      }
    }
  }, [organizationSelected]);

  /**
   * Run effect on first render
   * Load eConsult draft or set patient context
   */
  useEffect(() => {
    // Don't do anything if we already have request data present
    if (serviceRequestDraftData || request) {
      return;
    }

    // Check for request ID param in the URL (coming from web-app, emails)
    if (params.id) {
      // load eConsult into serviceRequestDraftData
      dispatch(fetchServiceRequest(params.id));
      return;
    }

    // Check for referral order ID
    if (ehrReferralOrderId) {
      methods.setValue('ehrReferralOrderId', ehrReferralOrderId);
    }
    // Check for referral procedure ID
    if (ehrReferralProcedureId) {
      methods.setValue('ehrReferralProcedureId', ehrReferralProcedureId);
    }
    // Check for referral procedure name
    if (ehrReferralProcedureName) {
      methods.setValue('ehrReferralProcedureName', ehrReferralProcedureName);
    }
  }, []);

  /**
   * Run effect on first render and if ehrPatientContextId or patient is loaded
   * Set form ehrPatientContextId value and patient is searched
   * This is required to lock fields and disable edit in the patient form
   */
  useEffect(() => {
    if (ehrPatientContextId && patient) {
      methods.setValue('ehrPatientContextId', ehrPatientContextId);
      dispatch(isPatientSearched(true, true));
    }
  }, [ehrPatientContextId, patient]);

  /**
   * Run effect on first render and if service request draft data is completely loaded
   * Set FHIR ID and attached documents
   */
  useEffect(() => {
    if (serviceRequestDraftData && !isFetchingRequest) {
      methods.setValue('fhirID', serviceRequestDraftData.fhirID);
      methods.setValue('ehrReferralOrderId', serviceRequestDraftData.ehrReferralOrderId);
      methods.setValue('ehrReferralProcedureId', serviceRequestDraftData.ehrReferralProcedureId);
      methods.setValue('ehrReferralProcedureName', serviceRequestDraftData.ehrReferralProcedureName);
      methods.setValue('documents', request ? request.documents : serviceRequestDraftData.documents);
    }
  }, [serviceRequestDraftData, isFetchingRequest]);

  const sendFieldToFocus = (fieldError) => {
    store.dispatch(addFieldsRefs(fieldError));
  };

  useEffect(() => {
    /* FORM-277
    * Validate which field should be focus
    * we should focus in order of which is first on the UI
    */
    if (methods?.errors) {
      const errorsArray = Object.keys(methods.errors);
      if (errorsArray?.length >= 1) {
        if (errorsArray.includes('siteSelector')) {
          sendFieldToFocus('siteSelector');
        } else if (errorsArray.includes('providerSelector')) {
          sendFieldToFocus('providerSelector');
        } else if (errorsArray.includes('patientSearch') || isMRNRequiredSelector) {
          sendFieldToFocus('patientSearch');
        } else if (errorsArray.includes('firstName')) {
          sendFieldToFocus('firstName');
        } else if (errorsArray.includes('lastName')) {
          sendFieldToFocus('lastName');
        } else if (errorsArray.includes('gender')) {
          sendFieldToFocus('gender');
        } else if (errorsArray.includes('dob')) {
          sendFieldToFocus('dob');
        } else if (errorsArray.includes('street_1')) {
          sendFieldToFocus('street_1');
        } else if (errorsArray.includes('street_2')) {
          sendFieldToFocus('street_2');
        } else if (errorsArray.includes('city')) {
          sendFieldToFocus('city');
        } else if (errorsArray.includes('state')) {
          sendFieldToFocus('state');
        } else if (errorsArray.includes('zip')) {
          sendFieldToFocus('zip');
        } else if (errorsArray.includes('insuranceProvider')) {
          sendFieldToFocus('insuranceProvider');
        } else if (errorsArray.includes('insuranceMemberId')) {
          sendFieldToFocus('insuranceMemberId');
        } else if (errorsArray.includes('epicEncounterCSN')) {
          sendFieldToFocus('epicEncounterCSN');
        } else if (errorsArray.includes('specialtySelector')) {
          sendFieldToFocus('specialtySelector');
        } else if (errorsArray.includes('chiefComplaintSelector')) {
          sendFieldToFocus('chiefComplaintSelector');
        } else if (errorsArray.includes('otherText')) {
          sendFieldToFocus('otherText');
        } else if (errorsArray.includes('patientConsent')) {
          sendFieldToFocus('patientConsent');
        } else {
          sendFieldToFocus('textEditor');
        }
      }
    }
  }, [methods.errors, isMRNRequiredSelector]);

  useEffect(() => {
    setIsDisabled(!methods.getValues().patientSearch && !isPatientSearchedSelector.isSearched);
  }, [methods.getValues().patientSearch, isPatientSearchedSelector.isSearched]);

  const onSubmit = () => {
    // if there is no mrn
    if (!methods.getValues().patientSearch || !isPatientSearchedSelector.isSearched) {
      sendFieldToFocus('patientSearch');
      dispatch(setMRNRequired(true));
      return;
    }

    goToReviewPage();
  };

  return (
    <>
      <div data-auto="form-header-auto" className="row container options-menu mb-3 mt-3">
        <div className="col-xs-8 col-sm-5">
          { appIframeSource === 'app'
            && <h4 className="font-weight-bold new-request-title">New eConsult Request</h4>}
        </div>
        <div className="col-12 col-sm-7 text-xs-left text-sm-right">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FormProvider {...methods}>
            <SaveDraftButton disabled={!isPatientSearchedSelector.isSearched} buttonId="save-as-draft-header" />
          </FormProvider>
          <ExitButton />
        </div>
        { serviceRequestDraftData && !postServiceRequestError
        && (
        <div className="col-sm-12 pl-3 p-0 mt-2">
          <div className="alert alert-info mb-0" role="alert" data-auto="request-confirmation">
            <span className="">
              { 'Last Saved: ' }
              {formatDateTimezone(serviceRequestDraftData.updated_at, user?.timezone)}
              { ' by ' }
              {serviceRequestDraftData.last_edit_by_id}
            </span>
            <br />
            <LogNotes user={user} />
          </div>
        </div>
        ) }
        <RequestError />
      </div>
      <div className="form-container container">
        <FormProvider {...methods}>
          <form className="mb-5" data-auto="request-form" onSubmit={methods.handleSubmit(onSubmit)}>
            {MemoRequestedBy}
            <PatientSearch request={request} params={params} />
            <MemoizedRequestReason request={request} isDisabled={isDisabled} />
          </form>
        </FormProvider>
      </div>
    </>
  );
}

const {
  any,
  arrayOf,
  bool,
} = PropTypes;

Form.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
  organizations: arrayOf(any),
  // eslint-disable-next-line react/forbid-prop-types
  organizationSelected: any,
  isFetchingOrganizations: bool,
};

Form.defaultProps = {
  params: {
    id: null,
  },
  organizations: [],
  organizationSelected: null,
  isFetchingOrganizations: false,
};

const mapStateToProps = (state) => ({
  organizations: selectOrganizations(state),
  isFetchingOrganizations: selectIsFetchingOrganizations(state),
  organizationSelected: selectUserOrganizationName(state),
});

export default connect(mapStateToProps)(Form);
