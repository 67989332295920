/**
 * Check if organization reimbursement type settings is different than organization pays all
 * If so, we need to enable the insurance providers field
 * @returns {boolean}
 */
export const checkReimbursementType = reimbursementType => reimbursementType &&
  ['insurance_pays', 'organization_or_insurance_pays'].includes(reimbursementType);

/**
 * Check if organization insurance reimbursement is enabled
 * @param organization
 * @returns {boolean}
 */
export const isReimbursementEnabled = organization => organization?.settings.request_insurance_reimbursement_enabled || false;

export const isInsuranceCollectionEnabled = organization => organization?.settings.request_insurance_collection_enabled || false;
