import moment from 'moment-timezone';
import { isEmpty } from 'lodash';

/**
 * Returns supported video file types
 */
export const SUPPORTED_VIDEO_TYPES = ['video/mp4', 'video/mov', 'video/quicktime'];

// this should be removed we should use data-auto instead
export const findByTestAttr = (component, attr) => component.find(`[data-aut='${attr}']`);

export const findByDataAttr = (component, attr) => component.find(`[data-auto='${attr}']`);
/**
 * @param {string} timezone
 * @return {string}
 * Todo get the timezone from the user
 */
export const timeZoneAbbr = () => moment.tz('America/Los_Angeles').format('z');

/**
 * @param {string} timezone
 * @return {string}
 * Todo get the timezone from the user
 */
export const timeZoneOffset = () => moment.tz('America/Los_Angeles').format('Z');

/**
 * Returns the formatted URL for the thumbnails
 * @param {string} assetId
 * @return {string} formatted url
 */

export const assetThumbnailUrl = (assetId) => `/assets/${assetId}/thumbnail`;

/**
 * Returns the formatted URL for the iframe to visualize the attachment
 * @param {string} assetId
 * @return {string} formatted url
 */

export const assetPreviewUrl = (assetId) => `/pdf-viewer/viewer.html?file=../assets/${assetId}/preview`;

/**
 * Returns the formatted URL for the dicom to visualize the attachment
 * @param {string} assetId
 * @return {string} formatted url
 */
export const assetUrl = (assetId) => `/assets/${assetId}`;

/**
 * Returns true if file type matches one of the supported formats (video/mp4, video/mov, video/quicktime)
 * @param {string} fileType
 * @return {boolean}
 */
export const isSupportedVideoFile = (fileType) => SUPPORTED_VIDEO_TYPES.includes(fileType);

/**
 * Returns whether a patient is pediatric or not. The input parameter is a date string
 * @param {string} dob
 * @returns {boolean}
 */
export const isPediatric = (dob) => moment().diff(moment(dob, 'MM-DD-YYYY'), 'years') < 18;

/**
 * Format a string date to match the mask format: 'MM/DD/YYYY'
 * @param date
 * @returns {*|moment.Moment}
 */
export const formatDate = (date) => moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY');

/**
 * Format a string date to match the mask format: 'MM/DD/YYYY'
 * @param date
 * @returns {*|moment.Moment}
 */
export const formatDateDay = (date) => moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD');

/**
 * Format a date string to local time
 * @param date
 * @returns {string}
 */
export const formatDateTimezone = (date, timezone) => moment.utc(date).tz(timezone).format('M/D/YYYY, h:mma z');

/**
 * Set callback value
 * @param value
 * @param callback
 */
export const setCallbackValue = (callback, value) => {
  if (callback) {
    callback(value);
  }
};

/**
 * Sort Algorithm by label
 * @param  a
 * @param  b
 * @return number
 */
export const sortByWithoutOther = (a, b) => {
  if (a.label === 'Other') {
    return 1;
  }
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  // a equals b
  return 0;
};

/**
 * Find item in array with the given attribute equal to the given value
 * @param {array} array
 * @param {string} attribute
 * @param value
 * @returns {object|undefined}
 */
export const findByAttributeValue = (array, attribute, value) => {
  return array.find((item) => item[attribute] === value);
};

/**
 * Filter option of an array by a given input
 * @param inputValue
 * @param object
 */
export const filterOptions = (inputValue, object) => {
  if (!isEmpty(object)) {
    return object.filter((i) =>
      i.label?.toLowerCase().includes(inputValue.toLowerCase()));
  }
  return [];
};

/**
 * Filter option of an array by a given input
 * @param inputValue
 * @param object
 * @returns {*[]|*}
 */
export const filterOptionsSpecialities = (inputValue, object) => {
  if (!isEmpty(object)) {
    const value = inputValue.toLowerCase();
    return object.filter(
      (i) => i.label?.toLowerCase().includes(value) || i.keywords?.join().includes(value)
    );
  }
  return [];
};

/**
 * Checks for demographic info required
 * @param Object address
 * @param Object name
 * @param birthDate string
 * @param gender string
 * @returns boolean
 */
export const validateDemographics = ({
  address,
  birthDate,
  gender,
  name,
}) => {
  let demographicFlag = true;
  const addressFieldsToValidate = ['state', 'city', 'postalCode', 'line'];
  if (!gender || !birthDate || name?.length < 1 || !name[0]?.family || !name[0]?.given[0]) {
    demographicFlag = false;
  }
  if (address?.length >= 1) {
    addressFieldsToValidate.forEach((fieldtoValidate) => {
      if (!address[0][fieldtoValidate]) {
        demographicFlag = false;
      }
    });
  }
  return demographicFlag;
};
