import axios from 'axios';
import { put } from 'redux-saga/effects';

import {
  states,
  isFetchingStates,
} from '../../state';

/**
 * Load US states
 */
export default function* fetchStates() {
  yield put(isFetchingStates(true));
  try {
    const response = yield axios.get('/states');
    yield put(states(response.data.filter((s) => s.code !== '**')));
  } catch (e) {
    yield put(states([]));
  }
  // Restore original state when user is fetched
  yield put(isFetchingStates(false));
}
