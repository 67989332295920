import axios from 'axios';
import EnvironmentSettings from '../../../../services/EnvironmentSettings';

/**
 * Post the attachments and associate them to the request and the patient
 * @param file file object
 * @param fhirPatientID
 * @param documents
 * @param status
 * @return {Generator<*, *&{reference: string}, *>}
 */
export default function* documentsLoop(file, fhirPatientID, documents, status) {
  // Call Document Reference for each document attached so we assign them to the patients
  // and return the fhir id
  const documentReference = {
    resourceType: 'DocumentReference',
    docStatus: status === 'active' ? 'final' : 'preliminary',
    status: 'current',
    id: file.fhir_id,
    identifier: [
      {
        use: 'usual',
        system: (file.source === 'aristamd_print_queue' ? 'aristamd_print_queue_id' : 'aristamd_internal_id'),
        value: (file.source === 'aristamd_print_queue' ? file.name : file.id),
      },
    ],
    subject: {
      reference: `Patient/${fhirPatientID}`,
    },
    content: [
      {
        attachment: {
          title: file.name,
          data: '',
        },
      },
    ],
  };
  if (file.ehrId) {
    documentReference.identifier.push({ system: 'EHR', value: file.ehrId });
  }

  const assetObject = yield axios[documentReference.id ? 'put' : 'post'](
    `${EnvironmentSettings.fhirApiUrl}/DocumentReference`, documentReference,
  );
  return { reference: `DocumentReference/${assetObject.data.id}`, ...documents };
}
