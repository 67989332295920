import createReducer from './createReducer';

/** ********************************************
 * constants
 ******************************************** */

export const APP_READY = 'app/APP_READY';
export const APP_IFRAME_SOURCE = 'app/APP_IFRAME_SOURCE'

/** ********************************************
 * reducer
 ******************************************** */

export const initialAppState = {
  isAppReady: false,
  appIframeSource: 'app'
};

export default createReducer(initialAppState, {
  [APP_READY]: (state, { payload }) => ({ isAppReady: payload }),
  [APP_IFRAME_SOURCE]: (state, { payload }) => ({ appIframeSource: payload }),
});

/** ********************************************
 * actions
 ******************************************** */

/**
 *
 * @param {boolean} bool
 * @return {{type: string, payload: *}}
 */
export const setIsAppReady = bool => ({
  type: APP_READY,
  payload: bool,
});

/**
 *
 * @param {string} source
 * @return {{type: string, payload: *}}
 */
export const setAppIframeSource = source => ({
  type: APP_IFRAME_SOURCE,
  payload: source,
});

/** ********************************************
 * selectors
 ******************************************** */

/**
 * @param state
 * @return {boolean}
 */
export const selectIsAppReady = state => state.app.isAppReady;

/**
 * @param state
 * @return {string}
 */
export const selectAppIframeSource = state => state.app.appIframeSource;
