import createReducer from '../createReducer';

/** ********************************************************
 * CONSTANTS
 ******************************************************** */

export const FETCH_ASSET_PREVIEW_STATUS = 'asset/FETCH_ASSET_PREVIEW_STATUS';
export const FETCHING_ASSET_PREVIEW_STATUS = 'asset/FETCHING_ASSET_PREVIEW_STATUS';
export const IS_ASSET_PREVIEW_AVAILABLE = 'asset/IS_ASSET_PREVIEW_AVAILABLE';

/** ********************************************************
 * REDUCER
 ******************************************************** */

export const initialStateAsset = {
  // Assets is an object of objects with key asset id
  // 62f52a5fb7191: {
  //   isFetchingPreviewStatus: boolean,
  //   isPreviewAvailable: boolean,
  // }
  assets: {},
};

export default createReducer(initialStateAsset, {
  [FETCHING_ASSET_PREVIEW_STATUS]: (state, { payload }) => ({
    ...state,
    assets: {
      ...state.assets,
      [payload.assetId]: {
        ...state.assets[payload.assetId],
        isFetchingPreviewStatus: payload.bool,
      },
    },
  }),
  [IS_ASSET_PREVIEW_AVAILABLE]: (state, { payload }) => ({
    ...state,
    assets: {
      ...state.assets,
      [payload.assetId]: {
        ...state.assets[payload.assetId],
        isPreviewAvailable: payload.bool,
      },
    },
  }),
});

/** ********************************************************
 * ACTIONS
 ******************************************************** */

/**
 * Sets if asset preview status is currently being fetched
 * @param {string} assetId
 * @param {boolean} bool
 * @returns {{type: string, payload: object}}
 */
export const isFetchingAssetPreviewStatus = (assetId, bool) => ({
  type: FETCHING_ASSET_PREVIEW_STATUS,
  payload: { assetId, bool },
});

/**
 * Fetches the asset preview status
 * @param {string} assetId
 * @returns {{type: string, payload: string}}
 */
export const fetchAssetPreviewStatus = (assetId) => ({
  type: FETCH_ASSET_PREVIEW_STATUS,
  payload: assetId,
});

/**
 * Sets asset isAssetPreviewAvailable property
 * @param {string} assetId
 * @param {boolean} bool
 * @returns {{type: string, payload: object}}
 */
export const isAssetPreviewAvailable = (assetId, bool) => ({
  type: IS_ASSET_PREVIEW_AVAILABLE,
  payload: { assetId, bool },
});
