// Dynamic breadcrumb (see https://github.com/icd2k3/react-router-breadcrumbs-hoc)
// routes = [{ path: '/contract/:id', breadcrumb: RequestBreadcrumb }]
// request.aristamd.com/econsults/123 --> Request / AristaMD Request

const RequestBreadcrumb = ({ match }) => {
  const id = match.params.id;

  return `C0${id}`;
};

export default RequestBreadcrumb;
