import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { snakeCase } from 'lodash';
import PropTypes from 'prop-types';
import { history } from '../../store';
import ExitButton from '../common/exit-button/ExitButton';
import SaveDraftButtonReview from '../common/save-draft-button/SaveDraftButtonReview';
import { postServiceRequest } from '../../state';
import DocumentsList from '../form/request-reason-section/DocumentsList';
import { trackEvent } from '../../services/AnalyticsService';
import useIsSpecialistOnCall from '../../hooks/useIsSpecialistOnCall';
import { isPediatric } from '../../utils';

/**
 * Reason for request review
*/
function ReasonForRequestReview({ edit, onEdit }) {
  const request = history.location?.state;
  const dispatch = useDispatch();
  const { coverage } = useSelector((state) => state.coverage);
  const { user } = useSelector((state) => state.user, shallowEqual);
  const { isRequestOnProcess } = useSelector((state) => state.serviceRequest, shallowEqual);
  const { patientContextId, patient } = useSelector((state) => state.patient, shallowEqual);
  const isOnCall = useIsSpecialistOnCall(
    snakeCase(request?.specialtySelector?.label),
    patientContextId || patient?.id,
    isPediatric(request?.dob),
    request?.organization?.id, request?.insuranceProvider?.value,
  );

  const onSubmit = () => {
    trackEvent('Submit eConsult', {
      site_name: request.siteSelector.label,
      site_id: request.siteSelector.value,
      provider: request.providerSelector.label,
      gender: request.gender,
      chief_complaint_name: request.chiefComplaintSelector.label,
      specialty_name: request.specialtySelector.label,
      chief_complaint_id: request.chiefComplaintSelector.value,
      specialty_id: request.specialtySelector.value,
    });
    dispatch(postServiceRequest({ ...request, status: 'active', coverage }));
  };

  return (
    <div className="row" data-auto="requested-by-form">
      <div className="col-12 mb-3">
        <h6 className="font-weight-bold border-bottom pb-2 requested-by">Reason for Request</h6>
      </div>
      <div className="col-12 col-lg-12 w-100 pl-0">
        <div className="labels-wrapper d-inline-block">
          {user?.organization?.settings?.request_epic_encounter_csn_enabled ? (
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-sm-5 col-md-4 text-sm-right">
                  <p className="font-weight-bold mb-1 mb-sm-2">CSN</p>
                </div>
                <div className="col-12 col-sm-5 col-md-6 col-xl-6 mb-3 mb-sm-0">
                  <span data-auto="epic_encounter_csn">{request?.epicEncounterCSN}</span>
                </div>
              </div>
            </div>
          ) : null}
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-sm-5 col-md-4 text-sm-right">
                <p className="font-weight-bold mb-1 mb-sm-2">Specialty</p>
              </div>
              <div className="col-12 col-sm-5 col-md-6 col-xl-6 mb-3 mb-sm-0">
                <span data-auto="specialty">
                  {request?.specialtySelector?.label}
                  { !isOnCall ? (
                    <i
                      className="fas fa-exclamation-triangle text-warning d-inline ml-2"
                      data-toggle="tooltip"
                      title="Response time may exceed SLA, please contact support@aristamd.com if you have any questions."
                      data-placement="right"
                    />
                  ) : null }
                </span>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-sm-5 col-md-4 text-sm-right">
                <p className="font-weight-bold mb-1 mb-sm-2">Chief Complaint</p>
              </div>
              <div className="col-12 col-sm-5 col-md-6 col-xl-6 mb-3 mb-sm-0">
                <span data-auto="txt-chief-complaint">{request?.chiefComplaintSelector?.label}</span>
                {request?.chiefComplaintSelector?.label === 'Other'
                  && (
                  <span data-auto="chief_complaint_other_details">
                    -
                    {request?.otherText}
                  </span>
                  )}
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-sm-5 col-md-4 text-sm-right">
                <p className="font-weight-bold mb-1 mb-sm-2">Clinical Question and Supporting Details</p>
              </div>
              <div className="col-12 col-sm-5 col-md-6 col-xl-6 mb-3 mb-sm-0">
                <span data-auto="txt-supporting-details" dangerouslySetInnerHTML={{ __html: request?.textEditor }} />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row mb-4">
              <div className="col-12 col-sm-5 col-md-4 text-sm-right">
                <p className="font-weight-bold mb-1 mb-sm-2">Attachments</p>
              </div>
              <div className="col-12 col-sm-7 col-md-8 col-xl-8 mb-3 mb-sm-0" data-auto="div-attachments-section">
                {
                  request?.documents?.length > 0
                    ? <DocumentsList documents={request.documents} />
                    : <p>-</p>
                }
              </div>
            </div>
          </div>
        </div>
        { edit
        && (
        <div className="col-12 col-sm-1 col-md-2 submit-options d-inline-block pl-3">
          <button
            type="button"
            className="btn btn-light ml-1"
            onClick={() => {
              onEdit({ specialty: true });
            }}
          >
            <i className="fas fa-pencil-alt d-inline mr-1" />
            Edit
          </button>
        </div>
        )}
        { edit
        && (
        <div>
          <div className="submit-wrapper" data-auto="submit-section">
            <div className="offset-sm-2 offset-md-4 col-12 col-sm-12 col-xl-10">
              <div className="row">
                <div className="col-12 mt-3 submit-options">
                  <button type="button" className="btn btn-success mr-3 d-block d-sm-inline" data-auto="request-form-submit-request" onClick={onSubmit} disabled={isRequestOnProcess.showSpinner}>
                    Submit Request
                    <i className="fa fa-angle-right ml-2 font-weight-bold" aria-hidden="true" />
                  </button>
                  <SaveDraftButtonReview />
                  <ExitButton />
                </div>
              </div>
            </div>
          </div>
        </div>
        )}
      </div>
    </div>
  );
}

ReasonForRequestReview.propTypes = {
  edit: PropTypes.bool,
  onEdit: PropTypes.func,
};

ReasonForRequestReview.defaultProps = {
  edit: false,
  onEdit: () => {},
};

export default ReasonForRequestReview;
