import React from 'react';
import { Redirect } from 'react-router';
import Form from '../form/Form';
import ReviewPageContainer from '../review-page/ReviewPageContainer';
import ConfirmationPage from '../confirmation-page/ConfirmationPage';
import RequestBreadcrumb from '../breadcrumbs/RequestBreadcrumb';
import EConsults from '../econsults/EConsults';
// Simple breadcrumbs use string
// Dynamic breadcrumbs use function/component
const routes = [
  {
    exact: true,
    path: '/patient/:id',
    breadcrumb: null,
    component: <Redirect to="/econsults/create-request" />,
  },
  {
    exact: true,
    path: '/econsults',
    breadcrumb: '', // This is a special case, see Breadcrumbs.js
    component: <EConsults />,
  },
  {
    exact: true,
    path: '/econsults/create-request',
    breadcrumb: 'New Request',
    component: <Form />,
  },
  {
    exact: true,
    path: '/econsults/create-request/:id',
    breadcrumb: null,
    component: params => (<Form params={params} />),
  },
  {
    exact: true,
    path: '/econsults/create-request/:id/review',
    breadcrumb: 'Review and Submit',
    component: <ReviewPageContainer />,
  },
  {
    exact: true,
    path: '/',
    breadcrumb: null,
    component: <Redirect to="/econsults/create-request" />,
  },
  {
    exact: true,
    path: '/econsults/:id',
    breadcrumb: null,
  },
  {
    exact: true,
    path: '/econsults/:id/confirmation',
    breadcrumb: RequestBreadcrumb,
    component: <ConfirmationPage />,
  },
];

export default routes;
