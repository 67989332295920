import axios from 'axios';
import { put, all } from 'redux-saga/effects';
import { parsePatient, parseServiceRequest } from 'fhir-parser';
import EnvironmentSettings from '../../../services/EnvironmentSettings';
import { formatDate, assetThumbnailUrl, assetPreviewUrl, assetUrl } from '../../../utils';

import {
  serviceRequestDraftData,
  isFetchingServiceRequest,
  patient,
  serviceLogsData,
  isPatientSearched as setPatientSearched,
} from '../../../state';
import { parsePatientGender } from '../../../helpers/gender';

const urlExtension = 'http://extensions.aristamd.com/documentReference/uploaded_by';

// get the attachments associated to the request
function* documentsLoop(file, documents, patientDocumentsData) {
  const assetObject = yield axios.get(`${EnvironmentSettings.fhirApiUrl}/${file.reference}`);
  const assetId = assetObject.data.identifier.find(id => id.system === 'aristamd_id').value;
  const documentReference = {
    id: assetId,
    fhir_id: assetObject.data.id,
    name: assetObject.data.content[0].attachment.title,
    size: assetObject.data.content[0].attachment.size, // Required to recreate the uploaded file list
    type: assetObject.data.content[0].attachment.contentType, // Required to recreate the uploaded file list
    lastModified: assetObject.data.content[0].attachment.creation, // Required to recreate the uploaded file list
    // FORM-496: EHR documents are selected according to the source
    source: assetObject.data.meta.source,
    thumbnail: `${assetThumbnailUrl(assetId)}?width=200`,
    urlPreview: (assetObject.data.content[0].attachment.contentType === 'application/dicom' || assetObject.data.content[0].attachment.title.split('.').pop() === 'dcm')
      ? assetUrl(assetId) : assetPreviewUrl(assetId),
    patientDocument: !!patientDocumentsData?.find(file => file.id === assetId),
    serviceRequestDocument: true,
  };
  const ehrId = assetObject.data.identifier.find(identifier => identifier.system === 'FHIR')?.value;
  // FORM-496: Only add the ehr id if present
  if (ehrId) {
    documentReference.ehrId = ehrId;
  }
  return {
    ...documentReference,
    ...documents,
  };
}

// get the attachments associated to the patient
function patientDocumentsLoop(file, documents) {
  const assetId = file.resource.identifier.find(id => id.system === 'aristamd_id').value;
  const uploadedBy = file.resource.extension.find(extension => extension.url === urlExtension).valueString;
  const documentReference = {
    id: assetId,
    fhir_id: file.resource.id,
    name: file.resource.content[0].attachment.title,
    size: file.resource.content[0].attachment.size, // Required to recreate the uploaded file list
    type: file.resource.content[0].attachment.contentType, // Required to recreate the uploaded file list
    lastModified: file.resource.content[0].attachment.creation, // Required to recreate the uploaded file list
    thumbnail: `${assetThumbnailUrl(assetId)}?width=200`,
    urlPreview: (file.resource.content[0].attachment.contentType === 'application/dicom' || file.resource.content[0].attachment.title.split('.').pop() === 'dcm')
      ? assetUrl(assetId) : assetPreviewUrl(assetId),
    uploadedBy,
    source: file.resource.meta?.source,
    patientDocument: true,
    serviceRequestDocument: false,
  };
  return {
    ...documentReference,
    ...documents,
  };
}

// get the past econsults associated to the patient
function* serviceRequestLoop(pastEconsult, pastEconsults) {
  const serviceRequest = parseServiceRequest(pastEconsult.resource);
  const pastEconsultData = {
    id: serviceRequest.parsedData[0].id,
    fhir_id: serviceRequest.parsedData[0].fhir_id,
    status: serviceRequest.parsedData[0].status,
    submitted_at: serviceRequest.parsedData[0].submitted_at,
    specialty: serviceRequest.parsedData[0].specialtyText,
    source: serviceRequest.parsedData[0].source,
  };
  return {
    ...pastEconsultData,
    ...pastEconsults,
  };
}

export default function* fetchServiceRequest({ payload }) {
  yield put(isFetchingServiceRequest(true));
  try {
    let response = null;
    let supportingInfoData = null;
    let documents = null;
    let pastEconsults = {};

    const { data } = yield axios.get(`${EnvironmentSettings.fhirApiUrl}/ServiceRequest/${payload}`);
    if (data.status === 'active') {
      const eConsultID = data.identifier.find(id => id.system === 'aristamd_econsult_id');
      window.location.href = `https://app${EnvironmentSettings.domainServer}/#/app/econsults/${eConsultID.value}`;
      return true;
    }

    const { parsedData, error } = parseServiceRequest(data);
    const site = parsedData[0].site.substring(parsedData[0].site.lastIndexOf('/') + 1);

    let request = {
      siteSelector: site,
      id: parsedData[0].id,
      providerSelector: parsedData[0].provider,
      patientId: parsedData[0].patient,
      fhirID: parsedData[0].fhir_id,
      ehrReferralOrderId: parsedData[0].ehr_referral_order_id,
      ehrReferralProcedureId: parsedData[0].ehr_referral_procedure_id,
      ehrReferralProcedureName: parsedData[0].ehr_referral_procedure_name,
      patientSearch: '',
      epicEncounterCSN: parsedData[0].request_epic_encounter_csn,
      specialtySelector: parsedData[0].specialty,
      chiefComplaintSelector: parsedData[0].chief_complaint_id,
      otherText: parsedData[0].chief_complaint_text,
      textEditor: parsedData[0].clinical_question,
      documents: supportingInfoData,
      updated_at: parsedData[0].updated_at,
      last_edit_by_id: parsedData[0].last_edit_by_id,
      patientConsent: parsedData[0].patient_consent
    };

    // get the patient associated to the request
    const patientResponse = yield axios.get(`${EnvironmentSettings.fhirApiUrl}/Patient/${parsedData[0].patient}`);
    const patientFhirID = patientResponse.data.identifier.find(id => id.system === 'aristamd_fhir_id').value;
    const patientDocuments = yield axios.get(`${EnvironmentSettings.fhirApiUrl}/DocumentReference?subject=Patient/${patientFhirID}`);

    if (patientDocuments?.data?.entry?.length > 0) {
      documents = yield all(patientDocuments.data.entry.map(file => patientDocumentsLoop(file, documents)));
    }

    // if there is attachments associated get each of them
    if (parsedData[0].attachments) {
      supportingInfoData = yield all(parsedData[0].attachments.map(file => documentsLoop(file, supportingInfoData, documents)));
      request = { ...request, documents: supportingInfoData };
    }

    const patientServiceRequests = yield axios.get(`${EnvironmentSettings.fhirApiUrl}/ServiceRequest?subject=Patient/${patientFhirID}&_count=5`);

    if (patientServiceRequests?.data?.entry?.length > 0) {
      pastEconsults = yield all(patientServiceRequests.data.entry.map(pastEconsult => serviceRequestLoop(pastEconsult, pastEconsults)));
      // Remove current opened econsult from the pastEconsults
      pastEconsults = pastEconsults.filter(pastEconsult => pastEconsult.fhir_id !== payload);
    }

    // parse patients and update states
    const patientData = parsePatient(patientResponse.data);
    patientData.parsedData[0].dob = formatDate(patientData.parsedData[0].dob);
    patientData.parsedData[0].gender = parsePatientGender(patientData.parsedData[0]);

    yield put(serviceRequestDraftData(request));
    yield put(patient({ ...patientData.parsedData[0], documents, pastEconsults }));

    response = yield axios.get(`${EnvironmentSettings.eConsultApiUrl}/${parsedData[0].id}/service-logs`, {
      validateStatus(status) {
        return status < 400; // Resolve only if the status code is less than 403 forbidden
      },
    }).catch((error) => {
      console.error(error);
    });
    if (response) {
      yield put(serviceLogsData(response.data));
    }
    yield put(setPatientSearched(true, true));
  } catch (e) {
    console.error(e);
    yield put(serviceRequestDraftData('Error'));
    yield put(serviceLogsData(null));
    yield put(setPatientSearched(false));
  }
  // Restore original state when user is fetched
  yield put(isFetchingServiceRequest(false));
}
