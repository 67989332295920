import React, { useState, useEffect, useRef } from 'react';
import AsyncSelect from 'react-select/async';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css';
import { useFormContext } from 'react-hook-form';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import axios from 'axios';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} from 'reactstrap';
import moment from 'moment';
import AddDocuments from './AddDocuments';
import FormFooterOptions from '../../common/form-footer-options/FormFooterOptions';
import PatientDocuments from '../patient-section/PatientDocuments';
import DocumentsList from './DocumentsList';
import {
  fetchSpecialties, pediatricsFilterLink, fetchChiefComplaints, chiefComplaintsData, fetchWorkupChecklists, workupChecklistsData, setSpecialistRequiredFieldsChanged,
} from '../../../state';
import WorkupChecklist from './WorkupChecklist';
import OtherTextInputComponent from './OtherTextInputComponent';
import Session from '../../../services/Session';
import EhrDocumentsProvider from '../../../services/EhrDocumentProvider';
import { filterOptions, filterOptionsSpecialities, formatDate } from '../../../utils';
import { trackEvent } from '../../../services/AnalyticsService';

function RequestReason(props) {
  const { request, isDisabled } = props;
  const dispatch = useDispatch();

  const [onSelectedValue, setOnSelectedValue] = useState(request ? request.specialtySelector : '');
  const [onSelectedChiefComplaint, setOnSelectedChiefComplaint] = useState(request ? request.chiefComplaintSelector : '');
  const [editorValue, setEditorValue] = useState(request ? request.textEditor : '');
  const [isPrintQueueEnabled, setIsPrintQueueEnabled] = useState(false);
  const [isCSNValid, setIsCSNValid] = useState(true);
  const [noSpecialistModal, setNoSpecialistModal] = useState(false);
  const [noSpecialistReason, setNoSpecialistReason] = useState(null);
  const [isSpecialistAvailableLoading, setIsSpecialistAvailableLoading] = useState(false);
  const [slaAcknowledge, setSlaAcknowledge] = useState(false);
  const [
    nextAvailableSpecialistAvailableDate,
    setNextAvailableSpecialistAvailableDate
  ] = useState(null);
  const [
    assignedToUsual,
    setAssignedToUsual
  ] = useState(null);

  const {
    specialties,
    isFetchingSpecialties,
    pediatricsFilter,
  } = useSelector((state) => state.specialty, shallowEqual);
  const { organizationId, user } = useSelector((state) => state.user, shallowEqual);
  const {
    chiefComplaints,
    isFetchingChiefComplaints,
  } = useSelector((state) => state.chiefComplaint, shallowEqual);
  const { patient, isPatientSearched } = useSelector((state) => state.patient, shallowEqual);
  const { serviceRequestDraftData } = useSelector((state) => state.serviceRequest, shallowEqual);
  const { specialistRequiredFieldsChanged } = useSelector((state) => state.form, shallowEqual);
  const { fieldsRefs } = useSelector((state) => state.fieldRef, shallowEqual);

  const {
    register,
    errors,
    setValue,
    getValues,
    watch,
    clearErrors,
    setError,
    unregister,
  } = useFormContext();

  const specialtyInputData = watch('specialtySelector');
  const siteInputData = watch('siteSelector');
  const documents = watch('documents');

  const emptyErrorMessage = 'This field is required';

  const toggleNoSpecialistModal = () => {
    setNoSpecialistModal(!noSpecialistModal);
  };

  const updateSlaAcknowledge = (value) => {
    setSlaAcknowledge(value);
    setValue('slaAcknowledge', value);
  };

  const handleEditorBlur = (html, delta, methods) => {
    if (methods?.getLength() === 1) {
      setError('textEditor', { type: 'manual', message: emptyErrorMessage });
    } else {
      clearErrors('textEditor');
    }
  };

  const handleEditorChange = (html, delta, origin, methods) => {
    setEditorValue(html);
    setValue('textEditor', html);
    if (methods?.getLength() === 1) {
      setValue('textEditor', '');
    } else {
      clearErrors('textEditor');
    }
  };

  const formats = [
    'list', 'bullet', 'bold', 'italic',
  ];

  const modules = {
    toolbar: [
      [{
        list: 'ordered',
      },
      {
        list: 'bullet',
      }],
      ['bold', 'italic'],
      ['clean'],
    ],
  };

  const epicEncounterCSN = useRef();
  const specialtySelectorRef = useRef();
  const chiefComplaintSelectorRef = useRef();
  const textEditorRef = useRef();
  const patientConsent = useRef();

  const onSelectChiefComplaint = (selectedItem) => {
    setValue('chiefComplaintSelector', selectedItem);
    setOnSelectedChiefComplaint(selectedItem);
    clearErrors('chiefComplaintSelector');
    dispatch(fetchWorkupChecklists(specialtyInputData.value, selectedItem.value));
    if (selectedItem.label !== 'Other') {
      setValue('otherText', '');
    }
  };

  const specialistAvailablilityCheck = async() => {
    const values = getValues();
    if (values.dob && values.specialtySelector) {
      try {
        setIsSpecialistAvailableLoading(true);
        const {data} = await axios.post(`/specialty/${values.specialtySelector.value}/is_specialty_available`, {
          organizationId,
          siteId: siteInputData.value,
          patientData: {
            date_of_birth: moment(values.dob).format('YYYY-MM-DD'),
            insurance_provider_id: values.insuranceProvider.value,
            // patientId contains the internal fhir id
            patient_internal_fhir_id: values.patientId !== '' ? values.patientId : undefined,
          },
        }, {validateStatus: (status) => status === 200 || status === 404})
        setNoSpecialistModal(!data.is_available);
        setNextAvailableSpecialistAvailableDate(data.next_specialist_available_date);
        setAssignedToUsual(data.assigned_to_usual)
        setNoSpecialistReason(data.reason || null);
        updateSlaAcknowledge(false);
        if (data.reason) {
          trackEvent('No Specialist Available', {
            specialty_id: values.specialtySelector.value,
            specialty_name: values.specialtySelector.label,
            reason: data.reason,
          });
        }
      } catch (e) {
        setNoSpecialistReason('An error occurred while loading specialist availability');
        setNoSpecialistModal(true);
      } finally {
        setIsSpecialistAvailableLoading(false);
      }
    }
  };

  /* Specialty Functions
  * Specialties input and select control
  */
  const onSelectSpecialty = async (selectedItem) => {
    // clean the selected chief complaint when the specialty change
    if (selectedItem !== specialtyInputData) {
      setOnSelectedChiefComplaint('');
      setValue('chiefComplaintSelector', '');
      dispatch(fetchChiefComplaints(selectedItem.value));
      dispatch(workupChecklistsData(null));
    }

    setValue('specialtySelector', selectedItem);
    clearErrors('specialtySelector');
    setOnSelectedValue(selectedItem);
    dispatch(workupChecklistsData(null));
    specialistAvailablilityCheck();
  };

  const [
    chiefComplaintWasLoadedFromDraft,
    setChiefComplaintWasLoadedFromDraft] = useState(false);
  useEffect(() => {
    // Auto-populate chief complaint for an eConsult saved as draft (on the state and on the form)
    if (!request && serviceRequestDraftData?.chiefComplaintSelector && specialtyInputData.value
      && chiefComplaints?.length >= 1 && !chiefComplaintWasLoadedFromDraft) {
      setChiefComplaintWasLoadedFromDraft(true);
      const chiefComplaintSelected = chiefComplaints.find((cc) => cc.value == serviceRequestDraftData.chiefComplaintSelector);
      if (chiefComplaintSelected) {
        onSelectChiefComplaint(chiefComplaintSelected);
      }
      setTimeout(() => {
        setValue('otherText', serviceRequestDraftData.otherText);
      }, 0);
    }
  }, [chiefComplaints]);

  const [
    epicEncounterCSNWasLoadedFromDraft,
    setEpicEncounterCSNWasLoadedFromDraft] = useState(false);
  useEffect(() => {
    // Auto-populate epicEncounterCSN for an eConsult saved as draft
    if (patient && !request && serviceRequestDraftData?.epicEncounterCSN
      && !epicEncounterCSNWasLoadedFromDraft) {
      setEpicEncounterCSNWasLoadedFromDraft(true);
      setValue('epicEncounterCSN', serviceRequestDraftData.epicEncounterCSN);
      // eslint-disable-next-line no-use-before-define
      validateCSN(serviceRequestDraftData.epicEncounterCSN);
    }
  }, [serviceRequestDraftData?.epicEncounterCSN, patient]);

  const [
    specialtyWasLoadedFromDraft,
    setSpecialtyWasLoadedFromDraft] = useState(false);
  useEffect(() => {
    // Auto-populate specialty for an eConsult saved as draft (on the state and on the form)
    if (!request) {
      if (serviceRequestDraftData?.specialtySelector
        && specialties?.length >= 1 && !specialtyWasLoadedFromDraft) {
        const specialtySelected = specialties.find((specialty) => specialty.value == serviceRequestDraftData.specialtySelector);
        // FORM-291 we should validate if the specialty selected is not available anymore
        if (specialtySelected) {
          setSpecialtyWasLoadedFromDraft(true);
          onSelectSpecialty(specialtySelected);
        } else if (serviceRequestDraftData.specialtySelector && pediatricsFilter) {
          // Specialty was not in the pedatrics list, try loading all specialties
          dispatch(fetchSpecialties(organizationId));
          dispatch(pediatricsFilterLink(false));
        }
      }
    }
  }, [specialties]);

  const [
    textEditorWasLoadedFromDraft,
    setTextEditorWasLoadedFromDraft] = useState(false);
  const [patientConsentWasLoadedFromDraft, setPatientConsentWasLoadedFromDraft] = useState(false);
  useEffect(() => {
    // Auto-populate Clinical Question for an econsult saved as draft (on the state and on the form)
    if (!request && serviceRequestDraftData?.textEditor && !textEditorWasLoadedFromDraft) {
      setTextEditorWasLoadedFromDraft(true);
      handleEditorChange(serviceRequestDraftData.textEditor);
    }
    // Auto-populate patientConsent for an eConsult saved as draft
    if (!request && serviceRequestDraftData?.patientConsent && !patientConsentWasLoadedFromDraft) {
      setPatientConsentWasLoadedFromDraft(true);
      setValue('patientConsent', serviceRequestDraftData.patientConsent);
    }
  }, [serviceRequestDraftData?.textEditor, serviceRequestDraftData?.patientConsent]);

  useEffect(() => {
    if (user?.id != null) {
      setIsPrintQueueEnabled(Session.verifyPermissions(user, 'print_queue_access'));
    }
    if (request?.scroll?.specialty && specialties) {
      setTimeout(() => {
        document.querySelector('#request-reason').scrollIntoView({ behavior: 'smooth' });
      }, 0);
    }
    register({ name: 'specialtySelector' }, { required: emptyErrorMessage });
    register({ name: 'chiefComplaintSelector' }, { required: emptyErrorMessage });
    register({ name: 'textEditor' }, { required: emptyErrorMessage });
  }, []);

  useEffect(() => {
    if (specialties === null && specialtyInputData === '') {
      setOnSelectedValue('');
      setOnSelectedChiefComplaint('');
      setValue('chiefComplaintSelector', '');
      chiefComplaintsData(null);
    }
  }, [specialtyInputData, specialties]);

  useEffect(() => {
    if (errors && typeof fieldsRefs === 'string') {
      if (fieldsRefs === 'textEditor') {
        textEditorRef.current.focus();
      }
      if (fieldsRefs === 'chiefComplaintSelector') {
        chiefComplaintSelectorRef.current.focus();
      }
      if (fieldsRefs === 'specialtySelector') {
        specialtySelectorRef.current.focus();
      }
      if (fieldsRefs === 'patientConsent') {
        patientConsent.current.focus();
      }
    }
  }, [errors, fieldsRefs]);

  useEffect(() => {
    if (onSelectedChiefComplaint.label === 'Other') {
      register({ name: 'otherText' }, { required: emptyErrorMessage });
    } else {
      unregister('otherText');
    }
  }, [onSelectedChiefComplaint]);

  // If any of the fields needed for trigger specialist availability change,
  // we will need to clean the specialty dropdown to force the user select the specialty again
  // Note: onSelectedValue is empty the first time we load a draft request, so it will not affect drafts
  // unless it finished loading
  useEffect(() => {
    if((specialistRequiredFieldsChanged) && onSelectedValue !== '') {
      specialistAvailablilityCheck();
    }
    dispatch(setSpecialistRequiredFieldsChanged(false))
  }, [specialistRequiredFieldsChanged]);

  const showAllSpecialties = (e, pediatricsFilterData, showPediatricSpecialties) => {
    e.preventDefault(e);
    // clean value of the form if filter is pediatric and user already has
    // and adult specialty selected
    if (onSelectedValue !== '') {
      if (!onSelectedValue.pediatrics_only && showPediatricSpecialties) {
        setValue('specialtySelector', '');
      }
    }
    dispatch(fetchSpecialties(organizationId, pediatricsFilterData));

    dispatch(pediatricsFilterLink(showPediatricSpecialties));
  };

  // Load options if the user use the input to filter them
  const loadSpecialtiesOptions = (inputValue, callback) => {
    callback(filterOptionsSpecialities(inputValue, specialties));
  };

  // Load options if the user use the input to filter them
  const loadChiefComplaintsOptions = (inputValue, callback) => {
    callback(filterOptions(inputValue, chiefComplaints));
  };

  useEffect(() => {
    if (
      request?.epicEncounterCSN
      && user?.organization?.settings?.request_epic_encounter_csn_enabled) {
      // eslint-disable-next-line no-use-before-define
      validateCSN(request.epicEncounterCSN);
    }
  }, [request?.epicEncounterCSN, user?.organization?.settings?.request_epic_encounter_csn_enabled]);

  useEffect(() => {
    // If is a new econsult and the patient was searched by the user,
    // proceed to trigger the CSN validation
    if (!request && !serviceRequestDraftData
      && patient && isPatientSearched.isSearched && isPatientSearched.userEvent) {
      // eslint-disable-next-line no-use-before-define
      validateCSN(epicEncounterCSN?.current?.value);
    }
  }, [patient]);

  const validateCSN = async (value) => {
    // Don't validate the CSN if the value is empty or the patient is not loaded yet
    if (!value || !patient) {
      setIsCSNValid(true);
      return;
    }

    try {
      const { data } = await axios.post('ehr/epic/encounters/search', {
        organization_id: user.organization?.id,
        csn: value,
        patient_id: patient.id,

      });

      if (data?.id) {
        setIsCSNValid(true);
      } else {
        setIsCSNValid(false);
      }
    } catch (e) {
      setIsCSNValid(false);
    }
  };

  return (
    <>
      <Modal
        id="no-specialist-modal"
        isOpen={noSpecialistModal}
        toggle={toggleNoSpecialistModal}
      >
        <ModalHeader toggle={toggleNoSpecialistModal}>Specialist Availability</ModalHeader>
        <ModalBody className="mw-100 h-100">
          <div className="row">
            <div className="col-1">
              <i className="fa fa-warning fa-lg mt-3 mr-3 text-warning float-left" />
            </div>
            <div className="col-11">
              <p>{noSpecialistReason}</p>
              {nextAvailableSpecialistAvailableDate
              && (
                <p>
                  {assignedToUsual ? 'Specialist available on' : 'Next available specialist'}:
                  <span className="font-weight-bold">{` ${formatDate(nextAvailableSpecialistAvailableDate)}`}</span>
                </p>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="light" className="mt-3" onClick={toggleNoSpecialistModal}>Close</Button>
          { nextAvailableSpecialistAvailableDate
            && (
              <Button color="success" className="mt-3" onClick={() => { updateSlaAcknowledge(true); toggleNoSpecialistModal(); }}>Acknowledge and Continue</Button>
            )}
        </ModalFooter>
      </Modal>
      <div className={isDisabled ? 'card disabled-field' : 'card enable-field'} data-aut="request-reason-form">
        <div className="card-title p-3 m-0">
          <h6 className="font-weight-bold border-bottom pb-2 request-reason" id="request-reason">Request Reason</h6>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-xl-6 p-0">
              {user?.organization?.settings?.request_epic_encounter_csn_enabled ? (
                <div className="form-group col-12 mb-4">
                  <div className="row">
                    <div className="col-12 col-sm-3 col-md-3 col-xl-4 text-sm-right">
                      <label htmlFor="epicEncounterCSN" className="epicEncounterCSN-label" data-auto="epicEncounterCSN-label">CSN</label>
                    </div>
                    <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-6 epicEncounterCSN">
                      <input
                        id="epicEncounterCSN"
                        ref={(ref) => {
                        epicEncounterCSN.current = ref;
                        register(ref, {
                          required: emptyErrorMessage,
                          pattern: {
                            value: /^[A-Za-z0-9'-\\ ]*$/,
                            message: 'Please remove any special character',
                          },
                        });
                      }}
                        onBlur={(i) => validateCSN(i.target.value)}
                        className={`form-control ${errors.epicEncounterCSN ? 'is-invalid' : null} ${!isCSNValid ? 'border-warning' : null}`}
                        type="text"
                        name="epicEncounterCSN"
                        data-auto="epicEncounterCSN-input"
                      />
                      {errors.epicEncounterCSN?.type === 'required'
                    && (
                    <div className="alert alert-danger" role="alert" data-auto="epicEncounterCSN-error">
                      <i className="fa fa-warning fa-sm" />
                      {' '}
                      {errors.epicEncounterCSN.message}
                    </div>
                    )}
                      {!isCSNValid && (
                      <div className="alert alert-warning mt-2" role="alert" data-auto="epicEncounterCSN-warning">
                        <i className="fa fa-warning fa-sm" />
                        {' '}
                        Warning: CSN not found, please confirm in your EHR
                      </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="form-group col-12 mb-4">
                <div className="row">
                  <div className="col-12 col-sm-3 col-md-3 col-xl-4 text-sm-right">
                    <label htmlFor="specialtyName" data-aut="specialty-label">Specialty</label>
                  </div>
                  <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-6 specialty-selector">
                    <AsyncSelect
                      ref={specialtySelectorRef}
                      autoFocus={request?.scroll?.specialty}
                      name="specialtySelector"
                      data-aut="specialty-select"
                      isSearchable
                      value={onSelectedValue}
                      onChange={onSelectSpecialty}
                      defaultOptions={specialties}
                      loadOptions={loadSpecialtiesOptions}
                      isLoading={(!specialties?.length && isFetchingSpecialties) || isSpecialistAvailableLoading}
                      className={
                      errors.specialtySelector
                        ? 'form-control-select is-invalid specialty-selector'
                        : 'form-control-select specialty-selector'
                    }
                    />
                    {errors.specialtySelector?.type === 'required'
                  && (
                  <div className="alert alert-danger" role="alert" data-auto="specialty-select-error">
                    <i className="fa fa-warning fa-sm" />
                    {' '}
                    {errors.specialtySelector.message}
                  </div>
                  )}
                    {specialties && !specialties.length && !isFetchingSpecialties && (
                    <div className="alert alert-danger" role="alert" data-auto="specialty-select-error">
                      <i className="fa fa-warning fa-sm" />
                      {' '}
                      No specialties available at this time
                    </div>
                    )}
                  </div>
                  {
                  pediatricsFilter
                  && (
                  <span className="mt-2 col-sm-8 offset-3 offset-sm-4 offset-md-3 offset-lg-3 offset-xl-4 text-muted">
                    Filtered to pediatric specialties only.
                    <a href="#" onClick={(e) => { showAllSpecialties(e, null, false); }}> Show all.</a>
                  </span>
                  )
                }
                  { // TODO listen the patient with redux and check if it is pediatrics
                  pediatricsFilter === false
                  && (
                  <span className="mt-2 col-sm-8 offset-3 offset-sm-4 offset-md-3 offset-lg-3 offset-xl-4 text-muted">
                    Filtered to show adult and pediatric specialties.
                    <a href="#" onClick={(e) => { showAllSpecialties(e, 'pediatric', true); }}> Show pediatric only.</a>
                  </span>
                  )
                }
                </div>
              </div>
              <div className="form-group col-12 mb-4">
                <div className="row">
                  <div className="col-12 col-sm-3 col-md-3 col-xl-4 text-sm-right">
                    <label htmlFor="chiefComplaintName" data-aut="chief-complaint-label">Chief Complaint</label>
                  </div>
                  <div className="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-6 chief-complaints-select">
                    <AsyncSelect
                      ref={chiefComplaintSelectorRef}
                      name="chiefComplaintSelector"
                      data-aut="chief-complaint-select"
                      isSearchable
                      value={onSelectedChiefComplaint}
                      onChange={onSelectChiefComplaint}
                      defaultOptions={chiefComplaints}
                      loadOptions={loadChiefComplaintsOptions}
                      isLoading={isFetchingChiefComplaints}
                      className={
                      errors.chiefComplaintSelector
                        ? 'form-control-select is-invalid chief-complaints-select'
                        : 'form-control-select chief-complaints-select'
                    }
                    />
                    {errors.chiefComplaintSelector?.type === 'required'
                  && (
                  <div className="alert alert-danger" role="alert" data-auto="chief-complaint-error">
                    <i className="fa fa-warning fa-sm" />
                    {' '}
                    {errors.chiefComplaintSelector.message}
                  </div>
                  )}
                  </div>
                </div>
              </div>
              { onSelectedChiefComplaint.label === 'Other'
              && <OtherTextInputComponent request={request} />}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-xl-6 p-0">
              <EhrDocumentsProvider>
                <div className="form-group col-12 mb-4">
                  <div className="row">
                    <div className="col-12 col-sm-3 col-md-3 col-xl-4 text-sm-right">
                      <label htmlFor="supportingDetails" data-aut="clinical-question-label">Clinical Question and Supporting Details</label>
                    </div>
                    <div className="col-12 col-sm-8 col-lg-6 col-xl-8" data-auto="request-form-clinical-question">
                      <div className="row">
                        <div className="col-12">
                        <ReactQuill
                          onBlur={handleEditorBlur}
                          theme="snow"
                          name="textEditor"
                          value={editorValue}
                          modules={modules}
                          formats={formats}
                          onChange={handleEditorChange}
                          ref={textEditorRef}
                          data-aut="text-editor"
                          className={
                          errors.textEditor
                            ? 'form-control-textarea is-invalid clinical-question'
                            : 'form-control-textarea clinical-question'
                        }
                        />
                        {(errors.textEditor?.type === 'required' || errors.textEditor?.types?.type === 'manual')
                        && (
                        <div className="alert alert-danger" role="alert" data-auto="clinical-question-error">
                          <i className="fa fa-warning fa-sm" />
                          {' '}
                          {errors.textEditor?.message || errors.textEditor?.types?.message}
                        </div>
                        )}
                        <AddDocuments show open={false} defaultTab="1" enablePrintQueue={isPrintQueueEnabled} />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
                { documents?.length > 0
              && (
              <div className="form-group col-12 mb-4">
                <div className="row">
                  <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-4 text-sm-right">
                    <label htmlFor="documents" data-aut="documents-label">Attached Documents</label>
                  </div>
                  <div className="col-12 col-sm-8 col-lg-6 col-xl-8">
                    <div className="row">
                      <div className="col-12">
                        <DocumentsList documents={documents} canDeleteDocument />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              )}
              {user?.organization?.settings?.patient_consent_enabled ? (
                <div className="form-group col-12 mb-4">
                  <div className="row">
                    <div className="col-12 col-sm-3 col-md-3 col-xl-4 text-sm-right"/>
                    <div className="col-12 col-sm-8 col-lg-6 col-xl-8 patientConsent">
                      <div class="form-check checkbox-lg">
                        <input
                          id="patientConsent"
                          ref={(ref) => {
                            patientConsent.current = ref;
                            register(ref, {
                              required: emptyErrorMessage,
                            });
                          }}
                          name="patientConsent"
                          className="form-check-input"
                          type="checkbox"
                        />
                        <label className={`patientConsent-label form-check-label ${errors.patientConsent ? 'is-invalid' : null}`} data-auto="patientConsent-label">The patient or guardian has provided verbal consent for eConsult to (1) share clinical information and (2) specialist billing for eConsult. Patient was consulted with specialty for diagnosis.</label>
                      </div>
                      {errors.patientConsent?.type === 'required'
                      && (
                      <div className="alert alert-danger" role="alert" data-auto="patientConsent-error">
                        <i className="fa fa-warning fa-sm" />
                        {' '}
                        {errors.patientConsent.message}
                      </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
                <PatientDocuments enablePrintQueue={isPrintQueueEnabled} />
                <FormFooterOptions
                  isLoading={isSpecialistAvailableLoading}
                  noSpecialistAvailableReason={noSpecialistReason}
                  slaAcknowledge={slaAcknowledge}
                />
              </EhrDocumentsProvider>
            </div>
            <WorkupChecklist />
          </div>
        </div>
      </div>
    </>
  );
}

const {
  bool,
} = PropTypes;

RequestReason.propTypes = {
  isDisabled: bool,
};

RequestReason.defaultProps = {
  isDisabled: false,
};

export default RequestReason;
