import { useState, useEffect } from 'react';
import axios from '../services/axios';

export default function useIsSpecialistOnCall(
  specialtyCode, patientId, isPediatrics = false, organizationId = null, payerId = null,
) {
  const [isOnCall, setIsOnCall] = useState(true);

  useEffect(() => {
    const params = { organizationId, payerId };
    if (isPediatrics) {
      params.filter = 'pediatrics';
    }
    axios.get(`/panelists/getNextAvailable/${specialtyCode}${patientId ? `/${patientId}` : ''}`, { params }).then(({ data }) => {
      if (!data.next_available_specialist_on_panel_sla) {
        setIsOnCall(false);
      }
    });
  }, []);

  return isOnCall;
}
