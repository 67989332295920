import React from 'react';
import { NavLink } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import routes from '../main-page/routes';
import EConsultsBreadcrumbLink from '../breadcrumbs/EConsultsBreadcrumbLink';

// map & render your breadcrumb components however you want.
const Breadcrumbs = ({ breadcrumbs }) => {
  if (true) { // breadcrumbs.length > 1 to hide first level
    return (
      <div className="breadcrumbs-container">
        <div
          className="breadcrumbs-bar container"
        >
    <Breadcrumb tag="nav">
      {breadcrumbs.map(({
        match,
        breadcrumb,
        key,
        // other props are available during render, such as `location`
        // and any props found in your route objects will be passed through too
      }, index) => {
        let content;

        if (index < breadcrumbs.length - 1) {
          if (match.url === '/econsults') {
            content = <EConsultsBreadcrumbLink />;
          } else {
            content = <NavLink to={match.url}>{breadcrumb}</NavLink>;
          }
        } else {
          content = breadcrumb;
        }

          return (
                  <BreadcrumbItem
                    active={index === breadcrumbs.length - 1}
                    key={key}
                  >
                    {content}
                  </BreadcrumbItem>
                );
      })}
    </Breadcrumb>
       </div>
      </div>
    )
}
 return '';
}

// don't add breadcrumb for Home (root path)
const options = {
  // excludePaths: ['/'],
};

export default withBreadcrumbs(routes, options)(Breadcrumbs);
