import createReducer from './createReducer';

/** ********************************************
 * constants
 ******************************************** */

export const SIDE_DRAWER_HEIGHT = 'sideDrawer/SIDE_DRAWER_HEIGHT';
export const SIDE_DRAWER_OPEN = 'sideDrawer/SIDE_DRAWER_OPEN';
export const SIDE_DRAWER_TOP = 'sideDrawer/SIDE_DRAWER_TOP';

/** ********************************************
 * reducer
 ******************************************** */

export const initialSideDrawerState = {
  height: 0,
  open: false,
  top: 0,
};

export default createReducer(initialSideDrawerState, {
  [SIDE_DRAWER_HEIGHT]: (state, { payload }) => ({ height: payload }),
  [SIDE_DRAWER_OPEN]: (state, { payload }) => ({ open: payload }),
  [SIDE_DRAWER_TOP]: (state, { payload }) => ({ top: payload }),
});

/** ********************************************
 * actions
 ******************************************** */

/**
 *
 * @param {string|int} height
 * @return {{type: string, payload: *}}
 */
export const setSideDrawerHeight = height => ({
  type: SIDE_DRAWER_HEIGHT,
  payload: height,
});

/**
 *
 * @param {boolean} open
 * @return {{type: string, payload: *}}
 */
export const setIsSideDrawerOpen = open => ({
  type: SIDE_DRAWER_OPEN,
  payload: open,
});

/**
 *
 * @param {string|int} top
 * @return {{type: string, payload: *}}
 */
export const setSideDrawerTop = top => ({
  type: SIDE_DRAWER_TOP,
  payload: top,
});

/** ********************************************
 * selectors
 ******************************************** */

/**
 * @param state
 * @return {boolean}
 */
export const selectSideDrawerHeight = state => state.sideDrawer.height;

/**
 * @param state
 * @return {boolean}
 */
export const selectIsSideDrawerOpen = state => state.sideDrawer.open;

/**
 * @param state
 * @return {boolean}
 */
export const selectSideDrawerTop = state => state.sideDrawer.top;
