import React, { useEffect, useState } from 'react';
import dwv from 'dwv';
import DicomToolBox from '../dicom-viewer/DicomToolBox';

const DicomViewer = ({ documentUrl, unitTest }) => {
  const [app, setApp] = useState(null);

  useEffect(() => {
    // Default window level presets.
    dwv.tool.defaultpresets = {};
    // Default window level presets for CT.
    dwv.tool.defaultpresets.CT = {
      mediastinum: { center: 40, width: 400 },
      lung: { center: -500, width: 1500 },
      bone: { center: 500, width: 2000 },
    };
    // decode query
    dwv.utils.decodeQuery = dwv.utils.base.decodeQuery;
    dwv.gui.getWindowSize = () => {
      return {
        width: $(window).width(),
        height: $(window).height() - 64,
      };
    };
    dwv.gui.displayProgress = () => {};
    // base function to get elements
    dwv.gui.getElement = dwv.gui.base.getElement;
    // create the dwv app
    setApp(new dwv.App());
  }, []);

  useEffect(() => {
    // initialise the application
    if (app && !unitTest) {
      app.init({
        containerDivId: 'dicom-viewer',
        fitToWindow: true,
        tools: ['Scroll', 'ZoomAndPan', 'WindowLevel'],
        isMobile: false,
        useWebWorkers: false,
      });

      app.loadURLs([documentUrl]);
    }
  }, [app]);

  return (
    <div className="dicom w-100">
      <div id="dicom-viewer">
        <div className="layerContainer">
          <DicomToolBox dicomApp={app} />
          <canvas className="imageLayer" />
        </div>
      </div>
    </div>
  );
};

export default DicomViewer;
