import createReducer from '../createReducer';

/** ********************************************************
 * CONSTANTS
 ******************************************************** */

export const SET_SPECIALIST_REQUIRED_FIELDS_CHANGED = 'form/SET_SPECIALIST_REQUIRED_FIELDS_CHANGED';


/** ********************************************************
 * REDUCER
 ******************************************************** */
export const initialStateForm = {
  specialistRequiredFieldsChanged: false,
};

export default createReducer(initialStateForm, {
  [SET_SPECIALIST_REQUIRED_FIELDS_CHANGED]: (state, { payload }) => ({
    ...state, specialistRequiredFieldsChanged: payload,
  }),
});

/** ********************************************************
 * ACTIONS
 ******************************************************** */

/**
 * Set a flag when a field required to availability specialist change
 * @param content true/false
 * @returns {{type: string, payload: boolean}}
 */
export const setSpecialistRequiredFieldsChanged = content => ({
  type: SET_SPECIALIST_REQUIRED_FIELDS_CHANGED,
  payload: content,
});
