import React from 'react';
import {
  Collapse,
  Nav,
} from 'reactstrap';
import HelpMenu from './HelpMenu';
import UserMenu from './UserMenu';

const HeaderMenu = () => (
  <div className="d-inline-block col-3 user-menu">
    <Collapse navbar className="float-right">
      <Nav className="mr-auto" navbar>
        <UserMenu />
      </Nav>
    </Collapse>
  </div>
);

export default HeaderMenu;
