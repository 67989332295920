import createReducer from './createReducer';

/** ********************************************
 * constants
 ******************************************** */

export const SHOW_IDLE_MODAL = 'idleModal/SHOW';

/** ********************************************
 * reducer
 ******************************************** */

export const initialIdleModalState = {
    show: false,
};

export default createReducer(initialIdleModalState, {
    [SHOW_IDLE_MODAL]: (state, { payload }) => ({ show: payload }),
});

/** ********************************************
 * actions
 ******************************************** */

/**
 *
 * @param {boolean} bool
 * @return {{type: string, payload: *}}
 */
export const setShowIdleModal = bool => ({
    type: SHOW_IDLE_MODAL,
    payload: bool,
});

/** ********************************************
 * selectors
 ******************************************** */

/**
 * @param state
 * @return {boolean}
 */
export const selectShowIdleModal = state => state.idleModal.show;
