import axios from 'axios';
import EnvironmentSettings from '../../../services/EnvironmentSettings';
import Session from '../../../services/Session';

/**
 * Gets the org Id based on the FHIR id
 * @param {string} fhirId 
 * @return {int} orgId
 */
 export default function* getOrgIdForRequest({fhirId}) {
  let orgId = null;
  try {
    const response = yield axios.get(`${EnvironmentSettings.fhirApiUrl}/ServiceRequest/${fhirId}/getOrganizationId`);
    orgId = response.data;
  } catch {
    let error = '';
    if (e.response && e.response.data && e.response.data.error) {
      error = e.response.data.error;
    }
    Session.unauthorized(error);
  }
  return orgId;
}