import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import 'animate.css/animate.min.css';
import {
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import DicomViewer from '../../dicom-viewer/DicomViewer';
import DicomThumbnail from '../../dicom-viewer/DicomThumbnail';
import FileViewer from '../../file-viewer/FileViewer';

function DocumentsList({ documents, canDeleteDocument }) {
  let setValue, getValues, removeDocument;

  if (canDeleteDocument) {
    ({ setValue, getValues } = useFormContext());
  }

  const [modal, setModal] = useState(false);
  const [currentDoc, setCurrentDoc] = useState(null);
  const toggle = () => {
    setModal(!modal);
  };

  function addAnimation(element, animationClass, callback) {
  	const END_EVENT = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
  	$(`#${element}`).addClass(animationClass);
  	$(`#${element}`).one(END_EVENT, function() {
    	callback(element);
  	});
  }

  if (canDeleteDocument) {
    removeDocument = (docId) => {
      setValue('documents', getValues().documents
      .filter(attachments => attachments.id !== docId));
    };
  }

  const onRemoveDocument = (docId) => {
    addAnimation(docId, 'animate__animated animate__fadeOut', removeDocument);
  }

  const onViewDocument = (document) => {
    setCurrentDoc(document);
    toggle();
  };

  return (
    <>
    <Modal
      id="attachment-modal"
      isOpen={modal}
      toggle={toggle}
      className="h-100 m-0 mw-100 full-screen-modal"
      wrapClassName="modal-desktop-attachments"
    >
      <ModalHeader toggle={toggle}>{currentDoc?.name}</ModalHeader>
      <ModalBody className="mw-100 h-100">
       { (currentDoc?.type === 'application/dicom' || currentDoc?.name.split('.').pop() === 'dcm') ? <DicomViewer documentUrl={currentDoc?.urlPreview}/> :
         <FileViewer file={currentDoc} />}
      </ModalBody>
    </Modal>
    <div className="row" data-auto="document-list-component">
      <div className={`${canDeleteDocument ? 'col-12' : 'col-12 ml-1'}`}>
        { documents?.map((doc) => {
          return (
            <div key={doc.id} id={doc.id} className='card mb-3' >
              <div className="row no-gutters">
                <div className={`col-xs-2 col-4 col-lg-2 ${canDeleteDocument ? 'col-sm-3' : 'col-sm-2'}`} >
                  <span onClick={toggle} className="align-items-center btn no-border" data-auto={`attachment-${doc.id}`}>
                    { (doc.type === 'application/dicom' || doc.name?.split('.').pop() === 'dcm') ?
                      <DicomThumbnail doc={doc} clickMethod={onViewDocument}/> :
                      <img src={doc.thumbnail} className="card-img" alt={doc.name} onClick={() => { onViewDocument(doc); }}/>}
                  </span>
                </div>
                <div className={`col-7 ${canDeleteDocument ? 'col-sm-8 col-md-8' : 'col-sm-10 col-md-10 pl-5'}`}>
                  <div className="card-body">
                    <p data-auto="attachment_name" className="card-title mb-0 truncate">{doc.name}</p>
                    <span className="btn btn-link p-0 m-0" onClick={() => { onViewDocument(doc); }}>View</span>
                  </div>
                </div>
                {
                  canDeleteDocument ? <div className="col-1 text-center" data-auto="trash-can-icon">
                  <i className="fas fa-trash-alt btn no-border" onClick={() => { onRemoveDocument(doc.id); }} />
                </div> : ''

                }

              </div>
            </div>
          );
        })}
      </div>
    </div>
    </>
  );
}
export default DocumentsList;
