window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
  window.$ = window.jQuery = require('jquery');
  require('bootstrap');
} catch (e) {}

// http://getbootstrap.com/javascript/#tooltips-usage
// Opt-in functionality for performance reasons,
// the Tooltip and Popover data-apis are opt-in, meaning
// you must initialize them yourself.
$(document).ready(() => {
  $('body').tooltip({ selector: '[data-toggle=tooltip]' });
  $('body').popover({ selector: '[data-toggle=popover]' });
	$('html').on('mouseup', function (e) {
    var l = $(e.target);
    if (typeof l[0].className === 'string' && l[0].className.indexOf('popover') == -1)
    {
      $('.popover').each(function () {
          $(this).popover('hide');
      });
    }
});
});
