import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import { fetchUser, selectUserOrganizations, userOrganizationId } from '../../state';
import { updateOrganization } from '../../services/axios';
import store from '../../store';
import EnvironmentSettings from '../../services/EnvironmentSettings';
import Session from '../../services/Session';

/**
 * Select sites modal
 */
class SelectSite extends Component {
  constructor(props) {
    super(props);
    this.childDiv = React.createRef();
    this.state = {
      organizationId: props.organizations[0].id,
    };
    this.handleSiteChange = this.handleSiteChange.bind(this);
    this.confirmSite = this.confirmSite.bind(this);
  }

  handleSiteChange(event) {
    this.setState({
      organizationId: event.target.value,
    });
  }

  confirmSite() {
    // Reset org saved in cookies to avoid duplicates
    cookie.remove(`scope_organization_id${EnvironmentSettings.stage ? '_dev' : ''}`);
    cookie.remove(`request_organization_id${EnvironmentSettings.stage ? '_dev' : ''}`);
    cookie.save(`scope_organization_id${EnvironmentSettings.stage ? '_dev' : ''}`, this.state.organizationId, {
      domain: EnvironmentSettings.domainServer,
      path: '/',
    });
    cookie.save(`request_organization_id${EnvironmentSettings.stage ? '_dev' : ''}`, this.state.organizationId, {
      domain: EnvironmentSettings.domainServer,
      path: '/',
    });
    updateOrganization(this.state.organizationId);
    store.dispatch(userOrganizationId(this.state.organizationId));
    // Fetch user after set organization since organization settings will
    // come back as part of user in user.organization.settings
    store.dispatch(fetchUser(Session.userId));
  }

  render() {
    return <div className="select-site-wrapper">
      <label>Select Site</label>
      <select className="form-control mb-3" value={this.state.organizationId} onChange={this.handleSiteChange}>
        {
          this.props.organizations ? this.props.organizations.map(org => (
            <option key={org.id} value={org.id}>{org.display_name.replace('&amp;', '&')}</option>
          )) : <option>Loading...</option>
        }
      </select>
      <button
        className="form-control btn btn-success btn-confirm"
        onClick={this.confirmSite}
      >
        Continue
      </button>
    </div>;
  }
}

const { array } = PropTypes;

SelectSite.propTypes = {
  organizations: array,
};

SelectSite.defaultProps = {
  organizations: [],
};

const mapStateToProps = state => ({
  organizations: selectUserOrganizations(state),
});

export default connect(mapStateToProps)(SelectSite);
