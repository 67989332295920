import React, { useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { history } from '../../store';
import ExitButton from '../common/exit-button/ExitButton';
import SaveDraftButtonReview from '../common/save-draft-button/SaveDraftButtonReview';
import RequestedByReview from './RequestedByReview';
import PatientReviewPage from './PatientReviewPage';
import ReasonForRequestReview from './ReasonForRequestReview';
import { serviceRequestData, isPatientSearched as setPatientSearched, postServiceRequestError } from '../../state';
import { trackEvent } from '../../services/AnalyticsService';
import RequestError from '../common/request-error/RequestError';

/**
 * Review Page Container
 */
function ReviewPageContainer() {
  const request = history.location?.state;
  const location = {
    pathname: '/econsults/create-request',
    state: request,
  };
  const dispatch = useDispatch();

  const {
    isRequestOnProcess,
    serviceRequestData: serviceRequestDataSelector,
  } = useSelector((state) => state.serviceRequest, shallowEqual);

  const onEdit = (scrollType) => {
    trackEvent('Edit Draft eConsult');
    request.scroll = scrollType;
    history.push(
      '/econsults/create-request',
      request,
    );
    dispatch(setPatientSearched(true, false));
    dispatch(serviceRequestData(null));
    dispatch(postServiceRequestError(false));
  };

  useEffect(() => {
    if (!isRequestOnProcess.showSpinner
        && serviceRequestDataSelector?.id && serviceRequestDataSelector?.status !== 'draft') {
      history.push(
        `/econsults/${serviceRequestDataSelector.identifier[0].value}/confirmation`,
        {
          ...request,
          id: serviceRequestDataSelector.identifier[0].value,
          submittedAt: serviceRequestDataSelector?.authoredOn?.date,
        },
      );
    }
  }, [isRequestOnProcess, serviceRequestDataSelector]);

  useEffect(() => () => {
    if (history.location.pathname === location.pathname) {
      history.replace(location);
    }
  }, []);

  if (!request) return null;

  return (
    <>
      <div data-auto="div-review-container" className="row container options-menu mb-3 mt-3">
        <div className="col-xs-8 col-sm-5">
          <h4 className="font-weight-bold new-request-title">Review and Submit</h4>
        </div>
        <div className="col-12 col-sm-7 text-xs-left text-sm-right">
          <SaveDraftButtonReview data-auto="button_save_draft" />
          <ExitButton data-auto="button_exit" />
        </div>
      </div>
      <div className="review-container container">
        <div className="row">
          <div className="col">
            <Card>
              <CardBody>
                <RequestError />
                <RequestedByReview edit onEdit={onEdit} />
                <PatientReviewPage edit onEdit={onEdit} />
                <ReasonForRequestReview edit onEdit={onEdit} />
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReviewPageContainer;
