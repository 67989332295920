import React from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import HeaderLogo from '../header-logo/HeaderLogo';
import HeaderMenu from '../header-menu/HeaderMenu';
import OrganizationMenu from '../header-menu/OrganizationMenu';

const Header = () => (
  <Navbar className="header d-block pt-1 row" color="primary" dark expand="xs">
    <div className="logo-container d-inline-block col-9">
      <div className="row">
        <NavbarBrand>
          <HeaderLogo />
        </NavbarBrand>
        <OrganizationMenu />
      </div>
    </div>
    <HeaderMenu />
  </Navbar>
);

export default Header;
