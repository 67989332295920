import React, { useState, useEffect, useContext } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Spinner
} from 'reactstrap';
import classnames from 'classnames';
import { useFormContext } from 'react-hook-form';
import UppyService from '../../../services/UppyService';
import DicomUploader from '../../../services/DicomUploader';
import DocumentsQueue from '../../common/documents-queue/DocumentsQueue';
import { clearPrintQueue, fetchPrintQueue } from '../../../state/printQueue/printQueue';
import EhrProgressNotes from './EhrProgressNotes/EhrProgressNotes';
import EhrLabResults from './EhrLabResults/EhrLabResults';
import { EhrDocumentContext } from "../../../services/EhrDocumentProvider";
import FileViewer from '../../file-viewer/FileViewer';


function AddDocuments({
  show, open, callbackMethod, defaultTab, enablePrintQueue,
}) {
  const {setEhrDocuments, ehrDocuments, uploadEhrDocuments, isUploadInProgress} = useContext(EhrDocumentContext);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(open);
  const [isUploadInProcess, setIsUploadInProcess] = useState(false);
  const [isClearAllDisabled, setIsClearAllDisabled] = useState(false);
  const [currentDoc, setCurrentDoc] = useState(null);
  const [modalPreview, setModalPreview] = useState(false);
  const { patient } = useSelector(state => state.patient, shallowEqual);
  const { hasEhrEnabled } = useSelector( state => state.organization );
  const {
    printQueue,
    isClearingPrintQueue,
    isClearingPrintQueueAll,
    isFetchingPrintQueue,
  } = useSelector(state => state.printQueue, shallowEqual);

  const {
    watch,
  } = useFormContext();
  const [rowData, setRowData] = useState([]);

  const watchFields = watch(['documents']);

  useEffect(() => {
      setIsClearAllDisabled(!!watchFields?.documents?.find(attachment => attachment.source === 'aristamd_print_queue'));
  }, [watchFields]);

  // Clear the selected ehr documents when the component unmounts
  useEffect(() => {
    return () => setEhrDocuments([])
  }, []);

  useEffect(() => {
    if (enablePrintQueue) {
      dispatch(fetchPrintQueue());
    }
  }, [enablePrintQueue]);

  const toggle = () => {
    if (callbackMethod) {
      callbackMethod();
    }
    setModal(!modal);
  };

  const togglePreview = () => {
    setModalPreview(!modalPreview);
  };

  const onViewDocument = (document) => {
    setCurrentDoc(document);
    togglePreview();
  };

  const closeBtn = <button className="close" data-auto="request-form-attach-document-close-modal" onClick={toggle} disabled={isUploadInProcess}>&times;</button>;
  let tab = defaultTab || 1;
  if (hasEhrEnabled) {
    tab = parseInt(defaultTab);
    tab = [1,2].indexOf(tab) !== -1 ? tab : tab + 2
    tab = `${tab}`;
  }
  const [activeTab, setActiveTab] = useState(tab);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  /**
   * When user clicks Clear Print Queue button, clear the print queue
   */
  const clearPrintQueueOnClick = () => {
    dispatch(clearPrintQueue());
  };

  /**
   * When user clicks Refresh Print Queue button, fetch the print queue
   */
  const refreshPrintQueueOnClick = () => {
    dispatch(fetchPrintQueue());
  };

  return (
    <>
      <Modal
        id="attachment-modal-preview"
        isOpen={modalPreview}
        toggle={togglePreview}
        className="h-100 m-0 mw-100 full-screen-modal"
        wrapClassName="modal-desktop-attachments"
      >
        <ModalHeader toggle={togglePreview}>{currentDoc?.name}</ModalHeader>
        <ModalBody className="mw-100 h-100">
          <FileViewer file={currentDoc} />
        </ModalBody>
      </Modal>
      <div>
        {show && (<Button
          color="light"
          className="mt-3"
          disabled={isUploadInProgress}
          data-auto="request-form-attach-document-button"
          onClick={toggle}>
            Attach Document(s) {isUploadInProgress && <Spinner size="sm" />}
        </Button>)}
        <Modal isOpen={modal} toggle={toggle} size="lg" scrollable backdrop="static" className="documents-modal" data-auto="request-form-attach-document-modal" keyboard={false}>
          <ModalHeader toggle={toggle} close={closeBtn} className="font-weight-bold">Attach Document(s)</ModalHeader>
          <ModalBody>
            <Nav tabs>
              {hasEhrEnabled ? (
                <>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => { toggleTab('1'); }}
                    >
                      EHR Visit Notes
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => { toggleTab('2'); }}
                    >
                      EHR Lab Results
                    </NavLink>
                  </NavItem>
                </>
              ) : false }
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === (!hasEhrEnabled ? '1' : '3') })}
                  onClick={() => { toggleTab((!hasEhrEnabled ? '1' : '3')); }}
                >
                  Upload File or Image
                </NavLink>
              </NavItem>
              {/*<NavItem>*/}
              {/*  <NavLink*/}
              {/*    className={classnames({ active: activeTab === (!hasEhrEnabled ? '2' : '4') })}*/}
              {/*    onClick={() => { toggleTab((!hasEhrEnabled ? '2' : '4')); }}*/}
              {/*  >*/}
              {/*    Upload DICOMS/X-RAY/MRI*/}
              {/*  </NavLink>*/}
              {/*</NavItem>*/}
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === (!hasEhrEnabled ? '3' : '5') })}
                  onClick={() => { toggleTab((!hasEhrEnabled ? '3' : '5')); }}
                >
                  Patient Documents({patient?.documents?.length ?? 0})
                </NavLink>
              </NavItem>
              { enablePrintQueue &&
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === (!hasEhrEnabled ? '4' : '6') })}
                    onClick={() => { toggleTab((!hasEhrEnabled ? '4' : '6')); }}
                  >
                    Print Queue({printQueue?.length ?? 0})
                  </NavLink>
                </NavItem>
              }
            </Nav>
            <TabContent activeTab={activeTab}>
              {hasEhrEnabled ? (
                <>
                  <TabPane tabId="1">
                    <EhrProgressNotes onViewDocument={onViewDocument} isActiveTab={activeTab === '1'}/>
                  </TabPane>
                  <TabPane tabId="2">
                    <EhrLabResults onViewDocument={onViewDocument} isActiveTab={activeTab === '2'}/>
                  </TabPane>
                </>
              ) : false }
              <TabPane tabId={(!hasEhrEnabled ? '1' : '3')}>
                <div className="row">
                  <div className="col-12">
                    <UppyService onProcess={setIsUploadInProcess} />
                  </div>
                </div>
              </TabPane>
              <TabPane tabId={(!hasEhrEnabled ? '2' : '4')}>
                <DicomUploader onProcess={setIsUploadInProcess} />
              </TabPane>
              <TabPane tabId={(!hasEhrEnabled ? '3' : '5')}>
                {
                  activeTab === (!hasEhrEnabled ? '3' : '5') &&
                  <DocumentsQueue documents={get(patient, 'documents')} onViewDocument={onViewDocument} />
                }
              </TabPane>
              { (enablePrintQueue && activeTab === (!hasEhrEnabled ? '4' : '6')) &&
              <TabPane tabId={(!hasEhrEnabled ? '4' : '6')}>
                <DocumentsQueue documents={printQueue} onViewDocument={onViewDocument} />
              </TabPane>
              }
            </TabContent>
          </ModalBody>
          <ModalFooter className="documents-footer">
            <Button color="light" className="mt-3" data-auto="request-form-attach-document-done-button" onClick={()=>{
              //FORM-496: Upload the EHR documents when user clicks done.
              if (modal && hasEhrEnabled && patient) {
                uploadEhrDocuments(patient.organization_id)
              }
              toggle();
            }} disabled={isUploadInProcess}>Done</Button>
            {
              // Show the print queue buttons only if user is viewing the Print Queue tab
              activeTab === (!hasEhrEnabled ? '4' : '6') &&
              <>
                <Button
                  color="light"
                  className="mt-3"
                  data-auto="request-form-clear-print-queue-button"
                  onClick={clearPrintQueueOnClick}
                  disabled={isFetchingPrintQueue || isClearingPrintQueue || isClearAllDisabled}
                >
                  Clear All {isClearingPrintQueueAll ? <Spinner size="sm" /> : ''}
                </Button>
                <Button
                  color="light"
                  className="mt-3"
                  data-auto="request-form-refresh-print-queue-button"
                  onClick={refreshPrintQueueOnClick}
                  disabled={isFetchingPrintQueue || isClearingPrintQueue}
                >
                  Refresh {isFetchingPrintQueue ? <Spinner size="sm" /> : ''}
                </Button>
              <span className="alert alert-info">
                <i className="fa fa-info-circle"></i> Queue will be cleared automatically after eConsult is submitted or saved
              </span>
              </>
            }
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default AddDocuments;
