import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { selectShowIdleModal } from '../../state';
import Session from '../../services/Session';

/**
 * Idle modal is controlled by Session.idleMonitor
 */
const IdleModal = ({ showIdleModal }) => (
        <Modal isOpen={showIdleModal} backdrop="static">
            <ModalHeader>Inactivity Notice</ModalHeader>
            <ModalBody>
                As a security precaution, your AristaMD session will end in
                {` ${Session.getIdleWarningInMinutes()} `} minutes due to inactivity.
                If you are still working, click OK.
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={ok}>OK</Button>
            </ModalFooter>
        </Modal>
);

const ok = () => {
    Session.hideIdleModal();
};

IdleModal.propTypes = {
    showIdleModal: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    showIdleModal: selectShowIdleModal(state),
});

export default connect(mapStateToProps)(IdleModal);
