import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { history } from '../../store';
import { isInsuranceCollectionEnabled, isReimbursementEnabled } from '../../helpers/CoverageHelper';
import { getGenderDisplay } from '../../helpers/gender';

/**
 * Patient Review Page
*/
function PatientReviewPage({ edit, onEdit }) {
  const request = history.location?.state;
  const { user } = useSelector((state) => state.user, shallowEqual);

  const [insuranceProvidersEnabled, isInsuranceProvidersEnabled] = useState(false);
  const [insuranceCollectionEnabled, setInsuranceCollectionEnabled] = useState(false);
  const [insuranceProvider, setInsuranceProvider] = useState(null);

  useEffect(() => {
    const insuranceProviderEnabled = isReimbursementEnabled(user?.organization);
    isInsuranceProvidersEnabled(insuranceProviderEnabled);
    const isInsuranceCollectionSettingEnabled = isInsuranceCollectionEnabled(user?.organization);
    setInsuranceCollectionEnabled(isInsuranceCollectionSettingEnabled);

    // Set the insurance provider
    if (insuranceProviderEnabled && request?.insuranceProvider) {
      setInsuranceProvider(request?.insuranceProvider?.label);
    } else if (isInsuranceCollectionSettingEnabled && request?.insuranceProviderText) {
      setInsuranceProvider(request?.insuranceProviderText);
    }
  }, [user]);

  return (
    <div className="row mb-4" data-auto="requested-by-form">
      <div className="col-12 mb-3">
        <h6 className="font-weight-bold border-bottom pb-2 requested-by">Patient</h6>
      </div>
      <div className="col-12 col-lg-10 w-100 pl-0">
        <div className="labels-wrapper d-inline-block">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-sm-5 col-md-4 text-sm-right">
                <p className="font-weight-bold mb-1 mb-sm-2">MRN</p>
              </div>
              <div className="col-12 col-sm-5 col-md-6 col-xl-6 mb-3 mb-sm-0">
                <span data-auto="patient-mrn">{request?.patientSearch}</span>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-sm-5 col-md-4 text-sm-right">
                <p className="font-weight-bold mb-1 mb-sm-2">First Name</p>
              </div>
              <div className="col-12 col-sm-5 col-md-6 col-xl-6 mb-3 mb-sm-0">
                <span data-auto="patient-first-name">{request?.firstName}</span>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-sm-5 col-md-4 text-sm-right">
                <p className="font-weight-bold mb-1 mb-sm-2">Last Name</p>
              </div>
              <div className="col-12 col-sm-5 col-md-6 col-xl-6 mb-3 mb-sm-0">
                <span data-auto="patient-last-name">{request?.lastName}</span>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-sm-5 col-md-4 text-sm-right">
                <p className="font-weight-bold mb-1 mb-sm-2">Date of Birth</p>
              </div>
              <div className="col-12 col-sm-5 col-md-6 col-xl-6 mb-3 mb-sm-0">
                <span data-auto="patient-dob">{request?.dob}</span>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-sm-5 col-md-4 text-sm-right">
                <p className="font-weight-bold mb-1 mb-sm-2">Gender</p>
              </div>
              <div className="col-12 col-sm-5 col-md-6 col-xl-6 mb-3 mb-sm-0">
                <span data-auto="patient-gender">{getGenderDisplay(request?.gender)}</span>
              </div>
            </div>
          </div>
          {(insuranceProvidersEnabled || insuranceCollectionEnabled) && (
            <>
              {insuranceProvider ? (
                <div className="col-12">
                  <div className={`row${!request?.insuranceMemberId ? ' mb-4' : ''}`}>
                    <div className="col-12 col-sm-5 col-md-4 text-sm-right">
                      <p className="font-weight-bold mb-1 mb-sm-2" data-auto="patient-insurance-provider-label">Insurance Provider</p>
                    </div>
                    <div className="col-12 col-sm-5 col-md-6 col-xl-6 mb-3 mb-sm-0" data-auto="patient-insurance-provider-text">
                      <span>
                        {insuranceProvider}
                      </span>
                    </div>
                  </div>
                </div>
              ) : null}
              {request?.insuranceMemberId ? (
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 col-sm-5 col-md-4 text-sm-right" data-auto="patient-member-id-label">
                      <p className="font-weight-bold mb-1 mb-sm-2">Insurance Member ID</p>
                    </div>
                    <div className="col-12 col-sm-5 col-md-6 col-xl-6 mb-3 mb-sm-0" data-auto="txt-patient-member-id-text">
                      <span>{request?.insuranceMemberId}</span>
                    </div>
                  </div>
                </div>
              ) : false}
            </>
          )}
        </div>
        { edit
        && (
        <div className="col-12 col-sm-1 col-md-2 submit-options d-inline-block pl-3">
          <button
            type="button"
            className="btn btn-light ml-1"
            onClick={() => {
              onEdit({ patient: true });
            }}
          >
            <i className="fas fa-pencil-alt d-inline mr-1" />
            Edit
          </button>
        </div>
        )}
      </div>
    </div>
  );
}

export default PatientReviewPage;
