import createReducer from '../createReducer';

/** ********************************************************
 * CONSTANTS
 ******************************************************** */

export const FETCH_ORGANIZATIONS = 'organization/FETCH_ORGANIZATIONS';
export const FETCHING_ORGANIZATIONS = 'organization/FETCHING_ORGANIZATIONS';
export const HAS_EHR_ENABLED = 'organization/HAS_EHR_ENABLED';
export const EHR_TYPE = 'organization/EHR_TYPE';
export const ORGANIZATIONS = 'organization/ORGANIZATIONS';

/** ********************************************************
 * REDUCER
 ******************************************************** */

export const initialStateOrganization = {
  organizations: null,
  isFetchingOrganizations: false,
  hasEhrEnabled: false,
  ehrType: null,
};

export default createReducer(initialStateOrganization, {
  [FETCHING_ORGANIZATIONS]: (state, { payload }) => ({
    ...state, isFetchingOrganizations: payload,
  }),
  [ORGANIZATIONS]: (state, { payload }) => ({
    ...state, organizations: payload,
  }),
  [HAS_EHR_ENABLED]: (state, { payload }) => ({
    ...state, hasEhrEnabled: payload,
  }),
  [EHR_TYPE]: (state, { payload }) => ({
    ...state, ehrType: payload,
  }),
});


/** ********************************************************
 * ACTIONS
 ******************************************************** */

/**
 * Fetches the organizations
 * @param {int} organizationId
 * @returns {{type: string, payload: *}}
 */
export const fetchOrganizations = organizationId => ({
  type: FETCH_ORGANIZATIONS,
  payload: organizationId,
});

/**
 * Used by the UI to determine if the form is ready to be shown
 * @param {boolean} bool
 * @returns {{type: string, payload: boolean}}
 */
export const isFetchingOrganizations = bool => ({
  type: FETCHING_ORGANIZATIONS,
  payload: bool,
});

/**
 * Set selected organization's has EHR enabled value
 * @param {boolean} bool
 * @returns {{type: string, payload: boolean}}
 */
export const hasEHREnabledOrganization = bool => ({
  type: HAS_EHR_ENABLED,
  payload: bool,
});

/**
 * Set selected organization's EHR type value
 * @param {string} payload
 * @returns {{type: string, payload: string}}
 */
export const ehrTypeOrganization = payload => ({
  type: EHR_TYPE,
  payload,
});

/**
 * Sets the organizations
 * @param {[]} payload
 * @returns {{type: string, payload: *}}
 */
export const organizations = payload => ({
  type: ORGANIZATIONS,
  payload,
});
/** ********************************************************
 * SELECTORS
 ******************************************************** */

/**
 * @param state
 * @returns {null|[]}
 */
export const selectOrganizations = state => state.organization.organizations;

/**
 * @param state
 * @returns {boolean}
 */
export const selectIsFetchingOrganizations = state => state.organization.isFetchingOrganizations;

/**
 * @param state
 * @returns {boolean}
 */
export const selectOrganizationHasEHREnabled = state => state.organization.hasEhrEnabled;

/**
 * @param state
 * @returns {boolean}
 */
export const selectOrganizationEHRType = state => state.organization.ehrType;
