import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  fetchSpecialties,
  fetchStoredCoverage,
  pediatricsFilterLink,
  fetchCoverage, setSpecialistRequiredFieldsChanged,
} from '../../../state';
import { isPediatric } from '../../../utils';
import PatientPastEconsults from './PatientPastEconsults';
import { validateDate, fillPatientData } from './PatientUtils';
import InsuranceForm from './insurance/InsuranceForm';
import { isReimbursementEnabled, isInsuranceCollectionEnabled } from '../../../helpers/CoverageHelper';
import PatientAddress from './PatientAddress';
import { getGenders } from '../../../helpers/gender';

const PatientForm = ({
  patient,
  editPatient,
  request,
  eConsultID,
}) => {
  const {
    clearErrors,
    register,
    errors,
    setValue,
    getValues,
  } = useFormContext();

  const dispatch = useDispatch();

  const { organizationId, user } = useSelector((state) => state.user, shallowEqual);
  const { isPatientSearched, pesRequestFailed } = useSelector((state) => state.patient, shallowEqual);
  const { fieldsRefs } = useSelector((state) => state.fieldRef, shallowEqual);
  const { serviceRequestDraftData } = useSelector((state) => state.serviceRequest, shallowEqual);
  const { isFetchingSpecialties } = useSelector((state) => state.specialty, shallowEqual);
  const { hasEhrEnabled, ehrType } = useSelector((state) => state.organization, shallowEqual);
  const { smart } = useSelector((state) => state.user.context, shallowEqual);
  const { isFetchingStoredCoverage } = useSelector((state) => state.coverage, shallowEqual);
  const { isRequestOnProcess } = useSelector((state) => state.serviceRequest, shallowEqual);
  const emptyErrorMessage = 'This field is required';
  const patternErrorMessage = 'Please remove any special character';

  const firstnameRef = useRef();
  const lastnameRef = useRef();
  const dobRef = useRef();
  const genderRef = useRef();

  const [lockedFields, setLockedFields] = useState({});

  /**
   * If we have patient context, lock all non-empty fields
   */
  const lockFields = () => {
    const values = getValues();
    if (values.ehrPatientContextId || (hasEhrEnabled && !values.mrnManualEntry)) {
      const fields = [
        'firstName',
        'lastName',
        'dob',
        'gender',
        'street_1',
        'street_2',
        'city',
        'state',
        'zip',
      ];
      const newLockedFields = {};
      fields.forEach((field) => {
        // If the PES request fails, we try to pull the patient data from Arista instead.
        // If that's the case, we need the field enabled
        newLockedFields[field] = !pesRequestFailed && (hasEhrEnabled || !!values[field]);
      });
      setLockedFields(newLockedFields);
    }
  };

  const loadSpecialties = (organizationID, filter) => {
    if (!isFetchingSpecialties) {
      const siteSelected = request?.siteSelector?.value || serviceRequestDraftData?.siteSelector;
      dispatch(fetchSpecialties(siteSelected || organizationID, filter));
    }
  };

  const fillData = async () => {
    await fillPatientData(request, setValue);
    clearErrors('patientSearch');
    lockFields();
  };

  // Get genders based on organization settings
  // This will include additional genders (X, NB, O, U) if enabled
  const genders = getGenders(user?.organization?.settings?.enabled_genders);

  useEffect(() => {
    // send filter to get both adult and pediatrics combined
    let filter = null;
    // Just enter here if the search button is clicked
    const userSearched = isPatientSearched.isSearched && isPatientSearched.userEvent;
    if (patient && (userSearched || getValues().ehrPatientContextId)) {
      dispatch(setSpecialistRequiredFieldsChanged(true));
      setValue('patientId', patient.id);
      setValue('patientFHIRId', patient.fhirID);
      setValue('patientSearch', patient.mrn);
      setValue('patientSystem', patient.mrn_system);
      setValue('firstName', patient.first_name);
      setValue('lastName', patient.last_name);
      setValue('dob', patient.dob);
      setValue('gender', patient.gender);
      const preferralId = patient.identifiers.find(identifier => identifier.system === 'preferral_id');
      if (preferralId) {
        setValue('preferralId', preferralId.value);
      }

      const insuranceProvidersEnabled = isReimbursementEnabled(user?.organization);
      const insuranceCollectionEnabled = isInsuranceCollectionEnabled(user?.organization);

      // FORM-563: Do not fetch the patient coverage if the request is being processed
      // PatientForm component is retrieving the patient's coverage from the database
      // each time there is a change in the patient state, so when the patient is created
      // at first time in the service request process this state changes triggering the
      // request for coverage
      if ((insuranceProvidersEnabled || insuranceCollectionEnabled) && !isFetchingStoredCoverage
        && !isRequestOnProcess.showSpinner && patient.fhirID) {
        dispatch(fetchStoredCoverage(patient.fhirID, eConsultID));
      }

      if (isPediatric(patient.dob)) {
        filter = 'pediatric'; // send pediatric filter
        dispatch(pediatricsFilterLink(true));
        if (!getValues().specialtySelector?.pediatrics_only) {
          setValue('specialtySelector', '');
        }
      } else {
        filter = 'non-pediatric';// send adult filter
        dispatch(pediatricsFilterLink(null));
        if (getValues().specialtySelector?.pediatrics_only) {
          setValue('specialtySelector', '');
        }
      }
      loadSpecialties(organizationId, filter);
      clearErrors('patientSearch');
      lockFields();
    } else if (request && (request.patientSearch === getValues().patientSearch)) {
      fillData();
    } else if (!patient && isPatientSearched.isSearched && isPatientSearched.userEvent) {
      // clean the patient
      setValue('patientId', '');
      setValue('patientFHIRId', '');
      setValue('patientSystem', '');
      setValue('firstName', '');
      setValue('lastName', '');
      setValue('dob', '');
      setValue('gender', '');
      clearErrors('patientSearch');
      lockFields();
    }
  }, [patient, isPatientSearched]);

  useEffect(() => {
    const errorsArray = Object.keys(errors);
    if (errors && typeof fieldsRefs === 'string') {
      if (errorsArray.includes('firstName')) {
        firstnameRef.current.focus();
      } else if (errorsArray.includes('lastName')) {
        lastnameRef.current.focus();
      } else if (errorsArray.includes('dob')) {
        dobRef.current.focus();
      } else if (errorsArray.includes('gender')) {
        genderRef.current.focus();
      }
    }
  }, [errors, fieldsRefs]);

  const onDOBChange = (event) => {
    dispatch(setSpecialistRequiredFieldsChanged(true));
    const isValid = validateDate(event.target.value);

    if (isValid) {
      let filter;
      if (isPediatric(event.target.value)) {
        filter = 'pediatric'; // send pediatric filter
        dispatch(pediatricsFilterLink(true));
        if (!getValues().specialtySelector?.pediatrics_only) {
          setValue('specialtySelector', '');
        }
      } else {
        filter = 'non-pediatric';// send adult filter
        dispatch(pediatricsFilterLink(null));
        if (getValues().specialtySelector?.pediatrics_only) {
          setValue('specialtySelector', '');
        }
      }
      dispatch(fetchSpecialties(organizationId, filter));

      // fetchCoverage will send request if insurance fields are already filled
      dispatch(fetchCoverage(getValues()));
    }
  };

  return (
    <>
      <div className="form-group col-12 mb-2" data-auto="form-patient">
        <div className="row">
          <div className="col-12 col-sm-3 col-xl-2 text-sm-right">
            <label htmlFor="firstName" data-aut="first-name-label">First Name</label>
          </div>
          <div className="form-group col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
            <input
              autoFocus={editPatient && !patient}
              ref={(ref) => {
                firstnameRef.current = ref;
                register(ref, {
                  required: emptyErrorMessage,
                  pattern: {
                    value: /^[A-Za-z0-9'-\\ "]*$/,
                    message: patternErrorMessage,
                  },
                });
              }}
              className={
                errors.firstName
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              type="text"
              name="firstName"
              data-auto="patient-first-name-input"
              disabled={lockedFields.firstName}
            />
            {(errors.firstName?.type === 'required' || errors.firstName?.type === 'pattern') && (
              <div className="alert alert-danger" role="alert" data-auto="patient-first-name-error">
                <i className="fa fa-warning fa-sm" />
                {' '}
                {errors.firstName.message}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="form-group col-12 mb-2">
        <div className="row">
          <div className="col-12 col-sm-3 col-xl-2 text-sm-right">
            <label htmlFor="lastName" data-auto="patient-last-name-label">Last Name</label>
          </div>
          <div className="form-group col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
            <input
              ref={(ref) => {
                lastnameRef.current = ref;
                register(ref, {
                  required: emptyErrorMessage,
                  pattern: {
                    value: /^[A-Za-z0-9'-\\ "]*$/,
                    message: patternErrorMessage,
                  },
                });
              }}
              className={
                errors.lastName
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              type="text"
              name="lastName"
              data-auto="patient-last-name-input"
              disabled={lockedFields.lastName}
            />
            {(errors.lastName?.type === 'required' || errors.lastName?.type === 'pattern') && (
              <div className="alert alert-danger" role="alert" data-auto="patient-last-name-error">
                <i className="fa fa-warning fa-sm" />
                {' '}
                {errors.lastName.message}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="form-group col-12 mb-2">
        <div className="row">
          <div className="col-12 col-sm-3 col-xl-2 text-sm-right">
            <label htmlFor="lastName" data-auto="patient-dob-label">Date of Birth</label>
          </div>
          <div className="form-group col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
            <input
              ref={(ref) => {
                dobRef.current = ref;
                register(ref, {
                  required: emptyErrorMessage,
                  validate: (value) => validateDate(value),
                });
              }}
              className={
                errors.dob
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              onBlur={onDOBChange}
              type="text"
              name="dob"
              data-auto="patient-dob-input"
              disabled={lockedFields.dob}
            />
            <p className="text-muted mb-0">MM / DD / YYYY</p>
            {errors.dob?.type === 'required'
              && (
              <div className="alert alert-danger" role="alert" data-auto="patient-dob-required-error">
                <i className="fa fa-warning fa-sm" />
                {' '}
                {errors.dob.message}
              </div>
              )}
            {errors.dob?.type === 'validate'
              && (
              <div className="alert alert-danger" role="alert" data-auto="patient-dob-error">
                <i className="fa fa-warning fa-sm" />
                {' '}
                Please enter a valid date
              </div>
              )}
          </div>
        </div>
      </div>
      <div className="form-group col-12 mb-2">
        <div className="row">
          <div className="col-12 col-sm-3 col-xl-2 text-sm-right">
            <label htmlFor="input-gender">Gender</label>
          </div>
          <div className="form-group col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
            <select
              id="input-gender"
              className="form-control"
              data-auto="patient-gender-select"
              name="gender"
              ref={(ref) => {
                genderRef.current = ref;
                register(ref, { required: emptyErrorMessage });
              }}
              disabled={lockedFields.gender}
            >
              <option />
              { genders.map(({ code, display }) => <option key={code} value={code}>{ display }</option>) }
            </select>
            {errors.gender?.type === 'required'
            && (
            <div className="alert alert-danger" role="alert" data-auto="patient-gender-error">
              <i className="fa fa-warning fa-sm" />
              {' '}
              {errors.gender.message}
            </div>
            )}
          </div>
        </div>
      </div>
      { user?.organization?.settings?.patient?.address_enabled
        ? <PatientAddress lockedFields={lockedFields} patient={patient} request={request} lockFieldsCallback={lockFields} /> : null}
      <InsuranceForm lockedFields={lockedFields} patient={patient} request={request} />
      { patient?.id && <PatientPastEconsults /> }
    </>
  );
};

export default PatientForm;
