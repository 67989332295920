import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector, shallowEqual } from 'react-redux';

function OtherTextInputComponent() {
  const {
    setValue,
    getValues,
    clearErrors,
    errors,
    trigger,
  } = useFormContext();
  const otherTextRef = useRef();
  const { fieldsRefs } = useSelector(state => state.fieldRef, shallowEqual);

  useEffect(() => {
    clearErrors('otherText');
  }, []);

  const onInputChange = (e) => {
    setValue('otherText', e.target.value);
    clearErrors('otherText');
  };

  const onInputBlur = () => {
    trigger('otherText');
  };

  useEffect(() => {
    if (errors && typeof fieldsRefs === 'string') {
      if (fieldsRefs === 'otherText') {
        otherTextRef.current.focus();
      }
    }
  }, [errors, fieldsRefs]);

  return (
    <>
      <div className="form-group col-12 mb-4">
        <div className="row">
          <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-4 text-sm-right">
          </div>
          <div className="col-12 col-sm-5 col-md-4 col-lg-4 col-xl-5">
            <input
              type="text"
              name="otherText"
              ref={otherTextRef}
              value={getValues().otherText || ''}
              className={
                errors?.otherText
                ? 'form-control is-invalid'
                : 'form-control'
              }
              data-aut="other-text-input"
              onChange={(e) => { onInputChange(e) }}
              onBlur={onInputBlur}
              placeholder="Please Enter Details"  
            />
            {errors?.otherText?.type === 'required' &&
              <div className="alert alert-danger" role="alert" data-aut="other-text-error">
                <i className="fa fa-warning fa-sm" /> {errors.otherText.message}
               </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}
export default OtherTextInputComponent;
