import React, { useEffect, useRef, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { filterOptions } from '../../../../utils';
import { isReimbursementEnabled, isInsuranceCollectionEnabled } from '../../../../helpers/CoverageHelper';
import { fetchCoverage, setSpecialistRequiredFieldsChanged } from '../../../../state';
import { INSURANCE_PROVIDER_NOT_LISTED, SELF_PAY_UNINSURED } from './defaultInsuranceProviderOptions';

const InsuranceForm = ({ lockedFields, patient, request }) => {
  const dispatch = useDispatch();

  const {
    clearErrors,
    register,
    errors,
    setValue,
    getValues,
  } = useFormContext();

  const {
    insuranceProviders,
    isFetchingInsuranceProviders,
  } = useSelector(state => state.insuranceProviders, shallowEqual);
  const { fieldsRefs } = useSelector(state => state.fieldRef, shallowEqual);
  const { isPatientSearched } = useSelector(state => state.patient, shallowEqual);
  const { storedCoverage } = useSelector(state => state.coverage, shallowEqual);
  const { user } = useSelector(state => state.user, shallowEqual);

  const { isRequestOnProcess } = useSelector(state => state.serviceRequest, shallowEqual);

  const [insuranceProvidersEnabled, setInsuranceProvidersEnabled] = useState(false);
  const [isValidInsuranceProvider, setIsValidInsuranceProvider] = useState(true);
  const [insuranceCollectionEnabled, setInsuranceCollectionEnabled] = useState(false);
  const [insuranceProviderValue, setInsuranceProviderValue] = useState(null);
  const [insuranceMemberIdValue, setInsuranceMemberIdValue] = useState(null);
  const [insuranceProviderText, setInsuranceProviderText] = useState(null);

  const [isFilledFromRequest, setIsFilledFromRequest] = useState(false);

  const insuranceProviderRef = useRef();

  const emptyErrorMessage = 'This field is required';
  const patternErrorMessage = 'This field does not allow special characters';

  // todo refactor the way we validate this permissions
  useEffect(() => {
    // Checks if the reimbursement is enabled for the org
    const insuranceProviderSettingEnabled = isReimbursementEnabled(user?.organization);
    // Used to hide the member id input when the selected insurance provider is Insurance provider not listed or Self Pay / Uninsured
    setIsValidInsuranceProvider(![INSURANCE_PROVIDER_NOT_LISTED, SELF_PAY_UNINSURED].includes(insuranceProviderValue?.value));
    setInsuranceProvidersEnabled(insuranceProviderSettingEnabled);
    const isInsuranceCollectionSettingEnabled = isInsuranceCollectionEnabled(user?.organization);
    setInsuranceCollectionEnabled(isInsuranceCollectionSettingEnabled);
  }, [user, insuranceProviderValue, insuranceProviderText]);

  /**
   * On change handler for insurance providers dropdown. This method sets the value of the insurance
   * provider dropdown
   * @param selectedItem
   * @returns {Promise<void>}
   */
  const onInsuranceProviderChange = async (selectedItem) => {
    // we want to avoid triggering the availability endpoint if user is going back from the review page
    // selectedItem is undefined when clicking edit from the review page
    if (selectedItem) {
      dispatch(setSpecialistRequiredFieldsChanged(true));

      // The value should be set to empty in case user changes provider to insurance not provided
      // Also insurance provider value can be registered initially as an empty string
      await setValue('insuranceProvider', selectedItem);
      await setInsuranceProviderValue(selectedItem);
      await clearErrors('insuranceProvider');

      // Let's clean the insurance member id input when insurance provider is Insurance provider not listed or Self Pay / Uninsured
      if ([INSURANCE_PROVIDER_NOT_LISTED, SELF_PAY_UNINSURED].includes(selectedItem?.value)) {
        await setValue('insuranceMemberId', '');
        await setInsuranceMemberIdValue(null);
      }
    }
    if (!isRequestOnProcess.showSpinner) {
      dispatch(fetchCoverage(getValues()));
    }
  };

  async function fillRequestData() {
    // Set the insuranceMemberId first because it is required to verify the coverage as part of the onInsuranceProviderChange logic
    await setValue('insuranceMemberId', request?.insuranceMemberId);
    await onInsuranceProviderChange(request?.insurance_provider);
    await setValue('insuranceProvider', request?.insuranceProvider);
    await setInsuranceProviderValue(request?.insuranceProvider);
    await setValue('insuranceProviderText', request?.insuranceProviderText);
  }

  /**
   * On blur handler for insurance member id. This method queries the coverage endpoint
   * when user clicks away from insurance member id field
   * @param event
   * @returns {Promise<void>}
   */
  const onInsuranceMemberIdChange = async () => {
    if (isInsuranceCollectionEnabled(user?.organization)) {
      return;
    }
    dispatch(fetchCoverage(getValues()));
  };

  async function fillPatientData() {
    if (storedCoverage?.insurance_provider_id > 0
      || (storedCoverage?.insurance_provider_id === 0 && storedCoverage?.member_id)) {
      // Set the insuranceMemberId first because it is required to verify the coverage as part of the onInsuranceProviderChange logic
      // only update member id value if it hasn't already been set
      if (getValues('insuranceMemberId') !== storedCoverage?.member_id) {
        await setValue('insuranceMemberId', storedCoverage?.member_id);
      }
      // insurance provider saved after insurance redesign, populate values
      if (storedCoverage?.insurance_provider_id > 0) {
        await onInsuranceProviderChange(storedCoverage?.insurance_provider);
      }
    } else if ([INSURANCE_PROVIDER_NOT_LISTED, SELF_PAY_UNINSURED].includes(storedCoverage?.insurance_provider_id)) {
      // insurance provider not listed or self pay uninsured, populate IP, hide member id field
      await onInsuranceProviderChange(storedCoverage?.insurance_provider);
    }
    else {
      if (request) {
        await setValue('insuranceProvider', request?.insuranceProvider);
        await setInsuranceProviderValue(request?.insuranceProvider);
      } else {
        //clear form values for all coverage stored before insurance redesign
        await setValue('insuranceProvider', '');
        await setValue('insuranceMemberId', '');
      }
    }

    // if insurance collection is enabled, set the insurance provider and member id values
    if (isInsuranceCollectionEnabled(user?.organization)) {
      if (getValues('insuranceProviderText') !== storedCoverage?.insurance_provider_name) {
        await setValue('insuranceProviderText', storedCoverage?.insurance_provider_name);
      }
      if (getValues('insuranceMemberId') !== storedCoverage?.member_id) {
        await setValue('insuranceMemberId', storedCoverage?.member_id);
      }
    }
  }

  const fillData = () => {
    if (patient && isPatientSearched.isSearched && isPatientSearched.userEvent) {
      fillPatientData();
    } else if (request && (request.patientSearch === getValues().patientSearch)
      && !isFilledFromRequest) {
      fillRequestData();
      setIsFilledFromRequest(true);
    }
  };

  const onInsuranceChange = () => {
      dispatch(setSpecialistRequiredFieldsChanged(true));
  }

  useEffect(() => {
    if (isPatientSearched.isSearched && isPatientSearched.userEvent) {
      // The above conditions will be true when the user searched for a patient by MRN or
      // when we have patient context or EHR patient context
      if (!patient) {
        // User searched for patient but no matching patient was found, clear the insurance fields
        setValue('insuranceProvider', '');
        setValue('insuranceMemberId', '');
        setValue('insuranceProviderText', '');
        clearErrors('insuranceProvider');
        setInsuranceProviderValue(null);
        setInsuranceMemberIdValue(null);
        setInsuranceProviderText(null);
      } else {
        // we have patient data, try to fill the insurance form data
        fillData();
      }
    }
  }, [storedCoverage]);

  useEffect( () => {
    // Check if the insurance provider is not active, then clear the values
    if (insuranceProviders && insuranceProviderValue) {
      const match = insuranceProviders.some(provider => provider.value === insuranceProviderValue.value);
      if (!match) {
        setValue('insuranceProvider', '');
        setValue('insuranceMemberId', '');
        setInsuranceProviderValue(null);
        setInsuranceMemberIdValue(null);
      }
    }
  }, [insuranceProviders, insuranceProviderValue]);

  useEffect(() => {
    // try to fill the insurance form data
    fillData();
  }, [request]);

  useEffect(() => {
    const errorsArray = Object.keys(errors);
    if (errors && typeof fieldsRefs === 'string') {
      if (errorsArray.includes('insuranceProvider')) {
        insuranceProviderRef.current.focus();
      }
    }
  }, [errors, fieldsRefs]);

  useEffect(() => {
    setValue('insuranceProvider', insuranceProviderValue);
    clearErrors('insuranceProvider');
  }, [insuranceProviderValue]);

  useEffect(() => {
    const insuranceProviderEnabled = isReimbursementEnabled(user?.organization);
    register({ name: 'insuranceProvider' }, { required: insuranceProviderEnabled ? emptyErrorMessage : false });
  }, []);

  const loadInsuranceProvidersOptions = (inputValue, callback) => {
    callback(filterOptions(inputValue, insuranceProviders));
  };

  return (
    <>
      <div className={`form-group col-12 mb-2 ${insuranceProvidersEnabled ? 'd-block' : 'd-none'}`}>
        <div className="row">
          <div className="col-12 col-sm-3 col-xl-2 text-sm-right">
            <label htmlFor="insuranceProvider" data-auto="insurance-provider-label">Insurance Provider</label>
          </div>
          <div className="form-group col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3" data-auto="insurance-provider-select">
            <AsyncSelect
              ref={insuranceProviderRef}
              name="insuranceProvider"
              isSearchable
              value={insuranceProviderValue}
              onChange={onInsuranceProviderChange}
              defaultOptions={insuranceProviders}
              loadOptions={loadInsuranceProvidersOptions}
              isLoading={isFetchingInsuranceProviders}
              className={
                errors.insuranceProvider
                  ? 'form-control-select is-invalid'
                  : 'form-control-select'
              }
              isDisabled={isFetchingInsuranceProviders}
            />
            <ErrorMessage errors={errors} name="insuranceProvider">
              {({ messages, message: customMessage }) => {
                const errorMessage = messages || [customMessage];
                return errorMessage
                  && Object.entries(errorMessage).map(([type, message]) => (
                    <div key={type} className="alert alert-danger" role="alert" data-auto="patient-insurance-provider-error">
                      <i className="fa fa-warning fa-sm" />
                      {message}
                    </div>
                  ));
              }}
            </ErrorMessage>
          </div>
        </div>
      </div>
      <div className={`form-group col-12 mb-2 ${insuranceCollectionEnabled ? 'd-block' : 'd-none'}`}>
        <div className="row">
          <div className="col-12 col-sm-3 col-xl-2 text-sm-right" data-auto="patient-insurance-text-label">
            <label htmlFor="insuranceProviderText">Insurance Provider</label>
          </div>
          <div className="form-group col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3" data-auto="patient-insurance-text-field">
            <input
              ref={register({
                // eslint-disable-next-line max-len
                required: insuranceCollectionEnabled ? emptyErrorMessage : false,
              })}
              className={
                errors.insuranceProviderText
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              onBlur={onInsuranceChange}
              type="text"
              data-auto="insurance-plan-text"
              id="insuranceProviderText"
              name="insuranceProviderText"
              value={insuranceProviderText}
            />
            <ErrorMessage errors={errors} name="insuranceProviderText">
              {({ messages, message: customMessage }) => {
                const errorMessage = messages || [customMessage];
                return errorMessage
                  && Object.entries(errorMessage).map(([type, message]) => (
                    <div key={type} className="alert alert-danger" role="alert" data-auto="patient-insurance-text-error">
                      <i className="fa fa-warning fa-sm" />
                      {message}
                    </div>
                  ));
              }}
            </ErrorMessage>
          </div>
        </div>
      </div>
      <div className={`form-group col-12 mb-2 ${(insuranceProvidersEnabled && isValidInsuranceProvider) || insuranceCollectionEnabled ? 'd-block' : 'd-none'}`}>
        <div className="row">
          <div className="col-12 col-sm-3 col-xl-2 text-sm-right">
            <label htmlFor="insuranceMemberId" data-auto="insurance-member-label">Insurance Member ID</label>
          </div>
          <div className="form-group col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
            <input
              ref={register({
                required: (insuranceProvidersEnabled && isValidInsuranceProvider) || insuranceCollectionEnabled ? emptyErrorMessage : false,
                pattern: {
                  value: /^[A-Za-z0-9-_\\ ]*$/,
                  message: patternErrorMessage,
                },
              })}
              className={
                errors.insuranceMemberId
                  ? 'form-control is-invalid'
                  : 'form-control'
              }
              type="text"
              name="insuranceMemberId"
              value={insuranceMemberIdValue}
              onBlur={onInsuranceMemberIdChange}
              data-auto="patient-insurance-member-id-input"
            />
            <ErrorMessage errors={errors} name="insuranceMemberId">
              {({ messages, message: customMessage }) => {
                const errorMessage = messages || [customMessage];
                return errorMessage
                  && Object.entries(errorMessage).map(([type, message]) => (
                    <div key={type} className="alert alert-danger" role="alert" data-auto="patient-insurance-member-error">
                      <i className="fa fa-warning fa-sm" />
                      {message}
                    </div>
                  ));
              }}
            </ErrorMessage>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsuranceForm;
