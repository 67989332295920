import React, { useCallback } from 'react';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { selectUser } from '../../state';
import Session from '../../services/Session';

const UserMenu = ({ user }) => {
  const logoutHandleClick = useCallback(
    () => {
      Session.logout.bind(Session);
      Session.logout();
    },
    [],
  );

  return (
    <UncontrolledDropdown inNavbar data-aut="user-menu">
      <DropdownToggle color="secondary" nav caret>
        <i className="fa fa-user mr-2" />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem header data-aut="user-menu-info">
          <strong>{user.full_name}</strong> <br />
          {user.email}
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={logoutHandleClick} data-aut="user-menu-logout">
          Logout
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const mapStateToProps = state => ({
  user: selectUser(state),
});

const {
  any,
  objectOf,
} = PropTypes;

UserMenu.propTypes = {
  user: objectOf(any).isRequired,
};

export default connect(mapStateToProps)(UserMenu);
