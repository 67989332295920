import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { history } from '../../store';
import { formatDateTimezone } from '../../utils';
/**
 * Requested By Review
 */
function SubmittedBy() {
  const request = history.location?.state;
  const { user } = useSelector(state => state.user, shallowEqual);
  const [authoredOn, setAuthoredOn] = useState(null);

  useEffect(() => {
    const utcDate = request?.submittedAt;
    if (utcDate && user?.timezone) {
      const authoredOnTime = formatDateTimezone(utcDate, user.timezone);
      setAuthoredOn(authoredOnTime);
    }
  }, [request]);

  return (
    <div className="row mb-4" id="requested-by-review">
      <div className="col-12 mb-3">
        <h6 className="font-weight-bold border-bottom pb-2 requested-by-review">Submitted by</h6>
      </div>
      <div className="col-12 col-lg-10 w-100 pl-0">
        <div className="labels-wrapper d-inline-block">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-sm-5 col-md-4 col-xl-4 text-sm-right">
                <p className="font-weight-bold mb-1 mb-sm-2">User</p>
              </div>
              <div className="col-12 col-sm-5 col-md-6 col-xl-6 mb-3 mb-sm-0">
                <span>{request?.providerSelector?.label}</span>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-sm-5 col-md-4 col-xl-4 text-sm-right">
                <p className="font-weight-bold mb-1 mb-sm-2">Submitted at</p>
              </div>
              <div className="col-12 col-sm-5 col-md-6 col-xl-6 mb-3 mb-sm-0">
                <span>{authoredOn}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubmittedBy;
